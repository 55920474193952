// CreateUser.js
import React, { useState } from "react";
import Layout from "../../../Components/Layout";
import { useAuth } from "../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import CreateAcopiador from "./Components/CreateAcopiador";
import Select from "../../../Components/Custom/Forms/Select";
import TextInput from "../../../Components/Custom/Forms/TextInput";

import { toast } from "react-toastify";

const CreateUser = () => {
  const { authState } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    username: "",
    name: "",
    lastname: "",
    mail: "",
    password: "",
    rol: "", // Inicializar el rol aquí si es necesario
  });

  const options = [
    { value: "", label: "Seleccionar Rol" },
    { value: "Root", label: "Root" },
    { value: "Acopiador", label: "Acopiador" },
    { value: "Veterinaria", label: "Veterinaria" },
    { value: "Secretaria", label: "Secretaria" },
    { value: "SubSecretario", label: "SubSecretario" },
  ];

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/auth/createUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authState.authToken}`,
        },
        body: JSON.stringify(userData),
      });
      if (response.ok) {
        toast.success("Usuario creado exitosamente");
        navigate("/");
        // Manejo adicional en caso de éxito
      } else {
        const errorData = await response.json();
        console.error("Error creating user:", errorData.message);
        toast.error(`Error: ${errorData.message}`);
      }
    } catch (error) {
      console.error("Error during user creation:", error);
      toast.error(`Error: ${error.message}`);
    }
  };

  return (
    <Layout>
      <div className="mt-4 w-full font-titles">
        <h1 className="text-4xl text-center mb-5 text-titles max-w-md mx-auto">
          Crear usuario
        </h1>
        <form onSubmit={handleSubmit} className="max-w-md mx-auto">
          <div className="mb-5">
            <TextInput
              label={"Nombre de Usuario:"}
              id="username"
              value={userData.username}
              onChange={handleChange}
              required
              maxLength={20}
              validationPattern={"^[a-zA-Z0-9]*$"}
              className="mr-2 w-full"
              name="username"
            />
          </div>
          <div className="mb-5">
            <TextInput
              label={"Nombre:"}
              id="name"
              value={userData.name}
              onChange={handleChange}
              required
              maxLength={40}
              className="mr-2 w-full"
              name="name"
            />
          </div>
          <div className="mb-5">
            <TextInput
              label={"Apellido:"}
              id="lastname"
              value={userData.lastname}
              onChange={handleChange}
              required
              maxLength={40}
              className="mr-2 w-full"
              name="lastname"
            />
          </div>
          <div className="mb-5">
            <TextInput
              label={"Correo Electrónico:"}
              id="mail"
              value={userData.mail}
              onChange={handleChange}
              required
              maxLength={40}
              className="mr-2 w-full"
              name="mail"
            />
          </div>
          <div className="mb-5">
            <Select
              label={"Rol de usuario:"}
              id="rol"
              value={userData.rol}
              onChange={handleChange}
              options={options}
              required
              className="mr-2 w-full"
              name="rol"
            />
          </div>
          {userData.rol === "Acopiador" && (
            <CreateAcopiador userData={userData} setUserData={setUserData} />
          )}
          <button
            type="submit"
            className="bg-blue-700 w-full hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-white  rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Crear Usuario
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default CreateUser;
