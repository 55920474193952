import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faEye,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import BottomDrawerMenu from "../Components/BottomDrawerMenu";
const SubSecretario = () => {
  const [data, setData] = useState([]);
  const [terminoBusqueda, setTerminoBusqueda] = useState("");
  const [filtroEstado, setFiltroEstado] = useState("");
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false); // Nuevo estado para controlar la visibilidad del menú de filtros

  const { authState } = useContext(AuthContext);
  const apiUrl = process.env.REACT_APP_API_URL;


  const navigate = useNavigate();

  useEffect(() => {
    // Restablecer la página actual a 1 cuando cambia el filtroEstado o terminoBusqueda
    setPaginaActual(1);
  }, [filtroEstado, terminoBusqueda]);

  // get db data from api http://localhost:4000/api/ and passing autorization token as header
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Construye la URL base
        let url = new URL(`${apiUrl}/api/folios`);

        // Inicia los parámetros con la búsqueda
        let params = { busqueda: terminoBusqueda };
        // Si filtroEstado es un objeto, añade estado y step a params
        if (typeof filtroEstado === "object" && filtroEstado !== null) {
          params = { ...params, ...filtroEstado };
        } else if (filtroEstado) {
          // Si es un string, solo añade estado a params
          params.estado = filtroEstado;
        }

        // Agrega los parámetros a la URL
        Object.keys(params).forEach((key) =>
          url.searchParams.append(key, params[key])
        );

        // Realiza la petición fetch con los parámetros incluidos en la URL
        const res = await fetch(url, {
          headers: {
            Authorization: `${authState.authToken}`,
          },
        });

        if (!res.ok) {
          throw new Error("Response not ok from API");
        }

        const json = await res.json();
        setData(json); // Asume que existe una función setData para actualizar el estado con los datos recibidos
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [authState.authToken, filtroEstado, terminoBusqueda]); // Dependencias del efecto

  // Estados para la paginación
  const [paginaActual, setPaginaActual] = useState(1);
  const [filasPorPagina, setFilasPorPagina] = useState(5); // Estado para controlar las filas por página

  // Función para cambiar las filas por página
  const cambiarFilasPorPagina = (evento) => {
    setFilasPorPagina(parseInt(evento.target.value, 10));
    setPaginaActual(1); // Reinicia a la primera página
  };

  // Calcula el número de páginas
  const numeroDePaginas = Math.ceil(data.length / filasPorPagina);

  // Obtiene los datos para la página actual
  const indiceDelUltimoItem = paginaActual * filasPorPagina;
  const indiceDelPrimerItem = indiceDelUltimoItem - filasPorPagina;
  const datosActuales = data.slice(indiceDelPrimerItem, indiceDelUltimoItem);

  // Cambia la página
  const paginar = (numeroDePagina) => setPaginaActual(numeroDePagina);

  return (
    <div className="w-full md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto">
      <h1 className="text-2xl leading-tight font-titles text-center">Sub Secretario</h1>

      <div className="my-2 flex justify-between items-center text-sm font-medium text-center text-gray-500 w-full border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <div className=" flex">
          <div className="relative">
            <select
              id="filasPorPagina"
              onChange={cambiarFilasPorPagina}
              value={filasPorPagina}
              className=" appearance-none h-full cursor-pointer rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight  focus:outline-none focus:bg-white focus:border-blue-500"
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              {/* Agrega más opciones según necesites */}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          <div className=" block relative">
            <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
              <svg
                viewBox=" 0 0 24 24"
                className=" h-4 fill-current w-4 text-gray-500">
                <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
              </svg>
            </span>
            <input
              type="text"
              id="search"
              placeholder="Search"
              className=" rounded-r border border-gray-400 block pr-6 pl-8 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:border-gray-600 focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
              value={terminoBusqueda}
              onChange={(e) => setTerminoBusqueda(e.target.value)}
            />
          </div>
        </div>
        <div>
          <BottomDrawerMenu
            isMenuOpen={isFilterMenuOpen}
            setFiltroEstado={setFiltroEstado}
            filtroEstado={filtroEstado}
          />
        </div>
      </div>{/**impplementacion de justify-between */}
      
      <div className="text-2xl mb-4 lg:flex lg:justify-between px-2 items-center">
        <div className="text-sm font-medium text-center text-gray-500 w-full border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul className="lg:flex lg:flex-wrap -mb-px lg:items-center">
            <li className="me-2">
              <div className="my-2 flex justify-between items-center">
                <div className="flex flex-row">
                  <div className="relative">
                    <select
                      id="filasPorPagina"
                      onChange={cambiarFilasPorPagina}
                      value={filasPorPagina}
                      className=" appearance-none h-full cursor-pointer rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight  focus:outline-none focus:bg-white focus:border-blue-500"
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      {/* Agrega más opciones según necesites */}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                  <div className=" block relative">
                    <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                      <svg
                        viewBox=" 0 0 24 24"
                        className=" h-4 fill-current w-4 text-gray-500">
                        <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
                      </svg>
                    </span>
                    <input
                      type="text"
                      id="search"
                      placeholder="Search"
                      className=" rounded-r border border-gray-400 block pr-6 pl-8 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:border-gray-600 focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                      value={terminoBusqueda}
                      onChange={(e) => setTerminoBusqueda(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {/*<input
                type="text"
                id="search"
                className="rounded-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block min-w-0 w-full text-sm p-2.5"
                placeholder="Search"
                value={terminoBusqueda}
                onChange={(e) => setTerminoBusqueda(e.target.value)}
              />*/}
            </li>
            <li>
              <BottomDrawerMenu
                isMenuOpen={isFilterMenuOpen}
                setFiltroEstado={setFiltroEstado}
                filtroEstado={filtroEstado}
              />
            </li>
          </ul>
        </div>
      </div>

      <div className="w-full py-4 sm:px-8overflow-x-auto px-4">
        <div className=" inline-block overflow-hidden min-w-full rounded-lg shadow">
          <table className="min-w-full leading-normal">
            <thead>
              <tr className="bg-blue-100">
                <th className="px-8 py-4 font-primary text-left">Folio</th>
                <th className="px-8 py-4 font-primary text-left">Rancho</th>
                <th className="px-8 py-4 font-primary text-left">Origen</th>
                <th className="px-8 py-4 font-primary text-left">Destino</th>
                <th className="px-8 py-4 font-primary text-left">Estado</th>
                <th className="px-8 py-4 font-primary text-left">Acciones</th>

                {/* Agrega aquí más cabeceras si las necesitas */}
              </tr>
            </thead>
            <tbody>
              {datosActuales.map((item, index) => (
                <tr key={index} className="border-b">
                  <td className=" text-left px-8 py-4">
                    {item.folio_id}
                  </td>
                  <td className="px-8 py-4 text-left">
                    {item.nombre_origen}
                  </td>
                  <td className="px-8 py-4 text-left">
                    {item.poblacion_origen}
                  </td>
                  <td className="px-8 py-4 text-left">
                    {item.poblacion_destino}
                  </td>
                  <td className="px-8 py-4 text-left">
                    {item.estado === "En Progreso" && (
                      <div className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900 w-24 text-center">
                        En Progreso
                      </div>
                    )}
                    {item.estado === "Rechazado" && (
                      <div className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900 w-24 text-center">
                        Rechazado
                      </div>
                    )}
                    {item.estado === "Aprobado" && (
                      <div className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900 w-24 text-center">
                        Aprobado
                      </div>
                    )}
                  </td>

                  <td className="py-4 px-8 text-center flex">
                    {item.estado === "Aprobado" && (
                      <button
                        type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-32"
                        onClick={() => navigate(`/folio-viewer/${item.folio_id}`)}
                      >
                        Ver Folio
                      </button>
                    )}
                    {item.estado === "Rechazado" && (
                      <div><p>-</p></div>

                    )}
                    {item.estado === "En Progreso" && (

                      <div className="w-full flex justify-between">
                        <div
                          className="cursor-pointer"
                          onClick={() => navigate(`/upload-signed-doc/${item.folio_id}`)}
                        >
                          <FontAwesomeIcon
                            icon={faUpload}
                            className="cursor-pointer px-2"
                          />
                          Subir permiso


                        </div>
                        <div
                          className="cursor-pointer "
                          onClick={() => navigate(`/permiso/${item.folio_id}`)}
                        >
                          <FontAwesomeIcon icon={faEye} className="px-2" />
                          Visualizar Permiso
                        </div>
                      </div>
                    )}
                  </td>

                  {/* Agrega aquí más celdas si las necesitas */}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="px-5 py-5 border-t bg-white flex flex-col xs:flex-row xs:justify-between items-center">
            <span className="text-xs text-gray-900">paginas</span>
            {/*falta agrgar lo de el numero de paginas:  "Showing 5 of 8 Entries"*/}
            <div className=" inline-flex mt-2 xs:mt-0">
              {/*Ambos botones no tienen funcion */}
              <button className=" text-sm bg-blue-700 hover:bg-blue-800 text-white font-semibold py-2 px-4 rounded-lg "
              >
                Prev
              </button>
              <button className="text-sm py-2 px-4 hover:bg-blue-200 rounded-lg"
              >
                1
              </button>
              <button className="text-sm py-2 px-4 hover:bg-blue-200 rounded-lg"
              >
                2
              </button>{/*La idea del boton es el cambiar a cierta pagina o como indicador de la pagina en donde estamos*/}
              <button className=" text-sm bg-blue-700 hover:bg-blue-800 text-white font-semibold py-2 px-4 rounded-lg"
              >
                Next
              </button>
            </div>
          </div>
        </div>
        {/* Selector para cambiar el número de filas por página */}
      </div>

      <div className=" px-5 py-5 bg-white border-t flex flex-col xs:flex-row xs:justify-between items-center">
        <div className=" inline-flex mt-2 xs:mt-0">
          <label htmlFor="filasPorPagina" className="font-primary px-2 py-4">Filas por página:</label>
          <div className="my-2 flex justify-between items-center">
            <div className="flex flex-row">
              <div className="relative">

                <select
                  id="filasPorPagina"
                  onChange={cambiarFilasPorPagina}
                  value={filasPorPagina}
                  className=" appearance-none h-full cursor-pointer rounded-lg border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight  focus:outline-none focus:bg-white focus:border-gray-700"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  {/* Agrega más opciones según necesites */}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="flex justify-center items-center">
          {Array.from({ length: numeroDePaginas }).map((_, index) => (
            <button
              key={index}
              onClick={() => paginar(index + 1)}
              className="text-primary px-2 border rounded-sm"
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubSecretario;
