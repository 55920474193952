import React, { useState, createContext, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext(null);

export const useAuth = () => React.useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    authToken: localStorage.getItem("token"),
    user: null,
    isLoading: true,
  });

  useEffect(() => {
    const initializeAuthState = () => {
      const token = localStorage.getItem("token");
      if (token) {
        const decoded = jwtDecode(token);
        setAuthState({
          authToken: token,
          user: decoded,
          isLoading: false,
        });
      } else {
        setAuthState({
          authToken: null,
          user: null,
          isLoading: false,
        });
      }
    };

    initializeAuthState();
  }, []);

  const setAuthToken = (token) => {
    localStorage.setItem("token", token);
    const decodedToken = jwtDecode(token);
    setAuthState({ authToken: token, user: decodedToken });
  };

  const clearAuthToken = () => {
    localStorage.removeItem("token");
    setAuthState({ authToken: null, user: null });
  };

  const login = (token, userData) => {
    localStorage.setItem("token", token);
    setAuthState({ authToken: token, user: userData });
  };

  const logout = () => {
    localStorage.removeItem("token");
    setAuthState({ authToken: null, user: null });
  };

  const [isDocumentViewerOpen, setIsDocumentViewerOpen] = useState({
    state: false,
    path: "",
  });

  const closeDocumentViewer = () =>
    setIsDocumentViewerOpen({ state: false, path: "" });

  const openDocumentViewer = (path) => {
    setIsDocumentViewerOpen({ state: true, path: path });
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState,
        clearAuthToken,
        setAuthToken,
        login,
        logout,
        isDocumentViewerOpen,
        openDocumentViewer,
        closeDocumentViewer,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
