import React, { useState, useEffect } from "react";
import PDFViewer from "./../../PDFViewer";
import { useNavigate } from "react-router-dom";

import AretesList from "./Components/AretesList";
import RejectionModal from "./Components/RejectionModal";
import MotivosRechazoModal from "./Components/MotivosRechazoModal";
import Modal from "../../Modal";
import { toast } from "react-toastify";
import { set } from "date-fns";
import { CircleHelp } from "lucide-react";
import { Tooltip } from "react-tooltip";

const DocumentsVerification = ({ folio_id, facturas, authState }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [currentFacturaIndex, setCurrentFacturaIndex] = useState(0);
  const [aretesVerificados, setAretesVerificados] = useState({});
  const [aretesRechazados, setAretesRechazados] = useState({});
  const [motivosRechazoFactura, setMotivosRechazoFactura] = useState({});
  const [motivosRechazoReemo, setMotivosRechazoReemo] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMotivoFactura, setSelectedMotivoFactura] = useState("");
  const [selectedMotivoReemo, setSelectedMotivoReemo] = useState("");
  const [selectedTypeFactura, setSelectedTypeFactura] = useState("");
  const [selectedTypeReemo, setSelectedTypeReemo] = useState("");
  const [triggersFacturaReemo, setTriggersFacturaReemo] = useState({});
  const [isMotivosModalOpen, setIsMotivosModalOpen] = useState(false); // Nuevo estado para el modal de motivos
  const [isOtroFactura, setIsOtroFactura] = useState(false);
  const [isOtroREEMO, setIsOtroREEMO] = useState(false);
  const [otroFacturaRechazo, setOtroFacturaRechazo] = useState("");
  const [otroReemoRechazo, setOtroReemoRechazo] = useState("");
  const [isOtroTipoFactura, setIsOtroTipoFactura] = useState(false);
  const [isOtroTipoReemo, setIsOtroTipoReemo] = useState(false);

  const [showAreteModal, setShowAreteModal] = useState(false);
  const [areteRechazo, setAreteRechazo] = useState("");
  const [motivo_arete_rechazo, setMotivoAreteRechazo] = useState("");

  const [motivo_rechazo, setMotivoRechazo] = useState({});
  const [folio, setFolio] = useState({});

  const currentFactura = facturas[currentFacturaIndex];
  const [toolTip, setShowToolTip] = useState(true);

  const handleShowAreteModal = (arete) => {
    setAreteRechazo(arete);
    setShowAreteModal(true);
  };

  const handleCloseAreteModal = () => {
    setShowAreteModal(false);
  };

  useEffect(() => {
    async function fetchFolio() {
      try {
        const response = await fetch(`${apiUrl}/folio/${folio_id}`, {
          headers: {
            Authorization: `Bearer ${authState.authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Error al obtener el folio");
        }

        const result = await response.json();
        setFolio(result);

        const motivos_rechazos =
          result.historial_revisiones !== null
            ? result.historial_revisiones
            : result.motivo_rechazo_vet;

        if (motivos_rechazos.aretesRechazados) {
          motivos_rechazos.aretesRechazados.forEach((arete) => {
            facturas.forEach((factura) => {
              // obtener arete.numero_arete y arete.comentario
              const aretesInFactura = factura.aretes.map(
                (arete) => arete.numero_arete
              );

              // ver si está en la factura actual
              if (aretesInFactura.includes(arete.numero_arete)) {
                arete.numero_de_factura = factura.numero_de_factura;
                arete.comentario = facturas
                  .find((f) => f.factura_id === factura.factura_id)
                  .aretes.find(
                    (a) => a.numero_arete === arete.numero_arete
                  ).comentario;
              }
            });
          });
        }

        setMotivoRechazo(motivos_rechazos);
      } catch (error) {
        console.error("Error al obtener el folio:", error);
      }
    }

    fetchFolio();
  }, []);

  const motivoRechazos = {
    Vendedor: [
      "NOMBRE DEL VENDEDOR NO COINCIDE",
      "FALTA FIRMA DEL VENDEDOR",
      "NO CORRESPONDE LA FIRMA DEL VENDEDOR",
      "OTRO",
    ],
    Comprador: [
      "NOMBRE INCORRECTO",
      "FIRMA DEL COMPRADOR NO CORRESPONDE",
      "CLAVE DE COMPRA - VENTA",
      "CLAVE DE PSG",
      "OTRO",
    ],
    Inspector: [
      "PRODUCTOR SIN REGISTRO GANADERO",
      "FALTAN CLAVES DE GANADERP",
      "FALTAN DERIVACIONES",
      "FIERRO-MARCA NO CORRESPONDE AL VENDEDOR",
      "FACTURA NO CORRESPONDE A LUGAR DE ORIGEN",
      "TACHADURAS-ENMENDADURAS",
      "FALTA SELLO",
      "FALTA FIRMA DEL INSPECTOR",
      "OTRO",
    ],
    Otro: ["OTRO"],
  };

  useEffect(() => {
    if (currentFactura && currentFactura.aretes) {
      const verificados = currentFactura.aretes
        .filter((arete) => arete.estado === "Aprobado")
        .map((arete) => arete.arete_id);

      const rechazados = currentFactura.aretes
        .filter((arete) => arete.estado !== "Aprobado")
        .map((arete) => arete.arete_id);

      setAretesVerificados({ [currentFactura.factura_id]: verificados });
      setAretesRechazados({ [currentFactura.factura_id]: rechazados });
    }
  }, [currentFactura]);

  const handleMotivoRechazoArete = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/facturas/arete/reject/${areteRechazo.arete_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authState.authToken}`,
          },
          body: JSON.stringify({
            comentario: motivo_arete_rechazo,
          }),
        }
      );
    } catch (error) {
      console.error("Error al rechazar el arete:", error);
    }
    setAreteRechazo("");
    handleCloseAreteModal();
  };

  const handleNextFactura = () => {
    if (triggersFacturaReemo) {
      setTriggersFacturaReemo({});
    }
    if (currentFacturaIndex < facturas.length - 1) {
      setCurrentFacturaIndex(currentFacturaIndex + 1);
    }
  };

  const handleVerifyArete = (facturaId, areteId, isVerified) => {
    if (isVerified) {
      setAretesVerificados((prev) => ({
        ...prev,
        [facturaId]: [...(prev[facturaId] || []), areteId],
      }));
      setAretesRechazados((prev) => ({
        ...prev,
        [facturaId]: (prev[facturaId] || []).filter((id) => id !== areteId),
      }));
    } else {
      setAretesRechazados((prev) => ({
        ...prev,
        [facturaId]: [...(prev[facturaId] || []), areteId],
      }));
      setAretesVerificados((prev) => ({
        ...prev,
        [facturaId]: (prev[facturaId] || []).filter((id) => id !== areteId),
      }));
    }
  };

  const handleMotivoRechazoFacturaChange = (e) => {
    setSelectedMotivoFactura(e.target.value);
    if (e.target.value === "OTRO") {
      setIsOtroFactura(true);
    } else {
      setIsOtroFactura(false);
    }
  };

  const handleMotivoRechazoReemoChange = (e) => {
    setSelectedMotivoReemo(e.target.value);
    if (e.target.value === "OTRO") {
      setIsOtroREEMO(true);
    } else {
      setIsOtroREEMO(false);
    }
  };

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    if (
      selectedMotivoFactura ||
      selectedMotivoReemo ||
      otroFacturaRechazo ||
      otroReemoRechazo
    ) {
      if (selectedMotivoFactura || otroFacturaRechazo) {
        setMotivosRechazoFactura(selectedMotivoFactura);
        if (isOtroFactura || isOtroTipoFactura) {
          setMotivosRechazoFactura(otroFacturaRechazo);
        }
      }
      if (selectedMotivoReemo || otroReemoRechazo) {
        setMotivosRechazoReemo(selectedMotivoReemo);
        if (isOtroREEMO || isOtroTipoReemo) {
          setMotivosRechazoReemo(otroReemoRechazo);
        }
      }
    }

    setIsOpen(false);
    setSelectedMotivoFactura("");
    setSelectedMotivoReemo("");
    setSelectedTypeFactura("");
    setSelectedTypeReemo("");
    setIsOtroFactura(false);
    setIsOtroREEMO(false);
    setOtroFacturaRechazo("");
    setOtroReemoRechazo("");
    setIsOtroTipoFactura(false);
    setIsOtroTipoReemo(false);
  };

  const openMotivosModal = () => {
    setIsMotivosModalOpen(true);
  };

  const closeMotivosModal = () => {
    setIsMotivosModalOpen(false);
  };

  const handleSelectedTypeFactura = (value) => {
    setSelectedTypeFactura(value);
    if (value === "Otro") {
      setIsOtroTipoFactura(true);
    } else {
      setIsOtroTipoFactura(false);
    }
  };

  const handleSelectedTypeReemo = (value) => {
    setSelectedTypeReemo(value);
    if (value === "Otro") {
      setIsOtroTipoReemo(true);
    } else {
      setIsOtroTipoReemo(false);
    }
  };

  const handleReject = async () => {
    try {
      if (selectedMotivoFactura || otroFacturaRechazo) {
        const motivoFactura =
          isOtroFactura || isOtroTipoFactura
            ? otroFacturaRechazo
            : selectedMotivoFactura;
        setTriggersFacturaReemo((prev) => ({
          ...prev,
          factura: motivoFactura,
        }));
        const responseFactura = await fetch(
          `${apiUrl}/facturas/reject/${currentFactura.factura_id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authState.authToken}`,
            },
            body: JSON.stringify({
              motivo: motivoFactura,
            }),
          }
        );

        if (!responseFactura.ok) {
          throw new Error("Error al rechazar la factura");
        }

        const resultFactura = await responseFactura.json();
      }

      if (selectedMotivoReemo || otroReemoRechazo) {
        const motivoReemo =
          isOtroREEMO || isOtroTipoReemo
            ? otroReemoRechazo
            : selectedMotivoReemo;
        setTriggersFacturaReemo((prev) => ({
          ...prev,
          reemo: motivoReemo,
        }));
        const responseReemo = await fetch(
          `${apiUrl}/reemos/reject/${currentFactura.factura_id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authState.authToken}`,
            },
            body: JSON.stringify({
              motivo: motivoReemo,
            }),
          }
        );

        if (!responseReemo.ok) {
          throw new Error("Error al rechazar el REEMO");
        }

        const resultReemo = await responseReemo.json();
      }

      handleCloseModal();
    } catch (error) {
      console.error("Error al rechazar la factura o el REEMO:", error);
    }
  };

  const handleFinalSubmit = async (facturaId) => {
    try {
      await handleSubmitVerification(facturaId);
      toast.success("Información enviada correctamente");
      navigate(`/verify-aretes/${folio_id}`);
    } catch (error) {
      console.error("Error en el envío final:", error);
    }
  };

  const handleSubmitVerification = async (facturaId) => {
    try {
      const response = await fetch(
        `${apiUrl}/folio/${folio_id}/factura/${facturaId}/verify`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authState.authToken}`,
          },
          body: JSON.stringify({
            aretesVerificados: aretesVerificados[facturaId] || [],
            aretesRechazados: aretesRechazados[facturaId] || [],
            motivoRechazoFactura: motivosRechazoFactura || "",
            motivoRechazoReemo: motivosRechazoReemo || "",
            triggersFacturaReemo: triggersFacturaReemo,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Error en la verificación");
      }

      handleNextFactura();
    } catch (error) {
      console.error("Error en la verificación:", error);
    }
  };

  const handlePreviousFactura = async () => {
    if (currentFacturaIndex > 0) {
      setCurrentFacturaIndex(currentFacturaIndex - 1);
    }
  };

  const isEmpty = (obj) => {
    return (
      obj &&
      (obj.rechazos?.length === 0 || obj.rechazos === undefined) &&
      (obj.comentarios?.length === 0 || obj.comentarios === "") &&
      (obj.aretesRechazados?.length === 0 ||
        obj.aretesRechazados === undefined) &&
      (obj.reemosRechazados?.length === 0 ||
        obj.reemosRechazados === undefined) &&
      (obj.facturasRechazadas?.length === 0 ||
        obj.facturasRechazadas === undefined)
    );
  };

  const handleToolTip = () => {
    console.log("toolTip", toolTip);
    setShowToolTip(!toolTip);
  };

  return (
    <div className="w-full flex h-[calc(100vh-96px)]">
      <div className="w-full lg:w-1/2 h-full flex flex-col bg-white shadow-lg border-r">
        <div className="flex-grow overflow-y-scroll">
          <h1 className="font-titles text-2xl h-8 my-2">
            Factura No {currentFactura.numero_de_factura}
          </h1>
          <div className="w-full h-[calc(100vh-144px)]">
            {currentFactura.factura_id && (
              <PDFViewer
                pdfUrl={`${apiUrl}/files/reemo/${currentFactura.factura_id}`}
                authState={authState}
                initialOrientation="vertical"
                containerStyle={{ height: "100%", width: "100%" }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="w-1/2 h-full flex flex-col bg-white shadow-lg">
        <div className="flex flex-col h-5/6">
          <div className="flex overflow-y-scroll h-1/2">
            {currentFactura.factura_id && (
              <PDFViewer
                pdfUrl={`${apiUrl}/files/factura/${currentFactura.factura_id}`}
                authState={authState}
                initialOrientation="horizontal"
                containerStyle={{ height: "100%", width: "100%" }}
              />
            )}
          </div>
          <div className="flex overflow-y-scroll h-1/2 flex-col">
            <div className="px-2 py-2 flex flex-row">
              <h1 className="text-xl font-primary">
                Aretes - Selecciona los aretes a APROBAR.
              </h1>
              <Tooltip
                anchorSelect="[name='circle-help']"
                place="top"
                content={
                  <>
                    Los aretes marcados en azul y con una palomita serán aprobados,
                    <br />
                    los aretes que no estén marcados serán rechazados. Puedes agregar
                    <br />
                    un comentario de rechazo a cada arete si es necesario.
                  </>
                }
              />
              <CircleHelp className="h-1/2" name="circle-help" />
            </div>
            <AretesList
              aretes={currentFactura.aretes}
              handleVerifyArete={handleVerifyArete}
              aretesVerificados={aretesVerificados}
              facturaId={currentFactura.factura_id}
              handleShowAreteModal={handleShowAreteModal}
            />
          </div>
        </div>
        <div className="flex mt-4 h-1/6">
          <div className="flex  w-full ml-4 mr-4 flex-row justify-between items-center">
            {currentFacturaIndex === facturas.length - 1 ? (
              facturas.lenght === 1 ? (
                <button
                  className="bg-blue-500 text-white py-2 px-4 font-medium rounded-lg text-sm"
                  onClick={() => handleFinalSubmit(currentFactura.factura_id)}
                >
                  Subir Información
                </button>
              ) : (
                <div className="flex space-x-4">
                  <button
                    className="bg-blue-500 text-white py-2 px-4 font-medium rounded-lg text-sm"
                    hidden={currentFacturaIndex === 0}
                    onClick={() => handlePreviousFactura()}
                  >
                    Anterior
                  </button>
                  <button
                    className="bg-blue-500 text-white py-2 px-4 font-medium rounded-lg text-sm"
                    onClick={() => handleFinalSubmit(currentFactura.factura_id)}
                  >
                    Subir Información
                  </button>
                </div>
              )
            ) : (
              <div className="flex space-x-4">
                <button
                  className="bg-blue-500 text-white py-2 px-4 font-medium rounded-lg text-sm"
                  hidden={currentFacturaIndex === 0}
                  onClick={() => handlePreviousFactura()}
                >
                  Anterior
                </button>
                <button
                  className="bg-blue-500 text-white py-2 px-4 font-medium rounded-lg text-sm"
                  onClick={() =>
                    handleSubmitVerification(currentFactura.factura_id)
                  }
                >
                  Siguiente
                </button>
              </div>
            )}
            {!isEmpty(motivo_rechazo) && (
              <button
                className="bg-yellow-500 text-white py-2 px-4 font-medium rounded-lg text-sm"
                onClick={openMotivosModal}
              >
                Ver Motivos de Rechazo
              </button>
            )}

            <button
              onClick={handleOpenModal}
              type="button"
              className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            >
              Rechazar
            </button>
          </div>
        </div>
      </div>
      <RejectionModal
        isOpen={isOpen}
        handleCloseModal={handleCloseModal}
        handleReject={handleReject}
        motivoRechazos={motivoRechazos}
        selectedTypeFactura={selectedTypeFactura}
        setSelectedTypeFactura={setSelectedTypeFactura}
        selectedMotivoFactura={selectedMotivoFactura}
        handleMotivoRechazoFacturaChange={handleMotivoRechazoFacturaChange}
        selectedTypeReemo={selectedTypeReemo}
        setSelectedTypeReemo={setSelectedTypeReemo}
        selectedMotivoReemo={selectedMotivoReemo}
        handleMotivoRechazoReemoChange={handleMotivoRechazoReemoChange}
        isOtroFactura={isOtroFactura}
        isOtroREEMO={isOtroREEMO}
        otroFacturaRechazo={otroFacturaRechazo}
        setOtroFacturaRechazo={setOtroFacturaRechazo}
        otroReemoRechazo={otroReemoRechazo}
        setOtroReemoRechazo={setOtroReemoRechazo}
        isOtroTipoFactura={isOtroTipoFactura}
        isOtroTipoReemo={isOtroTipoReemo}
        handleSelectedTypeFactura={handleSelectedTypeFactura}
        handleSelectedTypeReemo={handleSelectedTypeReemo}
      />
      <MotivosRechazoModal
        isOpen={isMotivosModalOpen}
        handleClose={closeMotivosModal}
        motivos={motivo_rechazo}
      />
      <Modal show={showAreteModal} onClose={handleCloseAreteModal}>
        <div className="flex flex-col p-4">
          <label htmlFor="comentario" className="text-sm font-primary">
            Comentario De Rechazo Arete: {areteRechazo.numero_arete}
          </label>
          <textarea
            id="comentario"
            className="border border-gray-300 rounded-md p-2"
            value={motivo_arete_rechazo}
            onChange={(e) => setMotivoAreteRechazo(e.target.value)}
          ></textarea>
          <div className="flex flex-row justify-between mt-4">
            <button
              className="bg-blue-500 text-white py-2 px-4 font-medium rounded-lg text-sm mt-4"
              onClick={handleMotivoRechazoArete}
            >
              Enviar
            </button>
            <button
              className="bg-red-500 text-white py-2 px-4 font-medium rounded-lg text-sm mt-4"
              onClick={handleCloseAreteModal}
            >
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DocumentsVerification;
