import React from "react";
import Button from "../../../../Custom/Button";

const AretesList = ({
  aretes,
  handleVerifyArete,
  aretesVerificados,
  facturaId,
  handleShowAreteModal,
}) => {
  //ordenar aretes de menor a mayor
  return (
    <div className="flex-grow overflow-y-scroll p-2">
      {(aretes.sort((a, b) => a.numero_arete - b.numero_arete) || []).map(
        (arete) => {
          const bg = aretesVerificados[facturaId]?.includes(arete.arete_id) ? "bg-blue-100" : "bg-grey-100";
          return (
            <div className="flex flex-row justify-center items-center">
              <div
                key={arete.arete_id}
                className={`flex w-4/5 justify-between items-center p-2 mb-1 border rounded-md cursor-pointer transition-colors duration-200 h-10 ${bg}`}
                onClick={() =>
                  handleVerifyArete(
                    facturaId,
                    arete.arete_id,
                    !aretesVerificados[facturaId]?.includes(arete.arete_id)
                  )
                }
              >
                <span>
                  {arete.numero_arete} -{" "}
                  {arete.sexo === "M" ? "Macho" : "Hembra"}
                </span>
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-600"
                  checked={
                    aretesVerificados[facturaId]?.includes(arete.arete_id) ||
                    false
                  }
                  readOnly
                />
              </div>
              <div className="flex flex-col justify-center ml-4 items-center w-1/6">
                {!aretesVerificados[facturaId]?.includes(arete.arete_id) ? (
                  <Button
                    className="bg-red-700 hover:bg-red-900 text-[10px] h-10 w-full mb-1"
                    onClick={() => handleShowAreteModal(arete)}
                  >
                    {" "}
                    Agregar Comentario{" "}
                  </Button>
                ) : null}
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

export default AretesList;
