import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "../../Custom/Button";

function Root() {
  const navigate = useNavigate();
  const { authState } = useContext(AuthContext);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 10,
  });
  const [search, setSearch] = useState("");
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(`${apiUrl}/auth/`, {
          headers: {
            Authorization: `${authState.authToken}`,
          },
        });
        if (!res.ok) {
          throw new Error("Response not ok from API");
        }
        const json = await res.json();
        // Ordenar los datos por usuario_id de manera ascendente
        const sortedData = json.sort((a, b) => a.usuario_id - b.usuario_id);
        setData(sortedData);
        setFilteredData(sortedData.slice(0, pagination.itemsPerPage));
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [authState.authToken]);

  const handleEditClick = (id) => {
    navigate(`/edit-user/${id}`);
  };

  const handleDeleteClick = (id) => {
    fetch(`${apiUrl}/auth/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `${authState.authToken}`,
      },
    });
  };

  const handleFilter = () => {
    // Filtrar los datos por el campo de búsqueda teniendo en cuenta la paginación y search
    const filtered = data?.filter((item) => {
      if (search === "") return true;
      return (
        item.usuario_id.toString().includes(search) ||
        item.username.toLowerCase().includes(search.toLowerCase()) ||
        item.rol.toLowerCase().includes(search.toLowerCase())
      )
    });

    setTotalItems(filtered?.length);
    const start = (pagination.currentPage - 1) * pagination.itemsPerPage;
    const end = start + pagination.itemsPerPage;
    setFilteredData(filtered?.slice(start, end));
  };

  const handlePagination = (type) => () => {
    if (type === "prev") {
      if (pagination.currentPage === 1) return;
      setPagination({
        ...pagination,
        currentPage: pagination.currentPage - 1,
      });
    } else {
      if (pagination.currentPage === Math.ceil(totalItems / pagination.itemsPerPage)) return;
      setPagination({
        ...pagination,
        currentPage: pagination.currentPage + 1,
      });
    }
  };

  useEffect(() => {
    handleFilter();
  }, [search]);

  useEffect(() => {
    handleFilter();
  }, [pagination]);

  const tableHeaders = ["User Id", "Nombre de Usuario", "Nombre", "Rol"];

  return (
    <div className="flex flex-col w-full px-12 min-h-[calc(100vh-96px)]">
      <div className="container mx-auto px-4 sm:px-8">
        <div className="">
          <div>
            <h2 className="text-2xl leading-tight font-titles">Usuarios</h2>
          </div>
          <div className="my-2 flex justify-between items-center">
            <div className="flex flex-row">
              <div className="relative">
                <select
                  className="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                  onChange={(e) =>
                    setPagination({
                      ...pagination,
                      itemsPerPage: parseInt(e.target.value),
                    })
                  }
                  value={pagination.itemsPerPage}
                >
                  <option>5</option>
                  <option>10</option>
                  <option>20</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    className="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              <div className="block relative">
                <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                  <svg
                    viewBox="0 0 24 24"
                    className="h-4 w-4 fill-current text-gray-500"
                  >
                    <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
                  </svg>
                </span>
                <input
                  placeholder="Search"
                  className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="flex">
              <Button
                onClick={() => navigate("/crud")}
                className="bg-blue-600 hover:bg-blue-700 text-white mr-4"
              >
                Editar Catálogos
              </Button>
              <Button
                onClick={() => navigate("/create-user")}
                className="bg-green-600 hover:bg-green-700 text-white"
              >
                Crear Usuario
              </Button>
            </div>
          </div>
          <div className="w-full px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr className="bg-blue-100">
                    {tableHeaders.map((header) => (
                      <th
                        key={header}
                        className="text-left px-8 py-4 font-primary"
                      >
                        {header}
                      </th>
                    ))}
                    <th className="text-left px-8 py-4 font-primary">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData?.map((item) => (
                    <tr key={item.usuario_id} className="border-b">
                      <td className="text-left px-8 py-4">{item.usuario_id}</td>
                      <td className="text-left px-8 py-4">{item.username}</td>
                      <td className="text-left px-8 py-4">{item.nombre}</td>
                      <td className="text-left px-8 py-4">{item.rol}</td>
                      <td className="px-8 py-4 flex justify-center">
                        <div className="flex items-center">
                          <div
                            className="px-1 cursor-pointer"
                            onClick={() => handleEditClick(item.usuario_id)}
                          >
                            <button className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm py-2 px-4 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 flex items-center">
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="px-2"
                              />
                              Editar
                            </button>
                          </div>
                          <div
                            className="px-1 cursor-pointer"
                            onClick={() => handleDeleteClick(item.usuario_id)}
                          >
                            {/* <button className=" text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm py-2 px-4 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 flex items-center">
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="px-2"
                              />
                              Eliminar
                            </button> */}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                { <span className="text-xs xs:text-sm text-gray-900">
                  
                  Mostrado {search!==""&&filteredData?.length<pagination.itemsPerPage?(filteredData?.length):(pagination.itemsPerPage * pagination.currentPage)}{" "}
                  de {search===""?(data?.length):totalItems} entradas
                </span> }
                <div className="inline-flex mt-2 xs:mt-0">
                  <button className="text-sm bg-blue-700 hover:bg-blue-800 text-white font-semibold py-2 px-4 rounded-l" onClick={handlePagination("prev")}>
                    Prev
                  </button>
                  <button className="text-sm bg-blue-700 hover:bg-blue-800 text-white font-semibold py-2 px-4 rounded-r" onClick={handlePagination("next")}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Root;
