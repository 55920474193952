import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const ImageCropUpload = ({ onImageCrop }) => {
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 1 });
  const [croppedImageUrl, setCroppedImageUrl] = useState("");

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setSrc(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const onImageLoaded = (image) => {
    setCrop({
      unit: "%",
      width: 30,
      height: (image.height / image.width) * 30,
      x: 0,
      y: 0,
      aspect: 1,
    });
  };

  const onCropComplete = (crop) => {
    makeCroppedImage(crop);
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const makeCroppedImage = async (crop) => {
    if (!crop.width || !crop.height) {
      return;
    }

    const image = new Image();
    image.src = src;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg");
      setCroppedImageUrl(base64Image);
      onImageCrop(base64Image);
    };
  };

  return (
    <div>
      {!src ? (
        <div>
          <input type="file" accept="image/*" onChange={onFileChange} />
          <p>Selecciona una imagen para subir y recortar</p>
        </div>
      ) : (
        <div>
          <ReactCrop
            src={src}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
            onChange={onCropChange}
          />
          {croppedImageUrl && (
            <img alt="Crop" style={{ maxWidth: "100%" }} src={croppedImageUrl} />
          )}
        </div>
      )}
    </div>
  );
};

export default ImageCropUpload;
