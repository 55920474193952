import AreteInput from "../AreteInput";
import React, { useRef, useState } from "react";
import NumberInput from "../../Custom/Forms/NumberInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faFileUpload,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

const FacturaUpload = ({
  group,
  index,
  handleFileChange,
  handleNumberChange,
  handleAreteChange,
  handleAddArete,
  handleRemoveArete,
  handleRemoveGroup,
  wichFileFacturaLoaded,
  wichFileReemoLoaded,

}) => {
  const [facturaUploaded, setFacturaUploaded] = useState(false);
  const [reemoUploaded, setReemoUploaded] = useState(false);

  const facturaInputRef = useRef(null);
  const reemoInputRef = useRef(null);

  const handleFacturaClick = () => {
    facturaInputRef.current.click();
  };

  const handleReemoClick = () => {
    reemoInputRef.current.click();
  };

  const onFacturaChange = (e) => {
    if (e.target.files[0].type !== 'application/pdf') {
      alert('El archivo debe ser un PDF');
      return;
    }
    handleFileChange(index, "factura", e.target.files[0]);
    setFacturaUploaded(true);
  };

  const onReemoChange = (e) => {
    if (e.target.files[0].type !== 'application/pdf') {
      alert('El archivo debe ser un PDF');
      return;
    }
    handleFileChange(index, "reemo", e.target.files[0]);
    setReemoUploaded(true);
  };

  const onFacturaNumberChange = (e) => {
    handleNumberChange(index, "facturaNumber", e.target.value);
  };

  const onReemoNumberChange = (e) => {
    handleNumberChange(index, "reemoNumber", e.target.value);
  };

  return (
    <div className="mb-4 font-primary text-sm">
      <div className="flex flex-col justify-between w-full">
        <p className="mb-2">Grupo N° {index+1}</p>
        <div className="flex justify-between mb-2">
          <div
            className="flex justify-between cursor-pointer flex-grow items-center mr-4 h-10 bg-gray-50 border border-gray-300 rounded-lg p-2.5"
            onClick={handleFacturaClick}
          >
            <p className="mr-2">Subir Factura:</p>
            <button
              type="button"
              className="text-gray-500 hover:text-gray-700 focus:outline-none mx-auto"
            >
              <FontAwesomeIcon
                icon={facturaUploaded||wichFileFacturaLoaded[index]?.loaded===true ? faCheckCircle : faFileUpload}
                size="lg"
                className={`${
                  facturaUploaded||wichFileFacturaLoaded[index]?.loaded===true ? "text-green-500" : "text-red-500"
                }`}
              />
            </button>
            <input
              type="file"
              accept=".pdf"
              ref={facturaInputRef}
              onChange={onFacturaChange}
              className="hidden"
              data-testid={`factura-file-${index}`}
            />
          </div>
          <div className="">
            <NumberInput
              required
              id="numeroFactura"
              value={group.facturaNumber}
              onChange={onFacturaNumberChange}
              placeholder="Número de Factura"
              className={"mr-2 w-1/2"}
              data-testid={`factura-number-${index}`}
              maxLength={9}
            />
          </div>
        </div>
        <div className="flex justify-between mb-2">
          <div
            className="flex justify-between cursor-pointer flex-grow items-center mr-4 h-10 bg-gray-50 border border-gray-300 rounded-lg p-2.5"
            onClick={handleReemoClick}
          >
            <p className="mr-2">Subir Reemo:</p>
            <button
              type="button"
              className="text-gray-500 hover:text-gray-700 focus:outline-none mx-auto"
            >
              <FontAwesomeIcon
                icon={reemoUploaded||wichFileReemoLoaded[index]?.loaded===true ? faCheckCircle : faFileUpload}
                size="lg"
                className={`${
                  reemoUploaded||wichFileReemoLoaded[index]?.loaded===true ? "text-green-500" : "text-red-500"
                }`}
              />
            </button>
            <input
              type="file"
              accept=".pdf"
              ref={reemoInputRef}
              onChange={onReemoChange}
              className="hidden"
              data-testid={`reemo-file-${index}`}
            />
          </div>
          <div className="">
            <NumberInput
              required
              id="numeroReemo"
              value={group.reemoNumber}
              onChange={onReemoNumberChange}
              placeholder="Número de Reemo"
              className={"mr-2 w-1/2"}
              data-testid={`reemo-number-${index}`}
              maxLength={9}
            />
          </div>
        </div>
      </div>
      {group.aretes.map((arete, areteIndex) => (
        <AreteInput
          key={areteIndex}
          index={index}
          arete={arete}
          areteIndex={areteIndex}
          handleAreteChange={handleAreteChange}
          handleRemoveArete={handleRemoveArete}
        />
      ))}

      <section
        data-testid={`agregar-arete-${index}`}
        className="w-full border-2 rounded-md py-2 mb-4 cursor-pointer flex justify-center flex-col items-center"
        onClick={() => handleAddArete(index)}
      >
        <p className="text-xs">Agregar Arete</p>
        <FontAwesomeIcon icon={faPlus} size="lg" />
      </section>

      <button
        type="button"
        onClick={() => handleRemoveGroup(index)}
        className="bg-rose-500 hover:bg-rose-700 text-white font-bold py-2 px-4 rounded"
      >
        Quitar Grupo
      </button>
    </div>
  );
};

export default FacturaUpload;
