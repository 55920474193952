import React from "react";
import "./styles.css";

const Modal = ({ show, onCancel, onAccept, children }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="bg-black bg-opacity-50 absolute inset-0"
        onClick={onCancel}
      ></div>
      <div className="bg-white p-6 rounded-lg shadow-lg relative z-10 min-h-[50vh]  min-w-[20%]  max-w-[60%] md:max-w-[40%] w-full flex flex-col">
        <div className="flex-grow overflow-y-auto">{children}</div>
        <div className="flex justify-end mt-4">
          <button
            onClick={onAccept}
            className="text-white bg-green-500 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-4"
          >
            Aceptar
          </button>
          <button
            onClick={onCancel}
            className="text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
