import React, { useState, useEffect } from "react";
import axios from "axios";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { rotatePlugin } from "@react-pdf-viewer/rotate";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";

const PDFViewer = ({
  pdfUrl,
  authState,
  initialOrientation = "vertical",
  containerStyle,
}) => {
  const [pdfFile, setPdfFile] = useState(null);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const zoomPluginInstance = zoomPlugin();
  const rotatePluginInstance = rotatePlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();

  useEffect(() => {
    if (pdfUrl) {
      axios
        .get(pdfUrl, {
          responseType: "blob",
          headers: {
            Authorization: `${authState.authToken}`,
          },
        })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          setPdfFile(fileURL);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [pdfUrl, authState.authToken]);

  const rotation = initialOrientation === "horizontal" ? 270 : 0;
  const defaultScale = 1; // Ajuste de zoom inicial

  return (
    <div className="w-full h-full" style={containerStyle}>
      {pdfFile && (
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}
        >
          <Viewer
            fileUrl={pdfFile}
            initialRotation={rotation}
            defaultScale={defaultScale}
            plugins={[
              defaultLayoutPluginInstance,
              zoomPluginInstance,
              rotatePluginInstance,
              pageNavigationPluginInstance,
            ]}
          />
        </Worker>
      )}
    </div>
  );
};

export default PDFViewer;
