import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es"; // importar el locale español

registerLocale("es", es); // registrar el locale español

const DatePickerInput = ({ id, name, label, selected, onChange, className }) => {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-900 dark:text-white">
        {label}
      </label>
      <DatePicker
        id={id}
        name={name}
        selected={selected ? new Date(selected) : null}
        onChange={(date) => onChange({ target: { name, value: date } })}
        dateFormat="yyyy-MM-dd"
        locale="es" // usar el locale español
        className={`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${className}`}
        placeholderText="YYYY-MM-DD"
      />
    </div>
  );
};

export default DatePickerInput;
