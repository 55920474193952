import React from "react";
import { AuthContext } from "../../Context/AuthContext";
import { Navigate } from "react-router-dom";
import Root from "../../Components/HomePageUser/Root";
import SubSecretario from "../../Components/HomePageUser/SubSecretario";
import Acopiador from "../../Components/HomePageUser/Acopiador";
import Veterinaria from "../../Components/HomePageUser/Veterinaria";
import Secretaria from "../../Components/HomePageUser/Secretaria";
import Layout from "../../Components/Layout";

const HomePage = () => {
  const { authState } = React.useContext(AuthContext);

  return (
    <Layout>
      <div className="mt-4 w-full">
        {authState.user?.rol === "Acopiador" && <Acopiador />}
        {authState.user?.rol === "Veterinaria" && <Veterinaria />}
        {authState.user?.rol === "Secretaria" && <Secretaria />}
        {authState.user?.rol === "Root" && <Root />}
        {authState.user?.rol === "SubSecretario" && <SubSecretario />}
        {authState.authToken && authState.user ? null : (
          <Navigate to="/login" />
        )}
      </div>
    </Layout>
  );
};

export default HomePage;
