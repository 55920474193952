  import React, { useState } from 'react';
  const DropZone = ({ onDrop, label, fileUploaded, setFileUploaded, fileName, setFileName}) => {
    
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      // si el documento no es pdf, no se sube
      if (file.type !== 'application/pdf') {
        alert('El archivo debe ser un PDF');
        return;
      }
      if (file) {
        setFileUploaded(true);
        setFileName(file.name);
        onDrop(e);
      }
    };

    return (
      <div className="mb-5 w-full">
        <label
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          htmlFor="user_avatar"
        >
          Sube aquí tu {label ? label : 'archivo'}
        </label>

        <div className="flex items-center justify-center w-full">
          <label
            htmlFor="dropzone-file"
            className={`flex flex-col items-center justify-center w-full h-64 min-h-[16rem] max-w-xl mx-auto border-2 border-gray-300 border-dashed rounded-lg cursor-pointer ${
              fileUploaded ? 'bg-green-50 border-green-300' : 'bg-gray-50'
            } dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600`}
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              {fileUploaded ? (
                <>
                  <p className="mb-2 text-sm text-green-500 dark:text-green-400 text-center truncate max-w-xs">
                    <span className="font-semibold">Archivo cargado:</span> {fileName}
                  </p>
                </>
              ) : (
                <>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400 text-center">
                    <span className="font-semibold">Haz click para cargar un archivo</span>
                  </p>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400 text-center">
                  <span className="font-semibold">desde tu computadora</span>
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400 text-center">PDF (MAX 10MB.)</p>
                </>
              )}
            </div>
            <input
              id="dropzone-file"
              type="file"
              accept='.pdf'
              className="hidden"
              onChange={handleFileChange}
            />
          </label>
        </div>
        
      </div>
    );
  };

  export default DropZone;
