import React from 'react';

const RejectionModal = ({
  isOpen,
  handleCloseModal,
  handleReject,
  motivoRechazos,
  selectedTypeFactura,
  setSelectedTypeFactura,
  selectedMotivoFactura,
  handleMotivoRechazoFacturaChange,
  selectedTypeReemo,
  setSelectedTypeReemo,
  selectedMotivoReemo,
  handleMotivoRechazoReemoChange,
  isOtroFactura,
  isOtroREEMO,
  otroFacturaRechazo,
  setOtroFacturaRechazo,
  otroReemoRechazo,
  setOtroReemoRechazo,
  isOtroTipoFactura,
  isOtroTipoReemo,
  handleSelectedTypeFactura,
  handleSelectedTypeReemo,
}) => (
  isOpen && (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
        <h2 className="text-xl font-bold mb-4">Motivo de Rechazo</h2>
        <div className="mb-4">
          <label
            htmlFor="tipo-rechazo-factura"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Tipo de Rechazo (Factura):
          </label>
          <select
            id="tipo-rechazo-factura"
            className="mb-4 p-2 border border-gray-300 rounded-lg w-full"
            value={selectedTypeFactura}
            onChange={(e) => handleSelectedTypeFactura(e.target.value)}
          >
            <option value="">Seleccione un tipo</option>
            {Object.keys(motivoRechazos).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          <label
            htmlFor="motivo-rechazo-factura"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Motivo (Factura):
          </label>
          <select
            id="motivo-rechazo-factura"
            className="mb-4 p-2 border border-gray-300 rounded-lg w-full"
            value={selectedMotivoFactura}
            onChange={handleMotivoRechazoFacturaChange}
            hidden={isOtroTipoFactura}
          >
            <option value="">Seleccione un motivo</option>
            {motivoRechazos[selectedTypeFactura]?.map((motivo) => (
              <option key={motivo} value={motivo}>
                {motivo}
              </option>
            ))}
          </select>
          <textarea
            className={`p-2 border border-gray-300 rounded-lg w-full ${
              (isOtroFactura|isOtroTipoFactura) ? "block" : "hidden"
            }`}
            placeholder="Ingrese su motivo"
            onChange={(e) => setOtroFacturaRechazo(e.target.value)}
            value={otroFacturaRechazo}
          ></textarea>
        </div>
        <div className="mb-4">
          <label
            htmlFor="tipo-rechazo-reemo"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Tipo de Rechazo (REEMO):
          </label>
          <select
            id="tipo-rechazo-reemo"
            className="mb-4 p-2 border border-gray-300 rounded-lg w-full"
            value={selectedTypeReemo}
            onChange={(e) => handleSelectedTypeReemo(e.target.value)}
          >
            <option value="">Seleccione un tipo</option>
            {Object.keys(motivoRechazos).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          <label
            htmlFor="motivo-rechazo-reemo"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Motivo (REEMO):
          </label>
          <select
            id="motivo-rechazo-reemo"
            className="mb-4 p-2 border border-gray-300 rounded-lg w-full"
            value={selectedMotivoReemo}
            onChange={handleMotivoRechazoReemoChange}
            hidden={isOtroTipoReemo}
          >
            <option value="">Seleccione un motivo</option>
            {motivoRechazos[selectedTypeReemo]?.map((motivo) => (
              <option key={motivo} value={motivo}>
                {motivo}
              </option>
            ))}
          </select>
          <textarea
            className={`p-2 border border-gray-300 rounded-lg w-full ${
              (isOtroREEMO|isOtroTipoReemo) ? "block" : "hidden"
            }`}
            placeholder="Ingrese su motivo"
            onChange={(e) => setOtroReemoRechazo(e.target.value)}
            value={otroReemoRechazo}
          ></textarea>
        </div>
        <div className="flex justify-end">
          <button
            onClick={handleCloseModal}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg mr-2"
          >
            Cancelar
          </button>
          <button
            onClick={handleReject}
            className="bg-red-500 text-white px-4 py-2 rounded-lg"
          >
            Rechazar
          </button>
        </div>
      </div>
    </div>
  )
);

export default RejectionModal;
