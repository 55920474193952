import React from "react";

const Select = ({
  id,
  name,
  value,
  onChange,
  options,
  required = false,
  className = "",
  label,
  sorted = false,
}) => {
  // Ordenar las opciones por etiqueta en orden alfabético si sorted es verdadero
  const sortedOptions = sorted
    ? [...options].sort((a, b) => a.label.localeCompare(b.label))
    : options;

  return (
    <div className="mb-4">
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-gray-900 dark:text-white"
        >
          {label}
        </label>
      )}
      <select
        id={id}
        name={name}
        required={required}
        value={value}
        onChange={onChange}
        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 h-10 ${className} dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
      >
        {sortedOptions.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
            {option.extra && (
              <span style={{ color: "#b3b3b3" }}>{` - ${option.extra}`}</span>
            )}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
