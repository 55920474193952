import React from "react";

const MotivosRechazoModal = ({ isOpen, handleClose, motivos }) => {
  if (!isOpen) return null;

  const comentarios = motivos.comentarios || "";
  const rechazos = motivos || [];
  const apiUrl = process.env.REACT_APP_API_URL;

  const {
    aretesRechazados = [],
    reemosRechazados = [],
    facturasRechazadas = [],
  } = rechazos;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white relative mt-[96px] p-6 rounded-lg shadow-lg min-w-96 max-h-[80vh] overflow-y-auto">
        <h2 className="text-xl font-bold mb-4">Motivos de Rechazo</h2>
        {aretesRechazados.length > 0 && (
          <div className="mb-4">
            <h3 className="text-lg font-semibold">Aretes Rechazados</h3>
            <ul className="list-disc list-inside space-y-1">
              {aretesRechazados.map((rechazo, index) => (
                <div key={index} className="text-sm truncate border-t-2">
                  <strong>Arete:</strong> {rechazo.numero_arete} -{" "}
                  {rechazo.motivo_rechazo} <br />
                  <strong>Factura:</strong> {rechazo.numero_de_factura}
                  {rechazo.comentario ? (
                    <div>
                      <strong>Comentario:</strong> {rechazo.comentario}
                    </div>
                  ) : null}
                </div>
              ))}
            </ul>
          </div>
        )}

        {reemosRechazados.length > 0 && (
          <div className="mb-4">
            <h3 className="text-lg font-semibold">Reemos Rechazados</h3>
            <ul className="list-disc list-inside space-y-1">
              {reemosRechazados.map((rechazo, index) => (
                <li key={index} className="text-sm truncate">
                  <strong>Reemo: </strong> {rechazo.numero_de_reemo} -{" "}
                  {rechazo.motivo_rechazo}
                </li>
              ))}
            </ul>
          </div>
        )}

        {facturasRechazadas.length > 0 && (
          <div className="mb-4">
            <h3 className="text-lg font-semibold">Facturas Rechazadas</h3>
            <ul className="list-disc list-inside space-y-1">
              {facturasRechazadas.map((rechazo, index) => (
                <li key={index} className="text-sm truncate">
                  <strong>Factura:</strong> {rechazo.numero_de_factura} -{" "}
                  {rechazo.motivo_rechazo}
                </li>
              ))}
            </ul>
          </div>
        )}
        <ul className="list-disc list-inside space-y-1">
          <h3 className="text-lg font-semibold">Comentarios</h3>
          {comentarios.length ? (
            <li className="text-sm truncate">{comentarios}</li>
          ) : (
            <li className="text-sm truncate">Sin comentarios</li>
          )}
        </ul>
        <button
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleClose}
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default MotivosRechazoModal;
