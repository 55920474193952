import React from "react";

const TextInput = ({
  id,
  value,
  onChange,
  placeholder,
  className = '',
  maxLength = 10,
  validationPattern,
  label, // Adding label prop
  toUpperCase, // Adding toUpperCase prop
  ...props
}) => {
  const handleInputChange = (e) => {
    const { value } = e.target;
    // Verifica la longitud del texto
    if (value.length <= maxLength) {
      // Si se proporciona un patrón de validación, verifica el valor
      if (!validationPattern || new RegExp(validationPattern).test(value)) {
        onChange(e);
      }
    }
  };
  // Verifica si se debe convertir el valor a mayúsculas
  if (toUpperCase) {
    value = value.toUpperCase();
  }
  return (
    <div className="mb-4">
      {label && <label htmlFor={id} className="block text-sm font-medium text-gray-900 dark:text-white">{label}</label>}
      <input
        id={id}
        type="text"
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={`text-input border rounded-lg bg-gray-50 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full text-sm p-2.5 h-10 ${className}`}
        {...props}
      />
    </div>
  );
};

export default TextInput;
