import Layout from "../../Components/Layout";
import AretesVerification from "../../Components/Verification/AretesVerification";

const VerifyAretes = () => {
  return (
    <Layout>
        <AretesVerification />
    </Layout>
  );
};

export default VerifyAretes;
