import React, { useState } from "react";
import { useAuth } from "../../../Context/AuthContext";

import Select from "../../../Components/Custom/Forms/Select";
import Layout from "../../../Components/Layout";
import Destinos from "./Components/Destinos";
import Motivos from "./Components/Motivos";
import Especies from "./Components/Especies";
const Cruds = () => {
  const { authState } = useAuth();

  const [tab, setTab] = useState("destinos");

  const options = [
    { value: "destinos", label: "Destinos" },
    { value: "motivos", label: "Motivos" },
    { value: "especies", label: "Especies" },
  ];
  return (
    <Layout>
      <h1 className="my-4 font-titles text-2xl">
        Selecciona en que catálogo quieres trabajar:
      </h1>
    <div className="flex justify-between w-[85%]">
      <h1 className="text-2xl font-primary">{tab.charAt(0).toUpperCase() + tab.slice(1)}</h1>
    <Select
        value={tab}
        options={options}
        onChange={(e) => setTab(e.target.value)}
      />
    </div>
      {tab === "destinos" && <Destinos authState={authState} />}
      {tab === "motivos" && <Motivos authState={authState}/>}
      {tab === "especies" && <Especies authState={authState} />}
    </Layout>
  );
};

export default Cruds;
