import React from 'react';

/**
 * 
 * @param {string} size - Tamaño del botón (large, normal, small)
 * @param {string} children - Contenido del botón
 * @param {function} onClick - Función que se ejecuta al hacer clic en el botón
 * @param {string} type - Tipo del botón (normal, success, reject, alert)
 * @param {string} container - Ancho del botón (normal, full)
 * @returns 
 */
const Button = ({ size, children, onClick, type, container, className, disabled}) => {
    // Clases base de Tailwind para todos los botones
    const baseClasses = 'text-white font-titles rounded-lg focus:outline-none focus:shadow-outline';

    // Clases específicas de Tailwind según el tamaño
    const sizeClasses = {
        large: 'text-lg px-10 py-4',
        normal: 'text-md px-4 py-2',
        small: 'text-sm px-4 py-1',
    };

    // Clases específicas de Tailwind según el tipo
    const typeClasses = {
        normal: 'bg-blue-700 hover:bg-blue-800',
        success: 'bg-green-600 hover:bg-green-700',
        reject: 'bg-red-600 hover:bg-red-700',
        alert: 'bg-yellow-500 hover:bg-yellow-600',
    };

    // Clases específicas de Tailwind según el ancho del contenedor
    const containerClasses = {
        normal: 'w-auto',
        full: 'w-full',
    };

    // Combinar todas las clases
    const classes = `${baseClasses} ${className} ${sizeClasses[size] || sizeClasses.normal} ${typeClasses[type] || typeClasses.normal} ${containerClasses[container] || containerClasses.normal}`;

    return (
        <button type={type} className={classes} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    );
};

export default Button;
