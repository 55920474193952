import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Navbar from "./Components/Navbar";
import LoginPage from "./Pages/LoginPage";
import HomePage from "./Pages/HomePage";
import FolioForm from "./Pages/FolioForm";
import AdminPage from "./Pages/Administrator/AdminPage";
import CreateUser from "./Pages/Administrator/CreateUser";
import EditUser from "./Pages/Administrator/EditUser";
import DocumentsForm from "./Pages/DocumentsForm";
import SubCheckPage from "./Pages/SubCheckPage";
import SignedDocUpload from "./Pages/SignedDocUpload";
import PaymentUpload from "./Pages/PaymentUpload";
import PaymentAsign from "./Pages/PaymentAsign";
import PaymentsList from "./Pages/Payments/PaymentsList";
import PaymentsVerify from "./Pages/Payments/PaymentsVerify";
import PaymentInvoice from "./Pages/PaymentInvoice";
import FolioViewer from "./Pages/FolioViewer";
import ReUploadDocuments from "./Pages/ReUploadDocuments";
import VerifyFacturas from "./Pages/VerifyFacturas";
import VerifyAretes from "./Pages/VerifyAretes";
import ChangePassword from "./Components/ChangePassword";
import NotFound from "./Pages/NotFound";

/* Solo para pruebas */
import TestPage from "./Pages/TestPage";

import { AuthProvider, useAuth } from "./Context/AuthContext";
import ErrorBoundary from "./Components/Error/ErrorBoundary";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Cruds from "./Pages/Administrator/Cruds";


/* App */
const App = () => {
  return (
    <div className="App">
      <AuthProvider>
        <ErrorBoundary>
          <BrowserRouter>
            <Navbar />
            <AppRoutes />
            <ToastContainer style={{ marginTop: "92px" }} />
          </BrowserRouter>
        </ErrorBoundary>
      </AuthProvider>
    </div>
  );
};

const AppRoutes = () => {
  const { authState } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authState.user?.needsPasswordChange) {
      navigate("/change-password");
    }
  }, [authState.user, navigate]);

  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/folio-viewer/:id" element={<FolioViewer />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/change-password" element={<ChangePassword />} />
      </Route>
      {/* Todos */}
      <Route
        element={
          <PrivateRoute
            allowedRoles={["Acopiador", "Veterinaria", "Secretaria", "Root"]}
          />
        }
      >
        <Route path="/inicio" element={<HomePage />} />
        <Route path="/admin" element={<AdminPage />} />
      </Route>
      {/* Acopiador */}
      <Route element={<PrivateRoute allowedRoles={["Acopiador"]} />}>
        <Route path="/new" element={<DocumentsForm />} />
        <Route path="/solve-folio/:folio_id" element={<ReUploadDocuments />} />
        <Route path="/asign-payment/:folio_id" element={<PaymentAsign />} />
        <Route path="/upload-payment" element={<PaymentUpload />} />
      </Route>
      {/* Veterinaria*/}
      <Route element={<PrivateRoute allowedRoles={["Veterinaria"]} />}>
        <Route path="/payments" element={<PaymentsList />} />
        <Route path="/verify-payments/:id" element={<PaymentsVerify />} />
        <Route path="/payment-invoice/:folio_id" element={<PaymentInvoice />} />
      </Route>
      {/*  Secretaria */}
      <Route element={<PrivateRoute allowedRoles={["Secretaria"]} />}>
        <Route path="/permiso/:id" element={<SubCheckPage />} />
        <Route path="/upload-signed-doc/:id" element={<SignedDocUpload />} />
      </Route>
      {/* Secretaria y Veterinaria */}
      <Route
        element={<PrivateRoute allowedRoles={["Secretaria", "Veterinaria"]} />}
      >
        <Route path="/folio/:folio_id" element={<FolioForm />} />
        <Route path="/verify-facturas/:folio_id" element={<VerifyFacturas />} />
        <Route path="/verify-aretes/:folio_id" element={<VerifyAretes />} />
      </Route>
      {/* Root */}
      <Route element={<PrivateRoute allowedRoles={["Root"]} />}>
        <Route path="/create-user" element={<CreateUser />} />
        <Route path="/edit-user/:id" element={<EditUser />} />
        <Route path="/crud" element={<Cruds />} />
      </Route>
      {/* Todos */}
      <Route element={<PrivateRoute />}>
        <Route path="/test/:folio_id" element={<TestPage />} />
      </Route>
    </Routes>
  );
};

export default App;
