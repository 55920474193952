import React, { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import logo from "../../Assets/img/logo.png";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"; // Asegúrate de tener estos íconos importados
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para controlar la visibilidad del menú
  const { authState, setAuthState } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token"); // Remove the token from localStorage
    setAuthState({ authToken: null, user: null, isLoading: false }); // Set the authState to null
    navigate("/login");
    setIsMenuOpen(false); // Cierra el menú al hacer logout
  };

  return (
    <nav className="flex justify-between items-center fixed z-10 top-0 w-full py-5 px-20 text-sm font-light bg-white shadow-sm h-24">
      <ul className="flex items-center gap-3">
        <li className="">
          <NavLink to={"/"}>
            <img src={logo} alt="Logotipo Gobierno" className="h-16" />
          </NavLink>
        </li>

        <li className="md:hidden">
          <FontAwesomeIcon
            icon={isMenuOpen ? faTimes : faBars}
            className="h-8 cursor-pointer"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
        </li>
      </ul>

      {/* Menu para dispositivos móviles */}
      <div
        className={`absolute top-24 left-0 w-full bg-white shadow-md py-5 px-8 ${
          isMenuOpen ? "flex" : "hidden"
        } flex-col items-start gap-3 md:hidden`}
      >
        <NavLink to="/" onClick={() => setIsMenuOpen(false)}>
          Inicio
        </NavLink>
        {authState.authToken && <button onClick={handleLogout}>Logout</button>}
      </div>

      <ul
        className={`hidden md:flex items-center gap-3 font-primary font-bold text-[#3A3B3C] ${
          isMenuOpen && "hidden"
        }`}
      >
        <li>
          <NavLink to="/">Inicio</NavLink>
        </li>

        {authState.authToken && (
          <li>
            <button onClick={handleLogout}>Cerrar Sesión</button>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;