import Layout from "../../../Components/Layout";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";

import TableData from "../../../Components/HomePageUser/Components/TableData";

const PaymentsList = () => {
  const [payments, setPayments] = useState([]);
  const { authState } = useContext(AuthContext); // Obtener el estado de autenticación
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await fetch(`${apiUrl}/pagos/pending`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authState.authToken}`, // Pasar el token de autorización
          },
        });

        if (!response.ok) {
          throw new Error("Error fetching payments");
        }

        const data = await response.json();
        setPayments(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchPayments();
  }, [apiUrl, authState.authToken]);

  const normalizeData = (data) => {
    return data.map((payment) => ({
      id: payment.id,
      numero_folio_talon: payment.numero_folio_talon,
      numero_folios_pagados: payment.numero_folios_pagados || "N/A",
      representante_legal: payment.acopiador.acopio.representante_legal,
    }));
  };

  const indexes = {
    id: "ID",
    numero_folio_talon: "Número de Folio Talón",
    numero_folios_pagados: "Folios Pagados",
    representante_legal: "Nombre del Acopiador",
  };

  const actions = (payment) => (
    <button
      onClick={() => navigate(`/verify-payments/${payment.id}`)}
      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
    >
      Verificar
    </button>
  );

  return (
    <Layout>
      <div className="mt-4">
        <h1 className="text-xl font-titles">Pagos a verificar</h1>
        <div className="overflow-x-auto">
          <TableData
            data={normalizeData(payments)}
            indexes={indexes}
            actions={actions}
          />
        </div>
      </div>
    </Layout>
  );
};

export default PaymentsList;
