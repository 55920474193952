import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../../Components/Layout";
import PDFViewer from "../../../Components/PDFViewer";
import TextInput from "../../../Components/Custom/Forms/TextInput";
import Select from "../../../Components/Custom/Forms/Select";
import DatePickerInput from "../../../Components/Custom/Forms/DatePickerInput";
import { AuthContext } from "../../../Context/AuthContext";
import { toast } from "react-toastify";
import Modal from "../../../Components/Modal";

const PaymentsVerify = () => {
  const { id } = useParams();
  const { authState } = useContext(AuthContext);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);
  const [comments, setComments] = useState("");

  const [paymentData, setPaymentData] = useState({
    numero_folio_talon: "",
    numero_folios_pagados: "",
    fecha_pago: "",
    monto_pago: "",
    tipo: "",
    referencia: "",
    acopiador: {
      acopio: {
        representante_legal: "",
      },
    },
  });

  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        const response = await fetch(`${apiUrl}/pagos/pending/${id}`, {
          method: "GET",
          headers: {
            Authorization: `${authState.authToken}`,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          toast.error(`Error: ${errorData.message}`);
          throw new Error("Error fetching payment data");
        }

        const data = await response.json();

        // Convertir la fecha a un objeto Date si es necesario
        if (data.fecha_pago) {
          data.fecha_pago = new Date(data.fecha_pago);
        }

        setPaymentData({
          ...data,
          vet_id: authState.user.usuario_id, // Añadir vet_id al paymentData
        });
      } catch (error) {
        toast.error(`Error: ${error}`);
        console.error("Error:", error);
      }
    };

    if (authState.user) {
      fetchPaymentData();
    }
  }, [apiUrl, authState.authToken, authState.user, id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPaymentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/pagos/pending/${id}/verify`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authState.authToken}`,
        },
        body: JSON.stringify(paymentData),
      });

      if (!response.ok) {
        // Capturar el error del backend como Json para poderlo usar
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      const data = await response.json();
      toast.success(data.message);
      navigate("/payments");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleModalSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/pagos/pending/${id}/reject`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authState.authToken}`,
        },
        body: JSON.stringify({ motivo_rechazo: comments }),
      });

      if (!response.ok) {
        // Capturar el error del backend como Json para poderlo usar
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      const data = await response.json();
      toast.success(data.message);
      navigate("/payments");
    } catch (error) {
      toast.error(error.message);
    }
  };

  if (!paymentData) {
    return (
      <Layout>
        <div>Loading...</div>
      </Layout>
    );
  }

  const paymentTypes = [
    { value: "", label: "Selecciona un tipo" },
    { value: "Camion", label: "Camión" },
    { value: "Trailer", label: "Tráiler" },
  ];

  return (
    <Layout>
      <div className="w-full flex flex-col md:flex-row h-[calc(100vh-96px)]">
        <div className="w-full md:w-1/2 h-full">
          <PDFViewer
            pdfUrl={`${apiUrl}/files/pago_movilizacion/${id}`}
            authState={authState}
            initialOrientation="vertical"
            containerStyle={{ height: "100%" }}
          />
        </div>
        <div className="w-full md:w-1/2 p-4 relative">
          <h1 className="text-xl font-titles mb-4">
            Verificando Pago de Movilización {id}
          </h1>
          <form onSubmit={handleSubmit} className="space-y-4">
            <TextInput
              id="numero_folio_talon"
              name="numero_folio_talon"
              label="Número de Folio Talón"
              placeholder="Número de Formato de Pago"
              value={paymentData.numero_folio_talon}
              onChange={handleInputChange}
              maxLength={19}
              className="w-full"
              required
            />
            <TextInput
              id="numero_folios_pagados"
              name="numero_folios_pagados"
              label="Número de Folios Pagados"
              placeholder="Número de Folios Pagados"
              value={paymentData.numero_folios_pagados}
              onChange={handleInputChange}
              className="w-full"
              required
            />
            <DatePickerInput
              id="fecha_pago"
              name="fecha_pago"
              label="Fecha de Pago"
              selected={paymentData.fecha_pago}
              onChange={handleInputChange}
              className="w-full"
              required
            />
            <TextInput
              id="monto_pago"
              name="monto_pago"
              label="Monto de Pago"
              placeholder="Monto de Pago"
              value={paymentData.monto_pago || ""}
              onChange={handleInputChange}
              className="w-full"
              required
            />
            <Select
              id="tipo"
              name="tipo"
              label="Tipo de Pago"
              value={paymentData.tipo || ""}
              onChange={handleInputChange}
              options={paymentTypes}
              className="w-full"
              required
            />
            <TextInput
              id="referencia"
              name="referencia"
              label="Referencia"
              placeholder="Referencia del Pago"
              value={paymentData.referencia || ""}
              onChange={handleInputChange}
              maxLength={40}
              className="w-full"
              required
            />
            <TextInput
              id="representante_legal"
              name="representante_legal"
              label="Nombre del Representante"
              placeholder="Nombre del Representante"
              value={paymentData.acopiador.acopio.representante_legal}
              readOnly
              className="w-full"
              required
            />
            <div className="absolute bottom-4 left-0 w-full flex px-4 justify-between">
              <div>
              <button
                type="submit"
                className="bg-blue-700 text-white font-medium py-2 px-4 rounded hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Guardar Cambios
              </button>
              </div>
              <div>
              <button
                type="button"
                className="bg-red-700 text-white font-medium py-2 px-4 rounded hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-red-500"
                onClick={handleOpenModal}
              >
                Rechazar
              </button>
              </div>
            </div>
          </form>
        </div>
        <Modal show={modalVisible} onClose={handleCloseModal}>
          <div className="px-4">
            <h1 className="font-primary">
              ¿Está seguro de proceder con el rechazo de este pago?
            </h1>
            <div className="py-4">
              <p>Esta acción no se puede deshacer.</p>
            </div>
            <div className="p">
              <form onSubmit={handleModalSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="comments"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Comentarios
                  </label>
                  <textarea
                    id="comments"
                    className="rounded-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full text-sm p-2.5"
                    placeholder="Ingrese sus comentarios"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-fit-content"
                >
                  Rechazar
                </button>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </Layout>
  );
};

export default PaymentsVerify;
