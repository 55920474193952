import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../Components/Layout";
import DropZone from "../../Components/FolioCreator/DropZone";
import Select from "../../Components/Custom/Forms/Select";
import FacturaSection from "../../Components/FolioCreator/FacturaUpload";
import { useAuth } from "../../Context/AuthContext";

import "./styles.css";
import { set } from "date-fns";
import Button from "../../Components/Custom/Button";
import CryptoJS from "crypto-js";

const DocumentsForm = () => {
  const { authState } = useAuth();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
  const [dictamenFile, setDictamenFile] = useState(null);
  const [motivo, setMotivo] = useState("");
  const [motivos, setMotivos] = useState([]);
  const [especie, setEspecie] = useState("");
  const [especies, setEspecies] = useState([]);
  const [destino, setDestino] = useState("");
  const [destinos, setDestinos] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [downloadedFile, setDownloadedFile] = useState(false);
  const [progress, setProgress] = useState(0);
  const fileInputRef = useRef(null);
  const [fileDictamenUploaded, setFileDictamenUploaded] = useState(false);
  const [fileDictamenName, setFileDictamenName] = useState("");
  const [vCopiaSolicitud, setVCopiaSolicitud] = useState("");

  const versionCopia = [
    { value: 2, label: "Versión 2" },
    { value: 1, label: "Versión 1" },
  ];

  const [wichFileFacturaLoaded, setWasFileFacturaLoaded] = useState([
    { loaded: false },
  ]);
  const [wichFileReemoLoaded, setWasFileReemoLoaded] = useState([
    { loaded: false },
  ]);

  const [documentGroups, setDocumentGroups] = useState([
    {
      factura: null,
      facturaNumber: "",
      reemo: null,
      reemoNumber: "",
      aretes: [{ numero_arete: "", sexo: "M" }],
    },
  ]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!downloadedFile) {
        const message =
          "Hay cambios sin aplicar, si sales perderás los datos ¿estás seguro de que quieres salir?";
        //event.preventDefault();
        event.returnValue = message; // Esto es necesario para algunos navegadores como Chrome
        return message; // Para navegadores más antiguos
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [downloadedFile]);

  useEffect(() => {
    //checar si se presionó f5 y no se ha descargado un archivo
    const fetchDestinos = async () => {
      try {
        const res = await fetch(`${apiUrl}/destinos/get`);
        const data = await res.json();
        const destinos_option = data.map((destino) => ({
          value: destino.id,
          label: destino.nombre,
          extra: destino.localidad,
        }));
        const defaultValue = { value: "", label: "Selecciona un destino" };
        destinos_option.unshift(defaultValue);

        setDestinos(destinos_option);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDestinos();
  }, []);

  useEffect(() => {
    const fetchMotivos = async () => {
      try {
        const res = await fetch(`${apiUrl}/crud/motivos`, {
          method: "GET",
          headers: {
            Authorization: `${authState.authToken}`,
          },
        });
        const data = await res.json();
        const motivos_option = data.map((motivo) => ({
          value: motivo.motivo,
          label: motivo.motivo,
        }));
        const defaultValue = { value: "", label: "Selecciona un motivo" };
        motivos_option.unshift(defaultValue);

        setMotivos(motivos_option);
      } catch (error) {
        console.error(error);
      }
    };
    fetchMotivos();
  }, [authState.authToken]);

  /*traer el catalogo de especies*/
  useEffect(() => {
    const fetchEspecies = async () => {
      try {
        const res = await fetch(`${apiUrl}/crud/especies`, {
          method: "GET",
          headers: {
            Authorization: `${authState.authToken}`,
          },
        });
        const data = await res.json();
        const especies_option = data.map((especie) => ({
          value: especie.especie,
          label: especie.especie,
        }));
        const defaultValue = { value: "", label: "Selecciona una especie" };
        especies_option.unshift(defaultValue);

        setEspecies(especies_option);
      } catch (error) {
        console.error(error);
      }
    };
    fetchEspecies();
  }, [authState.authToken]);

  const handleDictamenChange = (e) => {
    setDictamenFile(e.target.files[0]);
  };

  const handleMotivoChange = (e) => {
    setMotivo(e.target.value);
  };

  const handleEspecieChange = (e) => {
    setEspecie(e.target.value);
  };

  const handleDestinoChange = (e) => {
    setDestino(e.target.value);
  };

  const handleAddGroup = () => {
    setDocumentGroups([
      ...documentGroups,
      {
        factura: null,
        facturaNumber: "",
        reemo: null,
        reemoNumber: "",
        aretes: [{ numero_arete: "", sexo: "M" }],
      },
    ]);
  };

  const handleRemoveGroup = (index) => {
    const newGroups = documentGroups.filter((group, idx) => idx !== index);
    setDocumentGroups(newGroups);
  };

  const handleFileChange = (index, key, file) => {
    const newGroups = [...documentGroups];
    newGroups[index][key] = file;
    setDocumentGroups(newGroups);
  };

  const handleNumberChange = (index, key, number) => {
    const newGroups = [...documentGroups];
    newGroups[index][key] = number;
    setDocumentGroups(newGroups);
  };

  const handleAreteChange = (groupIndex, areteIndex, key, value) => {
    const newGroups = [...documentGroups];
    newGroups[groupIndex].aretes[areteIndex][key] = value;
    setDocumentGroups(newGroups);
  };

  const handleAddArete = (groupIndex) => {
    const newGroups = [...documentGroups];
    newGroups[groupIndex].aretes.push({ numero_arete: "", sexo: "M" });
    setDocumentGroups(newGroups);
  };

  const handleRemoveArete = (groupIndex, areteIndex) => {
    const newGroups = [...documentGroups];
    newGroups[groupIndex].aretes.splice(areteIndex, 1);
    setDocumentGroups(newGroups);
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleDownload = async () => {
    try {
      toast.info("Generando copia, espere un momento...");

      const documentGroupsBase64 = await Promise.all(
        documentGroups.map(async (group) => ({
          facturaFile: group.factura
            ? await convertFileToBase64(group.factura)
            : null,
          reemoFile: group.reemo
            ? await convertFileToBase64(group.reemo)
            : null,
          facturaNumber: group.facturaNumber,
          reemoNumber: group.reemoNumber,
          aretes: group.aretes,
        }))
      );

      const formDataObject = {
        motivo_de_movilizacion: motivo,
        destino_id: destino,
        especie: especie,
        dictamenFile: dictamenFile
          ? await convertFileToBase64(dictamenFile)
          : null,
        documentGroups: documentGroupsBase64,
      };

      const jsonBlob = new Blob([JSON.stringify(formDataObject)], {
        type: "application/json",
      });
      const url = URL.createObjectURL(jsonBlob);

      const link = document.createElement("a");
      link.href = url;
      const date = new Date().toISOString().split("T")[0];
      link.download = `solicitud_${date}.smpbk`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
      setDownloadedFile(true);
    } catch (error) {
      console.error(error);
      toast.error("Error al generar la copia de solicitud: " + error);
    }
  };

  const handleFileLoad = (event) => {
    const file = event.target.files[0];

    if (!file) {
      toast.error("No se ha seleccionado ningún archivo.");
      return;
    }

    toast.info("Cargando archivo espere un momento...");

    let facturasLoaded = [];
    let reemosLoaded = [];

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        let data = null;

        if (vCopiaSolicitud === "1") {
          const encryptedData = e.target.result;

          const bytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
          data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } else {
          data = JSON.parse(e.target.result);
        }
        //convertir a json

        // Setear los datos cargados en los campos del formulario
        if (data.motivo_de_movilizacion) setMotivo(data.motivo_de_movilizacion);
        if (data.destino_id) setDestino(data.destino_id);
        if (data.especie) setEspecie(data.especie);

        if (data.dictamenFile) {
          const dictamenBlob = dataURLToBlob(data.dictamenFile); // Convertir base64 a Blob
          const dictamenFile = new File([dictamenBlob], "dictamen.pdf", {
            type: "application/pdf",
          });
          setDictamenFile(dictamenFile);
          setFileDictamenUploaded(true);
          setFileDictamenName(dictamenFile.name);
        }

        if (data.documentGroups) {
          setDocumentGroups(
            data.documentGroups.map((group) => ({
              factura: group.facturaFile
                ? new File([dataURLToBlob(group.facturaFile)], "factura.pdf", {
                    type: "application/pdf",
                  })
                : null,
              reemo: group.reemoFile
                ? new File([dataURLToBlob(group.reemoFile)], "reemo.pdf", {
                    type: "application/pdf",
                  })
                : null,
              facturaNumber: group.facturaNumber,
              reemoNumber: group.reemoNumber,
              aretes: group.aretes,
            }))
          );
        }

        for (let i = 0; i < data.documentGroups.length; i++) {
          if (data.documentGroups[i].facturaFile) {
            facturasLoaded.push({ loaded: true });
          } else {
            facturasLoaded.push({ loaded: false });
          }
          if (data.documentGroups[i].reemoFile) {
            reemosLoaded.push({ loaded: true });
          } else {
            reemosLoaded.push({ loaded: false });
          }
        }

        setWasFileFacturaLoaded(facturasLoaded);
        setWasFileReemoLoaded(reemosLoaded);

        toast.success("Archivo cargado correctamente.");
      } catch (error) {
        console.error("Error parsing JSON file:", error);
        toast.error(
          "Error al cargar el archivo. Asegúrate de que sea un archivo válido o seleccione la versión correcta."
        );
      }
    };

    reader.readAsText(file); // Leer el archivo como texto
  };

  // Función para convertir base64 a Blob
  const dataURLToBlob = (dataURL) => {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    setDownloadedFile(false);

    let machos = 0;
    let hembras = 0;

    const formData = new FormData();
    formData.append("usuario_id", authState.user.usuario_id);
    if (dictamenFile) {
      formData.append("dictamen", dictamenFile);
    } else {
      formData.append("dictamen", null);
    }
    if (motivo) {
      formData.append("motivo_de_movilizacion", motivo);
    } else {
      setIsUploading(false);
      toast.error("Debes seleccionar un motivo de movilización");
      return null;
    }
    if (!dictamenFile && motivo !== "SACRIFICIO") {
      setIsUploading(false);
      toast.error("Debes subir un dictamen");
      return null;
    }
    if (destino) {
      formData.append("destino_id", destino);
    } else {
      setIsUploading(false);
      toast.error("Debes seleccionar un destino");
      return null;
    }
    if (especie) {
      formData.append("especie", especie);
    } else {
      setIsUploading(false);
      toast.error("Debes seleccionar una especie");
      return null;
    }

    let faltaArete = false;
    let faltaNumeroFactura = false;
    let faltaNumeroReemo = false;
    let faltaDocumento = false;

    //verificar que los aretes no se repitan en diferentes grupos o el mismo

    const aretes = documentGroups.map((group) => group.aretes).flat();
    const aretesSet = new Set();
    for (const arete of aretes) {
      if (aretesSet.has(arete.numero_arete)) {
        toast.error(
          `No puedes subir el mismo arete en diferentes o el mismo grupo. El arete ${arete.numero_arete} está duplicado.`
        );
        setIsUploading(false);
        return null;
      }
      aretesSet.add(arete.numero_arete);
      if (arete.sexo === "M") {
        machos++;
      } else {
        hembras++;
      }

      if (!arete.numero_arete) {
        faltaArete = true;
      }
    }

    if (machos === 0 && hembras === 0) {
      setIsUploading(false);
      toast.error("Debes subir al menos un arete");
      return null;
    }

    if (faltaArete) {
      setIsUploading(false);
      toast.error("No puedes subir aretes vacíos");
      return null;
    }
    documentGroups.forEach((group, index) => {
      //verificar que al grupo no le falte ningun archivo

      if (!group.facturaNumber || group.facturaNumber === "") {
        faltaNumeroFactura = true;
      }

      if (!group.factura) {
        setIsUploading(false);
        toast.error("Falta subir una factura");
        faltaDocumento = true;
        return;
      }
      if (!group.reemo) {
        setIsUploading(false);
        toast.error("Falta subir un reemo");
        faltaDocumento = true;
        return;
      }
      if (!group.reemoNumber || group.reemoNumber === "") {
        faltaNumeroReemo = true;
      }
      if (group.factura) formData.append("facturas", group.factura);
      if (group.reemo) formData.append("reemos", group.reemo);
      formData.append(`factura_numbers[${index}]`, group.facturaNumber);
      formData.append(`reemo_numbers[${index}]`, group.reemoNumber);
      formData.append(`aretes[${index}]`, JSON.stringify(group.aretes));
    });

    if (faltaNumeroFactura) {
      setIsUploading(false);
      toast.error("Debes ingresar un número de factura");
      return null;
    }

    if (faltaNumeroReemo) {
      setIsUploading(false);
      toast.error("Debes ingresar un número de reemo");
      return null;
    }

    if (faltaDocumento) {
      console.log("error en los documentos");
      setIsUploading(false);
      return null;
    }

    const result = window.confirm(
      `¿Estás seguro de que deseas enviar la solicitud?\nResumen:\n- Machos: ${machos}\n- Hembras: ${hembras}`
    );

    if (!result) {
      setIsUploading(false);
      return;
    }

    try {
      toast.info("Subiendo documentos espere un momento...");
      setIsUploading(true);

      const res = await fetch(`${apiUrl}/upload/documents`, {
        method: "POST",
        headers: {
          Authorization: `${authState.authToken}`,
        },
        body: formData,
      });

      if (!res.ok) {
        throw new Error("Error al subir los documentos");
      }

      const data = await res.json();
      console.log(data);
      setIsUploading(false);
      toast.success("Documentos subidos correctamente");
      navigate("/inicio");
    } catch (error) {
      console.error(error);
      setIsUploading(false);
      toast.error("Error al subir los documentos");
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Layout>
      <div className="mb-5 px-24 py-4 glassmorphism justify-end">
        {/* Botón estilizado que abre el cuadro de diálogo de archivos */}
        <div className="flex flex-row justify-end items-center">
          <div className="flex items-center border-2 rounded border-black glassmorphism">
            <input
              type="file"
              accept=".smpbk"
              onChange={handleFileLoad}
              ref={fileInputRef}
              style={{ display: "none" }} // Oculta el input nativo
            />
            <Button
              onClick={handleButtonClick}
              className="bg-orange-500 hover:bg-orange-700 font-bold m-2"
            >
              Cargar Copia de Solicitud
            </Button>
            <select
              className="m-2 h-fit"
              onChange={(e) => setVCopiaSolicitud(e.target.value)}
            >
              {versionCopia.map((version, index) => (
                <option key={index} value={version.value}>
                  {version.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <form className="w-full mx-auto" onSubmit={handleSubmit}>
          <DropZone
            onDrop={handleDictamenChange}
            label={"archivo dictamen"}
            fileUploaded={fileDictamenUploaded}
            setFileUploaded={setFileDictamenUploaded}
            fileName={fileDictamenName}
            setFileName={setFileDictamenName}
          />
          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              htmlFor="motivo"
            >
              Motivo de Movilización
            </label>
            <Select
              required
              id="motivo"
              options={motivos}
              className="w-full font-primary"
              value={motivo}
              onChange={handleMotivoChange}
              sorted
            />
          </div>
          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              htmlFor="destino"
            >
              Destino
            </label>
            <Select
              required
              id="destino"
              options={destinos}
              className="w-full font-primary"
              value={destino}
              onChange={handleDestinoChange}
              sorted
            />
          </div>
          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              htmlFor="especie"
            >
              Especie
            </label>
            <Select
              required
              id="especie"
              options={especies}
              className="w-full font-primary"
              value={especie}
              onChange={handleEspecieChange}
              sorted
            />
          </div>
          {documentGroups.map((group, index) => (
            <div key={index} className="mb-4 p-4 glassmorphism">
              <FacturaSection
                group={group}
                index={index}
                handleFileChange={handleFileChange}
                handleNumberChange={handleNumberChange}
                handleAreteChange={handleAreteChange}
                handleAddArete={handleAddArete}
                handleRemoveArete={handleRemoveArete}
                handleRemoveGroup={handleRemoveGroup}
                wichFileFacturaLoaded={wichFileFacturaLoaded}
                wichFileReemoLoaded={wichFileReemoLoaded}
              />
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddGroup}
            className="bg-lime-500 hover:bg-lime-700 text-white font-bold py-2 px-4 rounded mb-4"
          >
            Agregar otro grupo
          </button>

          <div className="flex gap-4 mb-4 ">
            <div className="flex-1 justify-start">
              <Button
                type="button"
                onClick={handleDownload}
                className="w-1/2 font-bold "
              >
                Guardar Copia de Solicitud
              </Button>
            </div>
            <div className="flex-2 justify-end">
              <Button
                onClick={handleSubmit}
                className="w-1/3 bg-green-500 hover:bg-green-700 font-bold rounded"
                disabled={isUploading}
              >
                Enviar Solicitud
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default DocumentsForm;
