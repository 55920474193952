import React from "react";
import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
import BottomDrawerMenu from "../Components/BottomDrawerMenu";
import TableData from "../Components/TableData";
import Modal from "./Components/Modal";
import MotivosRechazo from "./Components/MotivosRechazo";
import ActionBar from "../Components/ActionBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faExclamationCircle,
  faEdit,
  faFileCirclePlus,
  faFileInvoiceDollar,
  faMoneyCheckDollar,
  faMagnifyingGlass,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";

import "./styles.css";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { set } from "date-fns";

function Acopiador() {
  const [data, setData] = useState([]);
  const [filtroEstado, setFiltroEstado] = useState("");
  const [terminoBusqueda, setTerminoBusqueda] = useState("");
  const [motivosRechazo, setMotivosRechazo] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [usuarioData, setUsuarioData] = useState({});

  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = new URL(`${apiUrl}/folios`);
        let params = { busqueda: terminoBusqueda };
        if (typeof filtroEstado === "object" && filtroEstado !== null) {
          params = { ...params, ...filtroEstado };
        } else if (filtroEstado) {
          params.estado = filtroEstado;
        }

        Object.keys(params).forEach((key) =>
          url.searchParams.append(key, params[key])
        );

        const res = await fetch(url, {
          headers: {
            Authorization: `${authState.authToken}`,
          },
        });

        if (!res.ok) {
          throw new Error("Response not ok from API");
        }

        const json = await res.json();
        setData(json);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [authState.authToken, filtroEstado, terminoBusqueda]);

  const handleOpenModal = async (folio_id) => {
    try {
      const res = await fetch(
        `${apiUrl}/folios/${folio_id}/rejection-reasons`,
        {
          headers: {
            Authorization: `${authState.authToken}`,
          },
        }
      );

      if (!res.ok) {
        throw new Error("Response not ok from API");
      }

      // Obtener las facturas con el folio id
      const facturasRes = await fetch(
        `${apiUrl}/facturas/aretes/rejected/${folio_id}`,
        {
          headers: {
            Authorization: `${authState.authToken}`,
          },
        }
      );

      const resJson = await res.json();
      //const aretesRech = await facturasRes.json();

      const motivosRechazo = await resJson.rejectionReasons;

      // Filtrar los aretes rechazados y agregar el comentario de aretesRech si existe
      /*const filteredAretes = aretesRech.map((arete) => {
        const areteRechazado = motivosRechazo.aretesRechazados.find((areteRechazado) => areteRechazado.numero_arete === arete.numero_arete);
        if (areteRechazado) {
          const motivo_rechazo = (arete.in_dictamen==='f'||arete.in_dictamen===false) ? "El arete no se encuentra el dictamen."  : "El número o datos del arete no coincide con la factura.";
          const comentario = arete.comentario

          return {
            ...areteRechazado,
            motivo_rechazo: motivo_rechazo,
            comentario: comentario,
            numero_de_factura: arete.factura.numero_de_factura,
          };
        }
        return null;
      });
*/
      //console.log(filteredAretes);

      //motivosRechazo.aretesRechazados = filteredAretes;
      console.log(motivosRechazo);

      setMotivosRechazo(motivosRechazo);

      setModalVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/acopiador/${authState.user.usuario_id}`,
          {
            headers: {
              Authorization: `${authState.authToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Response not ok from API");
        }

        const json = await response.json();
        setUsuarioData(json.acopiador);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [authState.authToken, authState.user.usuario_id]);

  const indexes = {
    folio_id: "Folio",
    motivo_de_movilizacion: "Motivo",
    nombre_origen: "Rancho",
    poblacion_origen: "Población",
    nombre_destino: "Destino",
    estado: "Estado",
  };

  const columnWidths = ["10%", "10%", "25%", "10%", "25%", "10%", "10%"];

  const actionsMap = {
    Rechazado: {
      0: () => (
        <div className="flex space-x-2 font-sec text-sm text-center">
          <p>En revisión</p>
        </div>
      ),
      1: (folio_id) => (
        <div className="flex space-x-2">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="mr-2 h-5 cursor-pointer text-red-600 hover:text-red-700"
            onClick={() => handleOpenModal(folio_id)}
            title="Motivos de Rechazo"
          />
          <FontAwesomeIcon
            icon={faEdit}
            className="mr-2 h-5 cursor-pointer"
            onClick={() => navigate(`/solve-folio/${folio_id}`)}
            title="Editar Folio"
          />
        </div>
      ),
      default: (folio_id) => (
        <div className="flex space-x-2">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="mr-2 h-5 cursor-pointer text-red-600 hover:text-red-700"
            onClick={() => handleOpenModal(folio_id)}
            title="Motivos de Rechazo"
          />
        </div>
      ),
    },
    "En Progreso": {
      0: (folio_id) => (
        <div className="flex space-x-2 font-sec text-sm text-center">
          <p>En revisión</p>
        </div>
      ),
      1: (folio_id) => (
        <div className="flex space-x-2 font-sec text-sm text-center">
          <p>En revisión</p>
        </div>
      ),
      2: (folio_id) => (
        <div className="flex space-x-2 font-sec text-sm text-center">
          <Link to={`/asign-payment/${folio_id}`}>
            <FontAwesomeIcon
              icon={faFileInvoiceDollar}
              className="mr-2 h-5 cursor-pointer"
              title="Subir Comprobante de Pago"
            />
          </Link>
        </div>
      ),
      3: () => (
        <div className="flex space-x-2 font-sec text-sm text-center">
          <p>En revisión de pago</p>
        </div>
      ),
    },
    Aprobado: {
      6: (folio_id, item) => (
        <div
          className="flex space-x-2 font-sec text-sm text-center cursor-pointer"
          onClick={() =>
            navigate(`/folio-viewer/${item.folio_aprobado?.numero_folio_final}`)
          }
        >
          Ver Folio
        </div>
      ),
    },
    Cancelado: {
      10: (folio_id) => (
        <div className="flex space-x-2">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="mr-2 h-5 cursor-pointer text-red-600 hover:text-red-700"
            onClick={() => handleOpenModal(folio_id)}
            title="Motivos de Rechazo"
          />
        </div>
      ),
    },
  };

  const actions = (item) => {
    if (
      !actionsMap[item.estado] ||
      !actionsMap[item.estado][parseInt(item.step)]
    ) {
      return <div>-</div>;
    }
    const action = actionsMap[item.estado][parseInt(item.step)];
    return action ? action(item.folio_id, item) : <div>-</div>;
  };

  // Paginacion
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to the first page when changing the number of items per page
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setTerminoBusqueda(e.target.value);
  };

  const fechaAutorizacion = new Date(usuarioData.fecha_oficio_autorizacion);
  const fechaVencimiento = new Date(fechaAutorizacion);
  fechaVencimiento.setFullYear(fechaVencimiento.getFullYear() + 1);
  const fechaActual = new Date();

  const diferenciaTiempo = fechaVencimiento - fechaActual;
  const diferenciaDias = Math.ceil(diferenciaTiempo / (1000 * 60 * 60 * 24));

  const isVencido = diferenciaDias <= 0;
  const isProximoVencimiento = diferenciaDias > 0 && diferenciaDias <= 14;

  const actionButtons = [
    isVencido
      ? {
          id: "create-folio",
          label: "PSG VENCIDO",
          linkTo: "",
          icon: faExclamationCircle,
          title: "PSG VENCIDO",
        }
      : {
          id: "create-folio",
          label: "Crear Folio",
          linkTo: "/new",
          icon: faFileCirclePlus,
          title: "Nuevo Folio",
        },
    {
      id: "upload-payment",
      label: "Subir Pago",
      linkTo: "/upload-payment",
      icon: faReceipt,
      title: "Subir Pagos",
    },
    {
      id: "cobros-abiertos",
      label: "Ventanilla Unica",
      icon: faFileInvoiceDollar,
      title: "Ventanilla Unica",
      linkTo:
        "https://hacienda.minayarit.gob.mx/pagos/pago-de-derechos-03/servicios-prestados-por-la-secretaria-de-desarrollo-rural-y-medio-ambiente-05",
    },
  ];

  return (
    <div className="w-full md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl leading-tight font-titles">
          {usuarioData?.representante_legal}
        </h1>
        <div>
          <p>
            <span className="font-bold">PSG: </span>
            {usuarioData?.psg}
          </p>
          {isVencido && (
            <p className="text-red-600 font-bold">Tu PSG ha vencido</p>
          )}
          {isProximoVencimiento && (
            <p className="text-orange-600 font-bold">
              Tu PSG está por vencer en {diferenciaDias} días
            </p>
          )}
        </div>
      </div>
      <div className="text-2xl mb-4 lg:flex lg:justify-between px-2 items-center">
        <div className="text-sm font-medium text-center w-full text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul className="lg:flex lg:flex-wrap -mb-px lg:items-center lg:justify-between">
            <li>
              <ActionBar
                itemsPerPage={itemsPerPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                searchTerm={terminoBusqueda}
                handleSearchChange={handleSearchChange}
                actions={actionButtons}
              />
            </li>
            <li className="">
              <BottomDrawerMenu
                setFiltroEstado={setFiltroEstado}
                filtroEstado={filtroEstado}
                opcionesExtraFiltro={[
                  {
                    valor: { estado: "En Progreso", step: "2" },
                    etiqueta: "Falta de Pago",
                  },
                ]}
              />
            </li>
          </ul>
        </div>
      </div>
      <TableData
        data={data}
        indexes={indexes}
        actions={actions}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        columnWidths={columnWidths}
      />
      {modalVisible && (
        <div className="flex h-1/2">
        <Modal show={modalVisible} onClose={handleCloseModal}>
          <MotivosRechazo motivos={motivosRechazo} />
        </Modal>
        </div>
      )}
    </div>
  );
}

export default Acopiador;
