import Layout from "../../Components/Layout";
import { useAuth } from "../../Context/AuthContext";
import React, { useState } from "react";
import PDFViewer from "../../Components/PDFViewer";
import { useParams } from "react-router-dom";

const TestPage = () => {
  const { authState } = useAuth();
  const { folio_id } = useParams();
  const pdfUrl = `http://44.217.141.141:3002/folios/${folio_id}.pdf`;

  return (
    <Layout>
      <PDFViewer pdfUrl={pdfUrl} authState={authState} />
    </Layout>
  );
};

export default TestPage;
