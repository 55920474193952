import React from "react";
import Layout from "../../Components/Layout";
import SolveFolio from "./Components/SolveFolio";
import "./styles.css";

const ReUploadDocuments = () => {
  return (
    <Layout>
      <SolveFolio />
    </Layout>
  );
};

export default ReUploadDocuments;
