import React from "react";
import { QRCodeCanvas } from "qrcode.react";

const QRCodeImg = ({ folio }) => {
  const url = process.env.REACT_APP_URL_FOLIO_FILES;

  if (!folio) {
    return <div className="w-[65px] h-[65px] border border-gray-300"></div>;
  }

  const qrUrl = `${url}${folio}`;

  return <QRCodeCanvas value={qrUrl} size={65} className="qrcode" />;
};

export default QRCodeImg;
