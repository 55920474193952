import React, { useEffect, useState, useContext } from "react";
import Layout from "../../../Components/Layout";
import { AuthContext } from "../../../Context/AuthContext";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import EditarAcopiador from "./EditarAcopiador"; // Import the new component
import TextInput from "../../../Components/Custom/Forms/TextInput";
import { jwtDecode } from "jwt-decode";

const EditUser = () => {
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { authState, setAuthState } = useContext(AuthContext);
  const navigate = useNavigate();

  const [data, setData] = useState({
    username: "",
    password: "",
    nombre: "",
    apellido: "",
    correo: "",
    rol: "",
  });
  const [acopiador, setAcopiador] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const setAuthToken = (token) => {
    localStorage.setItem("token", token);
    const decodedToken = jwtDecode(token);
    setAuthState({ authToken: token, user: decodedToken });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!authState.authToken || !id) {
        return;
      }

      try {
        setLoading(true);
        const res = await fetch(`${apiUrl}/auth/${id}`, {
          headers: {
            Authorization: `${authState.authToken}`,
          },
        });

        if (!res.ok) {
          throw new Error("Response not ok from API");
        }

        const json = await res.json();
        setData(json);

        // Si el usuario es acopiador, obtener los datos del acopiador
        if (json.rol === "Acopiador") {
          const resAcopiador = await fetch(`${apiUrl}/auth/acopiador/${id}`, {
            headers: {
              Authorization: `${authState.authToken}`,
            },
          });

          if (!resAcopiador.ok) {
            throw new Error("Response not ok from API");
          }

          const acopiadorData = await resAcopiador.json();
          setAcopiador(acopiadorData);
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchData();
  }, [authState.authToken, id]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const resetPassword = async (e) => {
    setDisabled(true);
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/resetPassword/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authState.authToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        toast.success("Contraseña cambiada correctamente");
        navigate("/");
      } else {
        const errorData = await response.json();
        toast.error(errorData.message);
      }
    } catch (error) {
      console.error("Error al cambiar la contraseña:", error);
    }
  };

  const editUser = async (e) => {
    setDisabled(true);
    e.preventDefault();
    try {
      const res = await fetch(`${apiUrl}/auth/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authState.authToken}`,
        },
        body: JSON.stringify({ ...data, acopiador }),
      });

      if (!res.ok) {
        throw new Error("Error updating user");
      }

      toast.success("Usuario actualizado correctamente");
      navigate("/");
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  if (loading) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-full">
          <p>Cargando...</p>
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-full">
          <p>{error}</p>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="flex flex-col w-full px-60 mt-4">
        <h1 className="text-2xl font-titles">Editar Usuario</h1>
        <form onSubmit={editUser} className="flex flex-col">
          <TextInput
            label={"Nombre de Usuario:"}
            id="username"
            value={data.username}
            onChange={handleChange}
            required
            maxLength={20}
            validationPattern={"^[a-zA-Z0-9]*$"}
            className="mr-2 w-full"
            name="username"
          />
          <TextInput
            label={"Nombre:"}
            id="nombre"
            value={data.nombre}
            onChange={handleChange}
            required
            maxLength={40}
            className="mr-2 w-full"
            name="nombre"
          />
          <TextInput
            label={"Apellido:"}
            id="apellido"
            value={data.apellido}
            onChange={handleChange}
            required
            maxLength={40}
            className="mr-2 w-full"
            name="apellido"
          />
          <TextInput
            label={"Correo Electrónico:"}
            id="correo"
            value={data.correo}
            onChange={handleChange}
            required
            maxLength={40}
            className="mr-2 w-full"
            name="correo"
            type="email"
          />
          {data.rol === "Acopiador" && (
            <EditarAcopiador
              acopiador={acopiador}
              setAcopiador={setAcopiador}
            />
          )}
          <button type="submit" disabled={disabled} className="btn-primary mt-4">
            Editar
          </button>
          <button onClick={() => navigate("/")} className="bg-red-500 text-white btn-primary mt-4 ">
            Cancelar
          </button>
        </form>
        <button onClick={resetPassword} disabled={disabled} className="bg-yellow-500 text-white btn-primary mt-4">
          Restablecer contraseña
        </button>
      </div>
    </Layout>
  );
};

export default EditUser;
