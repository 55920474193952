import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const Modal = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center ">
      <div className="bg-white p-5 rounded-lg w-96">
        <FontAwesomeIcon icon={faXmark} onClick={onClose} className="py-2 cursor-pointer" />
        {children}
      </div>
    </div>
  );
};

export default Modal;
