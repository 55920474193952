// CreateAcopiador.js
import React from "react";
import Select from "../../../../../Components/Custom/Forms/Select";
import TextInput from "../../../../../Components/Custom/Forms/TextInput";
import NumberInput from "../../../../../Components/Custom/Forms/NumberInput";
import ImageCropUpload from "../ImageCropUpload";

const CreateAcopiador = ({ userData, setUserData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      acopiador: {
        ...prevData.acopiador,
        [name]: value,
      },
    }));
  };

  const handleClaveCompraVentaChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = formatClaveCompraVenta(value);
    setUserData((prevData) => ({
      ...prevData,
      acopiador: {
        ...prevData.acopiador,
        [name]: formattedValue,
      },
    }));
  };

  const handlePSGChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = formatWithHyphens(value);
    setUserData((prevData) => ({
      ...prevData,
      acopiador: {
        ...prevData.acopiador,
        [name]: formattedValue,
      },
    }));
  };

  const formatWithHyphens = (value) => {
    const cleanedValue = value.replace(/[^a-zA-Z0-9]/g, ''); // Elimina caracteres no alfanuméricos
    const segments = [
      cleanedValue.slice(0, 2),
      cleanedValue.slice(2, 5),
      cleanedValue.slice(5, 9),
      cleanedValue.slice(9, 13)
    ];
    return segments.filter(Boolean).join('-'); // Une los segmentos con guiones
  };

  const formatClaveCompraVenta = (value) => {
    const cleanedValue = value.replace(/[^a-zA-Z0-9]/g, ''); // Elimina caracteres no alfanuméricos
    const segments = [
      cleanedValue.slice(0, 1),
      cleanedValue.slice(1, 3),
      cleanedValue.slice(3, 6),
      cleanedValue.slice(6, 9)
    ];
    return segments.filter(Boolean).join('-'); // Une los segmentos con guiones
  };
  

  const handleImageCrop = (croppedImage) => {
    setUserData((prevData) => ({
      ...prevData,
      acopiador: {
        ...prevData.acopiador,
        image: croppedImage,
      },
    }));
  };

  const registroOptions = [
    { value: "", label: "Seleccionar Registro" },
    { value: "COMPRA-VENTA", label: "COMPRA-VENTA" },
    { value: "REPASTO", label: "REPASTO" },
    { value: "INTRODUCTOR", label: "INTRODUCTOR" },
  ];

  const tipoCredencialOptions = [
    { value: "", label: "Seleccionar Tipo de Credencial" },
    { value: "REFRENDO", label: "REFRENDO" },
    { value: "NUEVO", label: "NUEVO" },
  ];

  const municipioOptions = [
    { value: "", label: "Seleccionar Municipio" },
    { value: "ACAPONETA", label: "ACAPONETA" },
    { value: "AHUACATLÁN", label: "AHUACATLÁN" },
    { value: "AMATLÁN DE CAÑAS", label: "AMATLÁN DE CAÑAS" },
    { value: "COMPOSTELA", label: "COMPOSTELA" },
    { value: "DEL NAYAR", label: "DEL NAYAR" },
    { value: "HUAJICORI", label: "HUAJICORI" },
    { value: "IXTLÁN DEL RÍO", label: "IXTLÁN DEL RÍO" },
    { value: "JALA", label: "JALA" },
    { value: "LA YESCA", label: "LA YESCA" },
    { value: "ROSAMORADA", label: "ROSAMORADA" },
    { value: "RUIZ", label: "RUIZ" },
    { value: "SAN BLAS", label: "SAN BLAS" },
    { value: "SAN PEDRO LAGUNILLAS", label: "SAN PEDRO LAGUNILLAS" },
    { value: "SANTA MARÍA DEL ORO", label: "SANTA MARÍA DEL ORO" },
    { value: "SANTIAGO IXCUINTLA", label: "SANTIAGO IXCUINTLA" },
    { value: "TECUALA", label: "TECUALA" },
    { value: "TEPIC", label: "TEPIC" },
    { value: "TUXPAN", label: "TUXPAN" },
    { value: "XALISCO", label: "XALISCO" },
    { value: "BAHÍA DE BANDERAS", label: "BAHÍA DE BANDERAS" },
  ];

  return (
    <div className="my-4">
      <h1 className="text-2xl font-medium mb-4">Dar de alta nuevo acopiador</h1>
      <form className="space-y-4">
        <div className="mb-5">
          <TextInput
            label={"Clave de Compra Venta:"}
            id="clave_compra_venta"
            name="clave_compra_venta"
            value={userData.acopiador?.clave_compra_venta || ""}
            onChange={handleClaveCompraVentaChange}
            maxLength={12}
            className="mr-2 w-full"
            toUpperCase
            required
          />
        </div>
        <div className="mb-5">
          <Select
            id="municipio"
            name="municipio"
            label={"Municipio:"}
            value={userData.municipio}
            onChange={handleChange}
            options={municipioOptions}
            required
            className="mr-2 w-full"
          />
        </div>
        <div className="mb-5">
          <TextInput
            label={"Población:"}
            id="poblacion"
            name="poblacion"
            value={userData.acopiador?.poblacion || ""}
            onChange={handleChange}
            maxLength={100}
            validationPattern={"^[a-zA-Z0-9 ]*$"}
            toUpperCase
            required
          />
        </div>
        <div className="mb-5">
          <TextInput
            label={"Razón Social y/o Nombre del Acopio:"}
            id="razon_social"
            name="razon_social"
            value={userData.acopiador?.razon_social || ""}
            onChange={handleChange}
            maxLength={100}
            validationPattern={"^[a-zA-Z0-9 ]*$"}
            toUpperCase
            required
          />
        </div>
        <div className="mb-5">
          <TextInput
            label={"Representante Legal y/o Encargado:"}
            id="representante_legal"
            name="representante_legal"
            value={userData.acopiador?.representante_legal || ""}
            onChange={handleChange}
            maxLength={100}
            validationPattern={"^[a-zA-Z0-9 Ññ.]*$"}
            toUpperCase
            required
          />
        </div>
        <div className="mb-5">
          <Select
            label={"Registro:"}
            id="registro"
            value={userData.registro}
            onChange={handleChange}
            options={registroOptions}
            required
            className="mr-2 w-full"
            name="registro"
          />
        </div>
        <div className="mb-5">
          <NumberInput
            label={"Teléfono:"}
            id="telefono"
            name="telefono"
            value={userData.acopiador?.telefono || ""}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-5">
          <label className="block text-sm font-medium text-gray-900 dark:text-white">
            Fecha de Oficio Autorización:
          </label>
          <input
            type="date"
            name="fecha_oficio_autorizacion"
            className="rounded-xl w-full border border-gray-300 bg-gray-50 text-gray-500 focus:ring-blue-500 focus:border-blue-500 block text-sm p-2.5"
            value={userData.acopiador?.fecha_oficio_autorizacion || ""}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-5">
          <TextInput
            label={"PSG:"}
            id="psg"
            name="psg"
            value={userData.acopiador?.psg || ""}
            onChange={handlePSGChange}
            required
            toUpperCase
            maxLength={15}
          />
        </div>
        <div className="mb-5">
          <label className="block text-sm font-medium text-gray-900 dark:text-white">
            Fecha PSG:
          </label>
          <input
            type="date"
            name="fecha_psg"
            className="rounded-xl w-full border border-gray-300 bg-gray-50 text-gray-500 focus:ring-blue-500 focus:border-blue-500 block text-sm p-2.5"
            value={userData.acopiador?.fecha_psg || ""}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-5">
          <label className="block text-sm font-medium text-gray-900 dark:text-white">
            Expedición Credencial:
          </label>
          <input
            type="date"
            name="expedicion_credencial"
            className="rounded-xl w-full border border-gray-300 bg-gray-50 text-gray-500 focus:ring-blue-500 focus:border-blue-500 block text-sm p-2.5"
            value={userData.acopiador?.expedicion_credencial || ""}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-5">
          <Select
            label={"Tipo de Credencial:"}
            id="tipo_credencial"
            value={userData.tipo_credencial}
            onChange={handleChange}
            options={tipoCredencialOptions}
            required
            className="mr-2 w-full"
            name="tipo_credencial"
          />
        </div>
        <div className="mb-5">
          <TextInput
            label={"Domicilio:"}
            id="domicilio"
            name="domicilio"
            value={userData.acopiador?.domicilio || ""}
            onChange={handleChange}
            maxLength={100}
            toUpperCase
            required
          />
        </div>
        {/* Fotografía pendiente */}
        {/* <div className="mb-5">
          <ImageCropUpload onImageCrop={handleImageCrop} />
        </div> */}
      </form>
    </div>
  );
};

export default CreateAcopiador;
