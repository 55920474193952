// FolioComponent.js
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
} from "react";
import "./styles.css"; // Si estás usando CSS externo
import logo from "./logo.png"; // Si estás usando imágenes
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../Context/AuthContext";
import QRCodeImg from "./QRCodeImg";
const FolioComponent = forwardRef(({ folioId, updateTrigger, data }, ref) => {
  const { authState } = useAuth();

  const componentRef = useRef(); // Referencia al elemento DOM interno

  const apiUrl = process.env.REACT_APP_API_URL;

  const caseta_de_inspeccion = {
    "San Jose de Gracia": "SAN JOSÉ DE GRACIA",
    "La Bayona": "LA BAYONA",
    "Crucero de San Blas": "CRUCERO DE SAN BLAS",
    Otates: "OTATES",
    "Presa el Cajon": "PRESA EL CAJÓN",
  };

  const strings = {
    title: "PERMISO DE MOVILIZACIÓN FUERA DEL ESTADO",
    subtitle: "ESPECIES PRODUCTOS Y SUBPRODUCTOS PECUARIOS",
    header:
      'CON FUNDAMENTO EN EL ARTÍCULO 150 FRACCIÓN IV Y V DEL CAPÍTULO ÚNICO DEL TÍTULO DÉCIMO DE "LA SANIDAD EN PECUARIA" DE LA LEY GANADERA VIGENTE EN EL ESTADO DE NAYARIT, SE AUTORIZA LA MOVILIZACIÓN DE LAS ESPECIES, PRODUCTOS Y SUBPRODUCTOS, QUE A CONTINUACIÓN SE DETALLAN, LOS QUE DEBERÁN VENIR ACOMPAÑADOS DE SU DOCUMENTACIÓN LEGAL ZOOSANITARIA CORRESPONDIENTE.',
    header_0:
      'CON FUNDAMENTO EN EL ARTÍCULO 150 FRACCIÓN IV Y V DEL CAPÍTULO ÚNICO DEL TÍTULO DÉCIMO DE "LA SANIDAD EN',
    header_1:
      'PECUARIA" DE LA LEY GANADERA VIGENTE EN EL ESTADO DE NAYARIT, SE AUTORIZA LA MOVILIZACIÓN DE LAS ESPECIES,',
    header_2:
      "PRODUCTOS Y SUBPRODUCTOS, QUE A CONTINUACIÓN SE DETALLAN, LOS QUE DEBERÁN VENIR ACOMPAÑADOS DE SU",
    header_3: "DOCUMENTACIÓN LEGAL ZOOSANITARIA",
    field_1: "CASETA DE INSPECCIÓN",
    field_1_options: {
      field_1: caseta_de_inspeccion["San Jose de Gracia"],
      field_2: caseta_de_inspeccion["La Bayona"],
      field_3: caseta_de_inspeccion["Crucero de San Blas"],
      field_4: caseta_de_inspeccion["Otates"],
      field_5: caseta_de_inspeccion["Presa el Cajon"],
    },
    field_2: "MOTIVO DE LA MOVILIZACIÓN",
    field_2_options: {
      field_1: "ENGORDA",
      field_2: "SACRIFICIO",
      field_3: "CAMBIO DE AGOSTADERO",
      field_4: "REPRODUCCIÓN",
      field_5: "INDUSTRIA",
      field_6: "CONSUMO",
      field_7: "COMPETENCIA",
    },
    field_3: "ESPECIE",
    field_3_options: {
      field_1: "BOVINA",
      field_2: "PORCINA",
      field_3: "CAPRINA",
      field_4: "OVINA",
      field_5: "EQUINA",
      field_6: "AVÍCOLA",
      field_7: "APÍCOLA",
    },
    field_4: "",
    field_4_options_2: {
      field_14: "CANTIDAD:",
      field_15: "CON LETRA:",
    },
    field_4_options_3: {
      field_16: "M",
      field_17: "H",
    },
    field_4_options_4: {
      field_18: "CABEZA",
      field_19: "KILOGRAMO",
      field_20: "CAJA",
      field_21: "LITRO",
      field_22: "PIEZA",
    },
    field_5: "CERTIFICADOS DE INSPECCIÓN Y/O FACTURAS",
    field_5_options: {
      field_1: "DICTAMEN TB",
      field_2: "CONSTANCIA TRATAMIENTO GARRAPATICIDA",
      field_3: "DICTAMEN BR",
      field_4: "GUÍA ELECTRÓNICA REEMO",
      field_5: "CERTIFICADO ZOOSANITARIO",
    },
    field_6: "EXPLOTACIÓN DE ORIGEN",
    field_6_options: {
      field_1: "NOMBRE:",
      field_2: "DOMICILIO:",
      field_3: "POBLACIÓN:",
      field_4: "MUNICIPIO:",
      field_5: "ESTADO:",
      field_6: "PSG/UPP:",
    },
    field_7: "DESTINO FINAL",
    field_7_options: {
      field_1: "NOMBRE:",
      field_2: "DOMICILIO:",
      field_3: "POBLACIÓN:",
      field_4: "MUNICIPIO:",
      field_5: "ESTADO:",
      field_6: "PSG/UPP:",
    },
    field_8: "FECHA DE EXPEDICIÓN",
    field_9: "VENCIMIENTO",
    field_10: "CENTRO EXPEDITOR",
    field_10_1: "DIRECCION DE GANADERIA",
    field_10_2: "SECRETARIA DE DESARROLLO RURAL",
    field_10_3: "AUTORIZA",
    field_10_4: "SUBSECRETARIO DE GANADERIA",
    footer:
      "NOTA: ESTE DOCUMENTO PERDERÁ VALIDEZ OFICIAL SI PRESENTA TACHADURAS O ENMENDADURAS.",
  };

  useImperativeHandle(ref, () => ({
    getNumeroFolioFinal: () => data?.numero_folio?.numero_folio_final || null,
    getDOMNode: () => componentRef.current,
  }));

  const isChecked = (optionValue, dataField) => {
    const dataValue = normalizeString(String(data[dataField]));
    const lowerOptionValue = normalizeString(optionValue);
    return dataValue === lowerOptionValue;
  };

  const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const renderSection = (fieldOptions, dataField) => {
    return Object.entries(fieldOptions).map(([key, value]) => (
      <main key={key}>
        <p>{value}</p>
        <div className="checkbox">
          {isChecked(value, dataField) && <FontAwesomeIcon icon={faTimes} />}
        </div>
      </main>
    ));
  };

  const addZeros = (number) => {
    // Agrega ceros a la izquierda para que el número tenga 4 dígitos
    if (!number) {
      return null;
    }
    return String(number).padStart(4, "0");
  };

  // Función para obtener el valor de data basado en el prefijo y el campo genérico
  const getFieldValue = (field, prefix) => {
    const normalizeField = (field) => {
      // Quita los dos puntos al final si los hay
      let normalized = field.replace(/:$/, "");

      if (normalized === "psg/upp") {
        return "psg_upp";
      }

      // Eliminar acentos
      normalized = normalized.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

      return normalized;
    };
    // Normaliza el campo
    const normalizedField = normalizeField(field);
    const key = `${normalizedField}_${prefix}`; // Construye la clave como "field_prefix"
    return data[key] || ""; // Devuelve el valor de data o cadena vacía si no se encuentra
  };

  return (
    <div ref={componentRef} className="folio-container mb-4">
      <div className="folio-container-border">
        <div className="graphics">
          <img src={logo} alt="Logo" className="logo" />
          <h1 className="font-primary">
            Folio N° SIMOPE{" "}
            {addZeros(data?.numero_folio?.numero_folio_final) ?? "-"}
          </h1>
          <QRCodeImg folio={data.numero_folio?.numero_folio_final} />
        </div>
        <div className="title font-primary">
          <h1>{strings.title}</h1>
          <h2>{strings.subtitle}</h2>
        </div>
        <div className="header">
          <p className="font-sec">{strings.header}</p>
        </div>
        <div className="label-container font-primary">
          <h1>{strings.field_1}</h1>
        </div>
        <div className="input-container font-sec">
          {renderSection(caseta_de_inspeccion, "caseta_de_inspeccion")}
        </div>
        <div className="label-container font-primary">
          <h1>{strings.field_2}</h1>
        </div>
        <div className="input-container font-sec">
          {renderSection(strings.field_2_options, "motivo_de_movilizacion")}
        </div>
        <div className="label-container font-primary">
          <h1>{strings.field_3}</h1>
        </div>
        <div className="input-container font-sec">
          {renderSection(strings.field_3_options, "especie")}
        </div>
        <div className="label-container font-primary">
          <h1>{strings.field_3}</h1>
        </div>
        <div className="flex font-sec px-4 text-[0.65rem] border-y border-black items-center">
          <div className="w-[20%] border-r border-black h-full py-2 flex">
            <p>{strings.field_4_options_2.field_14}</p>
            <p className="pl-2">{data.cantidad} CABEZAS</p>
          </div>
          <div className="w-[80%] ml-2 py-2 flex border-r border-black">
            <p>{strings.field_4_options_2.field_15}</p>
            <p className="pl-2">{data.cantidad_con_letra} CABEZAS</p>
          </div>
          <div className="flex justify-between ml-4 py-1 items-center">
            <div className="flex items-center">
              <p>{strings.field_4_options_3.field_16}</p>
              <div className="checkbox ">
                <p className="flex text-[0.65rem]">{data.cant_machos}</p>
              </div>
            </div>
            <div className="flex items-center ml-4">
              <p>{strings.field_4_options_3.field_17}</p>
              <div className="checkbox">
                <p className="flex text-[0.65rem]">{data.cant_hembras}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex text-xs h-[240px] border-black w-full">
          <div className="font-primary h-[190px] w-1/2">
            <div className="bg-black text-white font-primary p-2 border-r h-[50px]">
              <p className="text-justify">{strings.field_5}</p>
            </div>
            <div className="h-full border-y-2 bg-white text-black border-black border-r">
              <p className="font-primary px-1 facturas-sec w-full">
                {(data.facturas || []).join(", ")}
              </p>
            </div>
          </div>
          <div className="flex-column font-primary h-[190px] border-black w-full">
            <div className="flex bg-black text-white font-primary  p-4 h-[50px] w-full border-black">
              <p className="aligm-middle align-bottom">ARETES AUTORIZADOS</p>
            </div>
            <div className="flex h-full flex-1 border-y-2 border-black">
              <p className="flex font-primary px-1 aretes-sec">
                {(data.aretes || []).join(", ")}
              </p>
            </div>
          </div>
        </div>
        <div className="flex px-4 pb-1 text-xs font-sec">
          <div className="input-container-4">
            <div className="flex mr-4 items-center mb-[2px]">
              <div className="flex items-center -mb-2">
                <p className="">{strings.field_5_options.field_1}</p>
              </div>
              <div className="linebox">{data.dictamen_tb}</div>
            </div>
            <div className="flex mr-4 items-center mb-[2px]">
              <div className="flex items-center -mb-2">
                <p className="">{strings.field_5_options.field_3}</p>
              </div>
              <div className="linebox">{data.dictamen_br}</div>
            </div>
          </div>
          <div className="input-container-4">
            <div className="flex mr-4 items-center mb-[2px]">
              <div className="flex items-center -mb-2">
                <p className="">{strings.field_5_options.field_2}</p>
              </div>
              <div className="linebox">
                {data.constancia_tratamiento_garrapaticida}
              </div>
            </div>
          </div>
        </div>
        {/* Label */}
        <div className="label-container font-primary w-full justify-between">
          <h1 className="w-[50%]">{strings.field_6}</h1>
          <h1 className="w-[50%]">{strings.field_7}</h1>
        </div>
        <div className="flex px-4 pb-1 text-xs font-sec">
          {/* Bloque para la explotación de origen */}
          <div className="input-container-4">
            {Object.entries(strings.field_6_options).map(([field, label]) => (
              <div key={field} className="flex mr-4 items-center mb-[2px]">
                <div className="flex items-center -mb-2">
                  <p>{label}</p>
                </div>
                <div className="linebox truncate">
                  {getFieldValue(label.toLowerCase(), "origen")}
                </div>
              </div>
            ))}
          </div>
          {/* Bloque para el destino */}
          <div className="input-container-4">
            {Object.entries(strings.field_7_options).map(([field, label]) => (
              <div key={field} className="flex pr-4 items-center mb-[2px]">
                <div className="flex items-center -mb-2">
                  <p>{label}</p>
                </div>
                <div className="linebox truncate">
                  {getFieldValue(label.toLowerCase(), "destino")}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="input-container-5 font-sec">
          <div className="w-1/3 flex flex-col mx-auto">
            <p className="flex justify-center">{strings.field_8}</p>
            <p className="flex justify-center text-sm">
              {new Date(
                `${data.fecha_expedicion}T00:00:00-07:00`
              ).toLocaleDateString("es-MX", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </p>
          </div>
          <div className="w-1/3 flex flex-col mx-auto border-x-2 border-black">
            <p className="flex justify-center">{strings.field_9}</p>
            <p className="flex justify-center text-sm">
              {new Date(
                `${data.fecha_vencimiento}T00:00:00-07:00`
              ).toLocaleDateString("es-MX", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </p>
          </div>
          <div className="w-1/3 flex flex-col mx-auto">
            <p className="flex justify-center">{strings.field_10}</p>
            <p className="flex justify-center text-sm">
              Secretaría de Desarrollo Rural
            </p>
          </div>
        </div>
        <div className="w-full h-[80px] font-sec">
          <p className="max-w-md mx-auto mt-0 text-[0.65rem] text-center mb-8">
            {strings.field_10_3}
          </p>
          <div className="w-[180px] border-b-2 border-black mx-auto mb-1"></div>
          <p className="max-w-md text-[0.65rem] text-center mx-auto">
            {strings.field_10_4}
          </p>
        </div>
        <div className="label-container justify-center font-primary">
          <h1>{strings.footer}</h1>
        </div>
      </div>
    </div>
  );
});

export default FolioComponent;
