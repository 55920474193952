import React from "react";
import "./styles.css";

const TableData = ({
  data,
  indexes,
  actions,
  itemsPerPage = 10,
  currentPage,
  handlePageChange,
  columnWidths = ["10%", "10%", "25%", "10%", "25%", "10%", "10%"],
}) => {
  const indexKeys = Object.keys(indexes);
  const indexValues = Object.values(indexes);

  // Verificar si se proporcionaron currentPage y itemsPerPage
  const isPaginationEnabled =
    currentPage !== undefined && itemsPerPage !== undefined;

  // Calcular el índice de los primeros y últimos elementos de la página actual si la paginación está habilitada
  const indexOfLastItem = isPaginationEnabled
    ? currentPage * itemsPerPage
    : data.length;
  const indexOfFirstItem = isPaginationEnabled
    ? indexOfLastItem - itemsPerPage
    : 0;
  const currentData = isPaginationEnabled
    ? data.slice(indexOfFirstItem, indexOfLastItem)
    : data;

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const RenderEstado = ({ estado, step}) => {
    switch (estado) {
      case "En Progreso":
        if(step === "2") {
          return (
            <div className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900 w-24 text-center">
              Falta de Pago
            </div>
          );}else{
        return (
          <div className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900 w-24 text-center">
            En Progreso
          </div>
        );}
      case "Rechazado":
        return (
          <div className="bg-orange-100 text-orange-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900 w-24 text-center">
            Rechazado
          </div>
        );
      case "Aprobado":
        return (
          <div className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900 w-24 text-center">
            Aprobado
          </div>
        );
      case "Cancelado":
        return (
          <div className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900 w-24 text-center">
            Cancelado
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="w-full py-4 overflow-x-auto">
      <div className="min-w-full inline-block shadow rounded-lg overflow-hidden">
        <table className="table leading-normal">
          <thead>
            <tr className="bg-blue-100">
              {indexValues.map((header, index) => (
                <th
                  key={index}
                  className="text-left table-col"
                  style={{ width: columnWidths[index] }} // Ancho fijo para las columnas
                >
                  {header}
                </th>
              ))}
              {actions && (
                <th
                  className="table-col text-center"
                  style={{ width: columnWidths[indexes.length] }} // Ancho fijo para la columna de acciones
                >
                  Acciones
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {currentData.length === 0 ? (
              <tr>
                <td colSpan={indexKeys.length + (actions ? 1 : 0)} className="text-center py-4 text-gray-600">
                  Nada que mostrar
                </td>
              </tr>
            ) : (
              currentData.map((item) => (
                <tr key={item.folio_id} className="border-b bg-white">
                  {indexKeys.map((key, index) => (
                    <td
                      key={`${item.folio_id}-${key}`} // Añadiendo una key única
                      className="text-left text-xs table-col"
                      style={{ width: columnWidths[index] }} // Estilo para truncar el texto
                    >
                      <span className="truncate">
                        {key === "estado" ? (
                          <RenderEstado estado={item[key]} step={item.step} />
                        ) : (
                          item[key] || "-"
                        )}
                      </span>
                    </td>
                  ))}
                  {actions && (
                    <td
                      key={`${item.folio_id}-acciones`} // Añadiendo una key única
                      className="text-center text-xs table-col"
                      style={{ width: columnWidths[indexes.length] }} // Estilo para truncar el texto
                    >
                      <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{actions(item)}</span>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
        {isPaginationEnabled && (
          <div className="px-5 py-5 border-t bg-white flex flex-col xs:flex-row xs:justify-between items-center">
            <span className="text-xs xs:text-ms text-gray-900">
              Mostrando {indexOfFirstItem + 1} -{" "}
              {Math.min(indexOfLastItem, data.length)} de {data.length} entradas
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="text-sm bg-blue-700 hover:bg-blue-800 text-white font-semibold py-2 px-4 rounded-l"
              >
                Prev
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage >= totalPages}
                className="text-sm bg-blue-700 hover:bg-blue-800 text-white font-semibold py-2 px-4 rounded-r"
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableData;
