import React from "react";
import "../../styles.css";

const Modal = ({ show, onClose, children }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="bg-black bg-opacity-50 absolute inset-0"
        onClick={onClose}
      ></div>
      <div className="bg-white p-6 rounded-lg shadow-lg relative z-10 h-[80vh] max-w-[90%] md:max-w-[40%] w-full flex flex-col">
        <div className="flex-grow overflow-y-auto">{children}</div>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
