import React from "react";
import { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faFileInvoiceDollar,
  faFileInvoice,
  faFileSignature,
  faMoneyCheckDollar,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import BottomDrawerMenu from "../Components/BottomDrawerMenu";
import ActionBar from "../Components/ActionBar";
import TableData from "../Components/TableData";

function Veterinaria() {
  // get db data from api http://localhost:4000/api/ and passing autorization token as header
  const [data, setData] = useState([]);
  const [filtroEstado, setFiltroEstado] = useState("");
  const [terminoBusqueda, setTerminoBusqueda] = useState("");
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false); // Nuevo estado para controlar la visibilidad del menú de filtros
  const [totalPagosPendientes, setTotalPagosPendientes] = useState(0); // Nuevo estado para almacenar el total de pagos pendientes de verificación
  const [currentPage, setCurrentPage] = useState(1); // Nuevo estado para controlar la página actual
  const [itemsPerPage, setItemsPerPage] = useState(10); // Nuevo estado para controlar el número de elementos por página

  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleFileDownload = async (type, id) => {
    try {
      const response = await fetch(`${apiUrl}/files/${type}/${id}`
        , {
          headers: {
            Authorization: `${authState.authToken}`,
          },
        }
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${type}-${id}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // get db data from api http://localhost:4000/api/ and passing autorization token as header
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Construye la URL base
        let url = new URL(`${apiUrl}/folios`);

        // Inicia los parámetros con la búsqueda
        let params = { busqueda: terminoBusqueda };
        // Si filtroEstado es un objeto, añade estado y step a params
        if (typeof filtroEstado === "object" && filtroEstado !== null) {
          params = { ...params, ...filtroEstado };
        } else if (filtroEstado) {
          // Si es un string, solo añade estado a params
          params.estado = filtroEstado;
        }

        // Agrega los parámetros a la URL
        Object.keys(params).forEach((key) =>
          url.searchParams.append(key, params[key])
        );
        // Realiza la petición fetch con los parámetros incluidos en la URL
        const res = await fetch(url, {
          headers: {
            Authorization: `${authState.authToken}`,
          },
        });

        if (!res.ok) {
          throw new Error("Response not ok from API");
        }

        const json = await res.json();
        setData(json); // Asume que existe una función setData para actualizar el estado con los datos recibidos
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [authState.authToken, filtroEstado, terminoBusqueda]); // Dependencias del efecto

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const response = await fetch(`${apiUrl}/pagos/pending/count`, {
          method: "GET",
          headers: {
            Authorization: `${authState.authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch count");
        }

        const data = await response.json();
        setTotalPagosPendientes(data.count); // Ajusta esto según el formato de la respuesta de tu API
      } catch (error) {
        console.error("Error fetching count:", error);
      }
    };

    fetchCount();
  }, [apiUrl, authState.authToken]);

  const handleEditClick = (folio_id) => {
    navigate(`/verify-facturas/${folio_id}`);
  };

  const indexes = {
    folio_id: "Folio",
    motivo_de_movilizacion: "Motivo",
    nombre_origen: "Rancho",
    poblacion_origen: "Población",
    nombre_destino: "Destino",
    estado: "Estado",
  };

  const defaultAction = (folio_id) => (
    <div className="flex space-x-2 font-sec text-center">
      <div
        className="px-4 cursor-pointer"
        onClick={() => {
          handleEditClick(folio_id);
        }}
      >
        <FontAwesomeIcon icon={faPenToSquare} className="px-2" />
        Editar
      </div>
      <div
            className="px-4 cursor-pointer"
            onClick={() => handleFileDownload("dictamen", folio_id)}
          >
            <FontAwesomeIcon icon={faDownload} className="px-2" />
            <span>Descargar Dictamen</span>
          </div>
    </div>
  );

  const actionsMap = {
    Rechazado: {},
    "En Progreso": {
      0: defaultAction,
      1: (folio_id) => (
        <div className="flex space-x-2 font-sec text-sm text-center">
          En revision por Secretaria
        </div>
      ),
      2: (folio_id) => (
        <div className="flex space-x-2 font-sec text-sm text-center">
          En espera de Pago
        </div>
      ),
      3: (folio_id) => (
        <div className="flex space-x-2 font-sec text-center">
          <div
            className="px-4 cursor-pointer"
            onClick={() => navigate(`/payment-invoice/${folio_id}`)}
          >
            <FontAwesomeIcon icon={faFileInvoiceDollar} className="px-2" />
            <span>Ver Pago</span>
          </div>
        </div>
      ),
    },
    Aprobado: {
      6: defaultAction,
    },
    Cancelado: {
      
    },
  };

  const actions = (item) => {
    const action = actionsMap[item.estado][parseInt(item.step)];
    return action ? action(item.folio_id) : <div>-</div>; // show "-" if no action
  };

  const notificationPayments = (totalPagosPendientes) =>
    totalPagosPendientes > 0 && (
      <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
        {totalPagosPendientes}
      </span>
    );

  const actionButtons = [
    {
      id: "pagos",
      label: "Pagos",
      linkTo: "/payments",
      icon: faMoneyCheckDollar,
      title: "Ir a los pagos",
      additionalElement: notificationPayments(totalPagosPendientes),
    },
  ];

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setTerminoBusqueda(e.target.value);
  };

  return (
    <div className="w-full md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto">
      <h1 className="text-2xl leading-tight font-titles">Veterinario/a {authState.user.nombre} {authState.user.apellido}</h1>
      <div className="text-2xl mb-4 lg:flex lg:justify-between px-2 items-center">
        <div className="w-full text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul className="lg:flex lg:flex-wrap -mb-px lg:items-center justify-between">
            <li>
              <ActionBar
                itemsPerPage={itemsPerPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                searchTerm={terminoBusqueda}
                handleSearchChange={handleSearchChange}
                actions={actionButtons}
              />
            </li>
            <li className="">
              <BottomDrawerMenu
                isMenuOpen={isFilterMenuOpen}
                setFiltroEstado={setFiltroEstado}
                filtroEstado={filtroEstado}
                opcionesExtraFiltro={[
                  {
                    valor: { estado: "En Progreso", step: "2" },
                    etiqueta: "Falta de Pago",
                  },
                ]}
              />
            </li>
          </ul>
        </div>
      </div>
      <TableData
        data={data}
        indexes={indexes}
        actions={actions}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </div>
  );
}

export default Veterinaria;
