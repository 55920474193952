import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";

import PDFViewer from "../../Components/PDFViewer";
import Button from "../../Components/Custom/Button";
import Layout from "../../Components/Layout";
import { toast } from "react-toastify";

const PaymentInvoice = () => {
  const { authState } = useContext(AuthContext);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [paymentData, setPaymentData] = useState({});

  const navigate = useNavigate();
  const { folio_id } = useParams();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        const response = await fetch(`${apiUrl}/pagos/folio/${folio_id}`, {
          method: "GET",
          headers: {
            Authorization: `${authState.authToken}`,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          toast.error(`Error: ${errorData.message}`);
          throw new Error("Error fetching payment data");
        }

        const data = await response.json();
        if (data.pago_permiso_movilizacion) {
          setPaymentData(data.pago_permiso_movilizacion);
        }
      } catch (error) {
        toast.error(`Error: ${error}`);
        console.error("Error:", error);
      }
    };

    if (authState.user) {
      fetchPaymentData();
    }
  }, [apiUrl, authState.authToken, authState.user, folio_id]);

  const handleOnClick = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/pagos/${folio_id}/verificar-asignacion`,
        {
          method: "PUT",
          headers: {
            Authorization: `${authState.authToken}`,
          },
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || "Error al aprobar el pago");
      }
      toast.success(result.message);
      navigate("/");
    } catch (error) {
      setErrorMessage(error.message || "Error al aprobar el pago");
      toast.error(errorMessage);
    }
  };

  return (
    <Layout>
      <div className="w-full flex h-[calc(100vh-96px)]">
        <div className="w-1/2 bg-white shadow-lg">
          <PDFViewer
            pdfUrl={`${apiUrl}/files/pago_folio/${folio_id}`}
            authState={authState}
            initialOrientation="vertical"
            containerStyle={{ height: "100%" }}
          />
        </div>
        <div className="w-1/2 h-full flex flex-col bg-white shadow-lg">
          <div className="px-4 py-2">
            <h1 className="text-2xl font-primary">
              Pago designado para el folio N° {folio_id}
            </h1>
            <p className="text-sm text-gray-500 mt-2">
              Asegúrate de revisar el pago antes de aprobarlo
            </p>

            <div className="max-w-full inline-block shadow rounded-lg overflow-hidden mt-4">
              <table className="table leading-normal">
                <thead>
                  <tr className="bg-blue-100">
                    <th className="px-4 py-2">Fecha de Pago</th>
                    <th className="px-4 py-2">Cantidad Folios Pagados</th>
                    <th className="px-4 py-2">Cantidad Folios Restantes</th>
                    <th className="px-4 py-2">Número de Pago</th>
                    <th className="px-4 py-2">Monto</th>
                    <th className="px-4 py-2">Tipo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b">
                    <td className="text-center">{paymentData.fecha_pago}</td>
                    <td className="text-center">
                      {paymentData.numero_folios_pagados}
                    </td>
                    <td className="text-center">
                      {paymentData.numero_folios_pagados-paymentData.countPagosFolios}
                    </td>
                    <td className="text-center">{paymentData.numero_folio_talon}</td>
                    <td className="text-center">${paymentData.monto_pago}</td>
                    <td className="text-center">{paymentData.tipo}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="w-full flex justify-between">
            <Button onClick={handleOnClick}>Aprobar</Button>
            {/* <Button onClick={() => navigate("/")} type="reject">Cancelar</Button> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PaymentInvoice;
