import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const ActionBar = ({
  itemsPerPage,
  handleItemsPerPageChange,
  searchTerm,
  handleSearchChange,
  actions,
}) => {
  const isExternalLink = (url) => {
    return url.startsWith("http://") || url.startsWith("https://");
  };

  return (
    <div className="my-2 justify-between items-center flex">
      <div className="flex flex-row">
        <div className="relative">
          <select
            className="appearance-none h-full rounded-l cursor-pointer border border-gray-400 border-b block py-2 px-4 mr-2 leading-tight w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 items-center flex text-gray-700 px-2">
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
        <div className="block relative">
          <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </span>
          <input
            type="text"
            id="search"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            className={`appearance-none h-full sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none ${
              actions ? "" : "rounded-r"
            }`}
          />
        </div>
        {actions &&
          actions.map((action, index) => (
            <div className="block relative" key={index}>
              {isExternalLink(action.linkTo) ? (
                <a
                  href={action.linkTo}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={action.icon}
                    className={`px-2 py-2 h-6 w-6 text-black hover:text-slate-800 border cursor-pointer border-gray-400 border-b block relative ${
                      index === actions.length - 1 ? "rounded-r" : ""
                    }`}
                    title={action.title}
                    id={action.id}
                  />
                  {action.additionalElement && action.additionalElement}
                </a>
              ) : (
                <Link to={action.linkTo}>
                  <FontAwesomeIcon
                    icon={action.icon}
                    className={`px-2 py-2 h-6 w-6 text-black hover:text-slate-800 border cursor-pointer border-gray-400 border-b block relative ${
                      index === actions.length - 1 ? "rounded-r" : ""
                    }`}
                    title={action.title}
                    id={action.id}
                  />
                  {action.additionalElement && action.additionalElement}
                </Link>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ActionBar;
