// ChangePassword.js
import React, { useContext, useState } from "react";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import Layout from "../../Components/Layout";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";

const ChangePassword = () => {
  const { authState, setAuthState } = useContext(AuthContext);
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      alert("Las nuevas contraseñas no coinciden");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/changePassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authState.authToken}`,
          },
          body: JSON.stringify({
            newPassword,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setAuthToken(data.token);
        toast.success("Contraseña cambiada correctamente");
        navigate("/");
      } else {
        const errorData = await response.json();
        toast.error(errorData.message);
      }
    } catch (error) {
      console.error("Error al cambiar la contraseña:", error);
    }
  };

  const setAuthToken = (token) => {
    localStorage.setItem("token", token);
    const decodedToken = jwtDecode(token);
    setAuthState({ authToken: token, user: decodedToken });
  };

  return (
    <Layout>
      <div className="mt-4 w-full font-titles">
        <h1 className="text-2xl font-titles text-left mb-5 max-w-md mx-auto">
          Cambiar Contraseña
        </h1>

        <form className="max-w-md mx-auto" onSubmit={handleSubmit}>
          <div className="mb-5">
            <label
              htmlFor="input-new-password"
              className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
            >
              Nueva Contraseña
            </label>
            <input
              type="password"
              id="input-new-password"
              className="rounded-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block min-w-64 w-full text-sm p-2.5"
              placeholder="Nueva Contraseña"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              autoComplete="new-password"
            />
          </div>
          <div className="mb-5">
            <label
              htmlFor="input-confirm-password"
              className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
            >
              Confirmar Nueva Contraseña
            </label>
            <input
              type="password"
              id="input-confirm-password"
              className="rounded-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block min-w-64 w-full text-sm p-2.5"
              placeholder="Confirmar Nueva Contraseña"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              autoComplete="new-password"
            />
          </div>
          <button
            type="submit"
            id="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Cambiar Contraseña
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default ChangePassword;
