import React, { useContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import Button from "../../Components/Custom/Button";
import { toast } from "react-toastify";
import Modal from "../../Components/Custom/Modal";

import Layout from "../../Components/Layout";

import FolioComponent from "../../Components/FolioComponent";
import { useReactToPrint } from "react-to-print";

const SubCheckPage = () => {
  const { authState } = useContext(AuthContext);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { id } = useParams();
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [numeroFolioFinal, setNumeroFolioFinal] = useState(null);
  const [isApproving, setIsApproving] = useState(false);
  const componentRef = useRef();
  const [data, setData] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [motivoRechazo, setMotivoRechazo] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Calcula la fecha de expedición y la fecha de vencimiento
        const fecha_expedicion = new Date();
        const fecha_vencimiento = new Date(fecha_expedicion);
        fecha_vencimiento.setDate(fecha_expedicion.getDate() + 4);

        // Llama a la API
        const response = await axios.get(
          `${apiUrl}/permiso-movilizacion/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${authState.authToken}`,
            },
          }
        );
        // Establece los datos recibidos junto con las fechas calculadas
        setData({
          ...response.data,
          fecha_expedicion: fecha_expedicion.toISOString().split("T")[0], // Formato de fecha YYYY-MM-DD
          fecha_vencimiento: fecha_vencimiento.toISOString().split("T")[0], // Formato de fecha YYYY-MM-DD
        });
      } catch (error) {
        console.error("Error al cargar los datos:", error);
      }
    };

    fetchData();
  }, [authState.authToken, apiUrl, id, updateTrigger]); // Dependencias del useEffect

  useEffect(() => {
    if (data.numero_folio?.numero_folio_final) {
      setNumeroFolioFinal(data.numero_folio.numero_folio_final);
    }
  }, [updateTrigger]);

  const handleApprove = async () => {
    setIsApproving(true);
    const fecha_expedicion = new Date().toISOString().split("T")[0];
    const centro_expeditor = "Secretaría de Desarrollo Rural";

    try {
      const response = await axios.put(
        `${apiUrl}/folios/${id}/preapprove`,
        { fecha_expedicion, centro_expeditor },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${authState.authToken}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        setUpdateTrigger((prev) => prev + 1);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error al aprobar el folio");
      }
      console.error("Error al aprobar el folio:", error);
    } finally {
      setIsApproving(false);
    }
  };

  const handleOpenModal = () => {
    setModalVisible(true);
  };
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleAccept = async (e) => {
    try {

        const response = await axios.post(
          `${apiUrl}/folios/${id}/cancel`,
          {comentarios: motivoRechazo},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `${authState.authToken}`,
            },
            body: {
              comentarios: motivoRechazo,
            },
        }
        );

      if (response.status === 200) {
        toast.success(response.data.message);
        navigate("/");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Error al cancelar el folio");
      }
      console.error("Error al cancelar el folio:", error);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current.getDOMNode(),
    documentTitle: "Folio",
    onAfterPrint: () => {
      toast.success("PDF descargado correctamente");
    },
  });

  return (
    <Layout>
      <FolioComponent
        ref={componentRef}
        folioId={id}
        updateTrigger={updateTrigger}
        data={data}
      />

      <div className="flex justify-between mb-4">
        <button
          onClick={handleApprove}
          className="text-white font-titles text-md bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg w-40 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          disabled={isApproving}
        >
          Aprobar Folio
        </button>
        <Button
          className="ml-8"
          onClick={handlePrint}
          disabled={!data.numero_folio?.numero_folio_final}
        >
          Descargar PDF
        </Button>
        <button
          onClick={handleOpenModal}
          className="ml-8 text-white font-titles text-md bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg w-40 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
          disabled={data.numero_folio?.numero_folio_final}
        >
          Cancelar Permiso
        </button>
      </div>
      {modalVisible && (
        <Modal
          show={modalVisible}
          onCancel={handleCloseModal}
          onAccept={handleAccept}
        >
          <div className="text-center">
            <p>¿Estás seguro de cancelar el folio? </p>
            <p>Esta acción no se puede deshacer.</p>
            <form action="">
              <textarea
                name="motivoRechazo"
                id="motivoRechazo"
                cols="30"
                rows="10"
                className="w-full mt-4"
                placeholder="Motivo de cancelación"
                value={motivoRechazo}
                onChange={(e) => setMotivoRechazo(e.target.value)}
              ></textarea>
              </form>
          </div>
        </Modal>
      )}
    </Layout>
  );
};

export default SubCheckPage;
