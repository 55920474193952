import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../Components/Layout";
import Modal from "../../Components/Modal";
import PDFViewer from "../../Components/PDFViewer";
import { useAuth } from "../../Context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";

import "./styles.css";

const FolioForm = () => {
  const { authState, isDocumentViewerOpen } = useAuth();
  const { folio_id } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);

  //useStates para settear los valores de los inputs
  const [casetaDeInspeccion, setCasetaDeInspeccion] = useState("");
  const [motivoDeMovilización, setMotivoDeMovilización] = useState("");
  const [especie, setEspecie] = useState("");
  const [productosYSubproductos, setProductosYSubproductos] = useState("");
  const [cantidad, setCantidad] = useState(0);
  const [cantidadConLetra, setCantidadConLetra] = useState("");
  const [cantMachos, setCantMachos] = useState(0);
  const [cantHembras, setCantHembras] = useState(0);
  const [unidades, setUnidades] = useState("");
  const [dictamenTB1, setDictamenTB1] = useState("");
  const [dictamenTB2, setDictamenTB2] = useState("");
  const [dictamenBR, setDictamenBR] = useState("");
  const [
    constanciaTratamientoGarrapaticida,
    setConstanciaTratamientoGarrapaticida,
  ] = useState("");
  const [guiaElectronicaReemo, setGuiaElectronicaReemo] = useState("");
  const [nombreOrigen, setNombreOrigen] = useState("");
  const [domOrigen, setDomOrigen] = useState("");
  const [poblacionOrigen, setPoblacionOrigen] = useState("");
  const [municipioOrigen, setMunicipioOrigen] = useState("");
  const [estadoOrigen, setEstadoOrigen] = useState("");
  const [psguuppOrigen, setPsguuppOrigen] = useState("");
  const [nombreDestino, setNombreDestino] = useState("");
  const [domDestino, setDomDestino] = useState("");
  const [poblacionDestino, setPoblacionDestino] = useState("");
  const [municipioDestino, setMunicipioDestino] = useState("");
  const [estadoDestino, setEstadoDestino] = useState("");
  const [psguuppDestino, setPsguuppDestino] = useState("");
  const [centroExpeditor, setCentroExpeditor] = useState(
    "Centro de Expedición"
  );
  const [numerosAretes, setNumerosAretes] = useState("");
  const [certificadosInspeccion, setCertificadosInspeccion] = useState("");

  const [comments, setComments] = useState("");

  useEffect(() => {
    const cargarFolio = async () => {
      try {
        const response = await fetch(`${apiUrl}/folio/${folio_id}`, {
          headers: {
            Authorization: `${authState.authToken}`,
          },
        });
        if (!response.ok) {
          throw new Error("Folio no encontrado");
        }
        const folioData = await response.json();
        // Actualizar los estados con los datos del folio
        setCasetaDeInspeccion(folioData.caseta_de_inspeccion);
        setMotivoDeMovilización(folioData.motivo_de_movilizacion);
        setEspecie(folioData.especie);
        setProductosYSubproductos(folioData.productos_y_subproductos);
        setCantidad(folioData.cantidad);
        setCantidadConLetra(folioData.cantidad_con_letra);
        setCantMachos(folioData.cant_machos);
        setCantHembras(folioData.cant_hembras);
        setUnidades(folioData.unidades);
        setDictamenTB1(folioData.dictamen_tb?.split(",")[0]===undefined?'':folioData.dictamen_tb.split(",")[0]);
        setDictamenTB2(folioData.dictamen_tb?.split(",")[1]===undefined?'':folioData.dictamen_tb.split(",")[1]);
        
        setDictamenBR(folioData.dictamen_br);
        setConstanciaTratamientoGarrapaticida(
          folioData.constancia_tratamiento_garrapaticida
        );
        setGuiaElectronicaReemo(folioData.guia_electronica_reemo);
        setNombreOrigen(folioData.nombre_origen);
        setDomOrigen(folioData.domicilio_origen);
        setPoblacionOrigen(folioData.poblacion_origen);
        setMunicipioOrigen(folioData.municipio_origen);
        setEstadoOrigen(folioData.estado_origen);
        setPsguuppOrigen(folioData.psg_upp_origen);
        setNombreDestino(folioData.nombre_destino);
        setDomDestino(folioData.domicilio_destino);
        setPoblacionDestino(folioData.poblacion_destino);
        setMunicipioDestino(folioData.municipio_destino);
        setEstadoDestino(folioData.estado_destino);
        setPsguuppDestino(folioData.psg_upp_destino);
        setCentroExpeditor(folioData.centro_expeditor);

        // ... actualiza el resto de tus estados con los datos de folioData
      } catch (error) {
        console.error("Error al cargar datos del folio:", error);
      }
    };
    cargarFolio();
  }, [folio_id, authState.authToken]);

  //Funcion para enviar los datos del formulario
  const handleSubmit = async (e) => {
    if (dictamenTB1 === undefined) {
      setDictamenTB1("");
    }
    if (dictamenTB2 === undefined) {
      setDictamenTB2("");
    }
    const dictamenTBMerged = `${dictamenTB1},${dictamenTB2}`;

    let dictamenTB = ",";
    if (dictamenTBMerged !== "," || dictamenTBMerged !== "undefined,undefined") {
      dictamenTB = dictamenTBMerged;
    }


    e.preventDefault();
    // tipo de dato de cantidad
    try {
      const cuerpo =
        authState.user.rol === "Veterinaria"
          ? JSON.stringify({
              caseta_de_inspeccion: casetaDeInspeccion,
              dictamen_tb: dictamenTB,
              dictamen_br: dictamenBR,
              constancia_tratamiento_garrapaticida:
                constanciaTratamientoGarrapaticida,
              comentarios: comments,
              vet_id: authState.user.usuario_id,
            })
          : JSON.stringify({
              caseta_de_inspeccion: casetaDeInspeccion,
              dictamen_tb: dictamenTB,
              dictamen_br: dictamenBR,
              constancia_tratamiento_garrapaticida:
                constanciaTratamientoGarrapaticida,
              comentarios: comments,
            });
      const response = await fetch(`${apiUrl}/folios/${folio_id}/verify`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authState.authToken}`,
        },
        body: cuerpo,
      });

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message);
        navigate("/");
      }
    } catch (error) {
      console.error("Error de red al intentar rechazar el folio", error);
    }
  };

  const [motivo, setMotivo] = useState({
    vendedor: [],
    comprador: [],
    animales: [],
    // Asegúrate de que todas las categorías esperadas estén inicializadas como arrays vacíos.
  });

  // Opciones de casetas de inspección
  const caseta_de_inspeccion = {
    "San Jose de Gracia": "San José de Gracia (Ixtlán del Río)",
    "La Bayona": "La Bayona (Acaponeta)",
    "Crucero de San Blas": "Crucero de San Blas",
    Otates: "Otates (Compostela)",
    "Presa el Cajon": "Presa el Cajón",
  };

  // Opciones de motivos para cada categoría
  const opcionesMotivos = {
    vendedor: [
      "NOMBRE DEL VENDEDOR NO COINCIDE",
      "LOCALIDAD Y MUNICIPIO NO CORRESPONDE",
      "FALTA CLAVE DE GANADERO",
      "FALTA FIRMA DEL VENDEDOR",
      "NO CORRESPONDE LA FIRMA DEL VENDEDOR",
    ],
    comprador: [
      "NOMBRE INCORRECTO",
      "NO COINCIDE LA FIRMA",
      "CLAVE DE COMPRA - VENTA",
      "CLAVE DE PSG",
    ],
    animales: [
      "TACHADURAS Y/O ENMENDADURAS EN EL NUMERO DE ARETE",
      "ERRORES EN EL FIERRO",
    ],
  };

  const handleOpenModal = () => {
    // Restablece motivo a su estado inicial estructurado, si es necesario.
    setMotivo({
      vendedor: [],
      comprador: [],
      animales: [],
    });
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleModalSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/folios/${folio_id}/cancel`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${authState.authToken}`,
        },
        body: JSON.stringify({
          comentarios: comments, // Asegúrate de que esto se maneje adecuadamente en el servidor
        }),
      });

      if (response.ok) {
        const data = await response.json();

        handleCloseModal();
        toast.success(data.message);
        navigate("/");
      }
    } catch (error) {
      console.error("Error de red al intentar rechazar el folio", error);
    }
  };
  return (
    <Layout>
      <div className="flex w-full flex-grow px-2 mt-4">
        <div className="w-2/3 h-[calc(100vh-6rem)] overflow-y-auto overflow-x-hidden md:order-0">
          <PDFViewer
            pdfUrl={`${apiUrl}/files/dictamen/${folio_id}`}
            authState={authState}
            initialOrientation="vertical"
            containerStyle={{ height: "100%", width: "100%" }}
          />
        </div>
        <div className="w-full form-container overflow-y-auto overflow-x-hidden mb-4">
          <div className="w-full flex justify-center">
            <h1 className="text-4xl font-titles text-left mb-5 max-w-md">
              Folio {folio_id}
            </h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="flex mb-5">
              <div className="mx-4 w-full">
                <label
                  htmlFor="casetas"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Caseta de Inspección
                </label>
                <select
                  id="casetas"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={casetaDeInspeccion}
                  onChange={(e) => setCasetaDeInspeccion(e.target.value)}
                >
                  <option value="" disabled>
                    Seleccione una caseta de inspección
                  </option>
                  {Object.entries(caseta_de_inspeccion).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex mb-5">
              <div className="mx-4 w-full">
                <label
                  htmlFor="dictamen-tb"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Dictamen TB 1
                </label>
                <input
                  type="text"
                  id="dictamen-tb"
                  className="rounded-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-bue-500 focus:border-blue-500 block w-full text-sm p-2.5"
                  placeholder=""
                  value={dictamenTB1}
                  onChange={(e) => setDictamenTB1(e.target.value)}
                />
              </div>
              <div className="mx-4 w-full">
                <label
                  htmlFor="dictamen-tb"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Dictamen TB 2
                </label>
                <input
                  type="text"
                  id="dictamen-tb"
                  className="rounded-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-bue-500 focus:border-blue-500 block w-full text-sm p-2.5"
                  placeholder=""
                  value={dictamenTB2}
                  onChange={(e) => setDictamenTB2(e.target.value)}
                />
              </div>
              <div className="mx-4 w-full">
                <label
                  htmlFor="dictamen-br"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Dictamen BR
                </label>
                <input
                  type="text"
                  id="dictamen-br"
                  className="rounded-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-bue-500 focus:border-blue-500 block w-full text-sm p-2.5"
                  placeholder=""
                  value={dictamenBR}
                  onChange={(e) => setDictamenBR(e.target.value)}
                />
              </div>
              <div className="mx-4 w-full">
                <label
                  htmlFor="constancia-tratamiento-garrapaticida"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Constancia Tratamiendo Garrapaticida
                </label>
                <input
                  type="text"
                  id="constancia-tratamiento-garrapaticida"
                  className="rounded-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-bue-500 focus:border-blue-500 block w-full text-sm p-2.5"
                  placeholder=""
                  value={constanciaTratamientoGarrapaticida}
                  onChange={(e) =>
                    setConstanciaTratamientoGarrapaticida(e.target.value)
                  }
                />
              </div>
            </div>
            <div className="mb-5 p-4 bg-gray-100 rounded-lg">
              <h2 className="text-lg font-semibold mb-2">
                Información del Folio
              </h2>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p>
                    <strong>Nombre Origen:</strong> {nombreOrigen}
                  </p>
                  <p>
                    <strong>Domicilio Origen:</strong> {domOrigen}
                  </p>
                  <p>
                    <strong>Población Origen:</strong> {poblacionOrigen}
                  </p>
                  <p>
                    <strong>Municipio Origen:</strong> {municipioOrigen}
                  </p>
                  <p>
                    <strong>Estado Origen:</strong> {estadoOrigen}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Nombre Destino:</strong> {nombreDestino}
                  </p>
                  <p>
                    <strong>Domicilio Destino:</strong> {domDestino}
                  </p>
                  <p>
                    <strong>Población Destino:</strong> {poblacionDestino}
                  </p>
                  <p>
                    <strong>Municipio Destino:</strong> {municipioDestino}
                  </p>
                  <p>
                    <strong>Estado Destino:</strong> {estadoDestino}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between px-4 w-full">
              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-40 px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Enviar
              </button>
              <button
                onClick={handleOpenModal}
                type="button"
                className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-40 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              >
                Cancelar Folio
              </button>
            </div>
          </form>
          <Modal show={modalVisible} onClose={handleCloseModal}>
            <div className="px-4">
              <h1 className="font-primary">
                ¿Está seguro de proceder con la cancelación total del folio?
              </h1>
              <div className="py-4">
                <p>Esta acción no se puede deshacer.</p>
              </div>
              <div className="p">
                <form onSubmit={handleModalSubmit}>
                  <div className="mb-4">
                    <label
                      htmlFor="comments"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Comentarios
                    </label>
                    <textarea
                      id="comments"
                      className="rounded-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full text-sm p-2.5"
                      placeholder="Ingrese sus comentarios"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-fit-content"
                  >
                    Cancelar Folio
                  </button>
                </form>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </Layout>
  );
};

export default FolioForm;
