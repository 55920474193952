import React, { useEffect, useState } from "react";
import Button from "../../Custom/Button";
import PDFViewer from "../../PDFViewer";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../../Context/AuthContext";
import { toast } from "react-toastify";

const AretesVerification = () => {
  const { folio_id } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { authState } = useAuth();
  const [Aretes, setAretes] = useState([]);
  const [selectedAretes, setSelectedAretes] = useState({});

  // Get aretes for folio id
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${apiUrl}/aretes/folio/${folio_id}`
        );
        if (!res.ok) {
          throw new Error("Error al obtener los aretes");
        }
        const dataAretes = await res.json();
        setAretes(dataAretes.aretes);

        // Automatically select aretes with in_dictamen = true
        const initialSelectedAretes = {};
        dataAretes.aretes.forEach((arete, index) => {
          if (arete.in_dictamen) {
            initialSelectedAretes[index] = true;
          }
        });
        setSelectedAretes(initialSelectedAretes);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [folio_id]);

  const toggleArete = (index) => {
    setSelectedAretes((prevSelectedAretes) => ({
      ...prevSelectedAretes,
      [index]: !prevSelectedAretes[index],
    }));
  };

  const toggleSelectAll = () => {
    const allSelected = Aretes.length === Object.keys(selectedAretes).length;
    if (allSelected) {
      setSelectedAretes({});
    } else {
      const newSelected = {};
      Aretes.forEach((_, index) => {
        newSelected[index] = true;
      });
      setSelectedAretes(newSelected);
    }
  };

  const handleSubmit = async () => {
    const aretesSeleccionados = Aretes.filter(
      (_, index) => selectedAretes[index]
    ).map((arete) => ({ numero_arete: arete.numero_arete, sexo: arete.sexo }));

    const aretesNoSeleccionados = Aretes.filter(
      (_, index) => !selectedAretes[index]
    ).map((arete) => ({ numero_arete: arete.numero_arete, sexo: arete.sexo }));

    try {
      const res = await fetch(
        `${apiUrl}/aretes/update/dictamen`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ folio_id, aretes: aretesSeleccionados, aretesR: aretesNoSeleccionados }),
        }
      );
      if (!res.ok) {
        throw new Error("Error al enviar los aretes seleccionados");  
      }

      const data = await res.json();

      toast.success(data.message);
      navigate(`/folio/${folio_id}`);
    } catch (error) {
      console.error("Error al enviar los aretes seleccionados:", error);
    }
  };

  return (
    <div className="w-full flex h-[calc(100vh-96px)]">
      <div className="w-full lg:w-1/2 h-full flex flex-col bg-white shadow-lg border-r ">
        <div className="px-4 py-2 bg-white">
          <h1 className="text-2xl font-primary">Dictamen</h1>
        </div>
        <div className="flex-grow overflow-y-scroll">
          <PDFViewer
            pdfUrl={`${apiUrl}/files/dictamen/${folio_id}`}
            authState={authState}
            initialOrientation="vertical"
            containerStyle={{ height: "100%" }}
          />
        </div>
      </div>
      <div className="w-1/2 h-full flex flex-col bg-white shadow-lg">
        <div className="flex px-4 py-2 w-full">
          <h1 className="flex-1 text-2xl font-primary">Aretes</h1>
          <p className="flex-2 text-sm text-gray-600 mb-2 mt-2 mr-5 font-bold">
            Machos: {Aretes.filter((arete) => arete.sexo === "M").length} |
            Hembras: {Aretes.filter((arete) => arete.sexo !== "M").length}
          </p>
          </div>
        <div className="ml-4">
          <button
            onClick={toggleSelectAll}
            className="flex bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-200"
          >
            {Aretes.length === Object.keys(selectedAretes).length
              ? "Deseleccionar Todos"
              : "Seleccionar Todos"}
          </button>
          </div>
        <div className="flex-grow overflow-y-scroll p-2">
          {Aretes.map((arete, index) => (
            <div
              key={index}
              className={`flex justify-between items-center p-2 mb-1 border rounded-md cursor-pointer transition-colors duration-200 ${
                selectedAretes[index]
                  ? "bg-blue-200"
                  : "bg-white hover:bg-gray-100"
              }`}
              onClick={() => toggleArete(index)}
            >
              <div className="flex items-center space-x-2">
                <span className="text-xs font-medium text-gray-800">
                  {arete.numero_arete}
                </span>
                <span className="text-xs text-gray-600">
                  {arete.sexo === "M" ? "Macho" : "Hembra"}
                </span>
              </div>
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-blue-600"
                checked={!!selectedAretes[index]}
                readOnly
              />
            </div>
          ))}
        </div>
        <div className="p-4 border-t bg-gray-200 flex justify-end">
          <Button onClick={handleSubmit}>Verificar</Button>
        </div>
      </div>
    </div>
  );
};

export default AretesVerification;
