import React from "react";

const MotivosRechazo = ({ motivos }) => {
  if (!motivos) return <p>No hay motivos de rechazo disponibles.</p>;

  return (
    <div className="h-1/2">
      <h2 className="text-xl font-bold mb-4">Motivos de Rechazo</h2>
      {motivos.aretesRechazados && motivos.aretesRechazados.length > 0 && (
        <div className="mb-4">
          <h3 className="font-semibold">Aretes Rechazados:</h3>
          {motivos.aretesRechazados.map((arete, index) => (
            <div key={index} className="mb-2 border-t-2">
              <p>
                <strong>Número de Arete:</strong> {arete.numero_arete}
              </p>
              <p>
                <strong>Motivo de Rechazo:</strong> {arete.motivo_rechazo}
              </p>
              {arete.comentario && (
                <p>
                  <strong>Comentario:</strong> {arete.comentario}
                </p>
              )}
              {arete.numero_de_factura && (
                <p>
                  <strong>Factura:</strong> {arete.numero_de_factura}
                </p>
              )}
            </div>
          ))}
        </div>
      )}
      {motivos.facturasRechazadas && motivos.facturasRechazadas.length > 0 && (
        <div className="mb-4">
          <h3 className="font-semibold">Facturas Rechazadas:</h3>
          {motivos.facturasRechazadas.map((factura, index) => (
            <div key={index} className="mb-2">
              <p>
                <strong>Número de Factura:</strong> {factura.numero_de_factura}
              </p>
              <p>
                <strong>Motivo de Rechazo:</strong> {factura.motivo_rechazo}
              </p>
            </div>
          ))}
        </div>
      )}
      {motivos.reemosRechazados && motivos.reemosRechazados.length > 0 && (
        <div className="mb-4">
          <h3 className="font-semibold">REEMOs Rechazados:</h3>
          {motivos.reemosRechazados.map((reemo, index) => (
            <div key={index} className="mb-2">
              <p>
                <strong>Número de REEMO:</strong> {reemo.numero_de_reemo}
              </p>
              <p>
                <strong>Motivo de Rechazo:</strong> {reemo.motivo_rechazo}
              </p>
            </div>
          ))}
        </div>
      )}
      {motivos.comentarios && (
        <div className="mb-4">
          <h3 className="font-semibold">Comentarios:</h3>
          {motivos.comentarios.map((comentario, index) => (
            <div key={index} className="mb-2">
              <p>{comentario}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MotivosRechazo;
