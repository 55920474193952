import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "./Context/AuthContext";
import axios from "axios";
import Layout from "./Components/Layout";

const PrivateRoute = ({
  allowedRoles = ["Acopiador", "Veterinaria", "Secretaria", "Root"],
}) => {
  const { authState, setAuthState } = useContext(AuthContext);
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() => {
    if (authState.isLoading) {
      return;
    }

    const verifyAuth = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/verify`,
          {},
          {
            headers: {
              Authorization: `${authState.authToken}`,
            },
          }
        );
        if (
          response.data.message === "Token is valid" &&
          allowedRoles.includes(authState.user.rol)
        ) {
          setIsAuthorized(true);
        } else {
          throw new Error("Not authorized");
        }
      } catch (error) {
        setAuthState({ ...authState, authToken: null });
        setIsAuthorized(false);
      }
    };

    if (authState.authToken) {
      verifyAuth();
    } else {
      setIsAuthorized(false);
    }
  }, [authState, setAuthState, allowedRoles]);

  if (authState.isLoading) {
    return (
      <Layout>
        <div className="mt-4">Cargando...</div>
      </Layout>
    );
  }

  if (isAuthorized === null) {
    return <div className="mt-4">Cargando...</div>;
  }

  return isAuthorized ? <Outlet /> : <Navigate to="/unauthorized" replace />;
};

export default PrivateRoute;
