import React from "react";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// FormComponents
import NumberInput from "../../Custom/Forms/NumberInput";
import Select from "../../Custom/Forms/Select";

const AreteInput = ({
  arete,
  index,
  areteIndex,
  handleAreteChange,
  handleRemoveArete,
}) => {
  const options = [
    { value: "M", label: "Macho" },
    { value: "F", label: "Hembra" },
  ];

  return (
    <div
      key={areteIndex}
      className="mb-2 flex items-center w-full h-full justify-between"
      data-testid={`arete-group-${index}-${areteIndex}`}
    >
      <div className="flex justify-center">
        <p className="text-sm font-semibold mb-4 w-4">#{areteIndex + 1}</p>
      </div>
      <div className="w-2/3">
        <NumberInput
          required
          value={arete.numero_arete}
          onChange={(e) =>
            handleAreteChange(index, areteIndex, "numero_arete", e.target.value)
          }
          placeholder={`Número de Arete`}
          className={"mr-2"}
          data-testid={`numero-arete-${index}-${areteIndex}`}
        />
      </div>
      <div className="flex">
        <Select
          id={`sexo-${index}-${areteIndex}`}
          value={arete.sexo}
          onChange={(e) =>
            handleAreteChange(index, areteIndex, "sexo", e.target.value)
          }
          options={options}
          className={"mr-2"}
        />
        <button
          type="button"
          className="mb-4 w-4"
          onClick={() => handleRemoveArete(index, areteIndex)}
          data-testid={`remove-arete-${index}-${areteIndex}`}
        >
          <FontAwesomeIcon icon={faMinus} className="w-full fa-2x" />
        </button>
      </div>
    </div>
  );
};

export default AreteInput;
