import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../Context/AuthContext";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "./styles.css";
import Layout from "../../Components/Layout";

const FolioViewer = () => {
  const { authState } = useContext(AuthContext); // Obtiene el token de autorización del contexto
  const apiUrl = process.env.REACT_APP_URL_STATIC_FILES;
  const [error, setError] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const { id } = useParams(); // Obtiene el ID del documento desde los parámetros de la URL
  const pdfUrl = `${apiUrl}${id}.pdf`;

  useEffect(() => {
    if (pdfUrl) {
      axios
        .get(pdfUrl, {
          responseType: "blob",
          headers: {
            Authorization: `${authState.authToken}`,
          },
        })
        .then((response) => {
          if (response.data.type !== "application/pdf") {
            throw new Error("El archivo recibido no es un PDF válido");
          }
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          setPdfFile(fileURL);
        })
        .catch((error) => {
          console.error(error);
          setError("No se pudo cargar el archivo PDF");
        });
    }
  }, [pdfUrl, authState.authToken]);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfFile;
    link.setAttribute("download", `folio_${id}.pdf`); // O el nombre de archivo que prefieras
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <Layout>
      <div className="my-2 pdfile">
        {pdfFile ? (
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
            <Viewer fileUrl={pdfFile} />
          </Worker>
        ) : (
          <div className="text-center text-gray-600">Cargando PDF...</div>
        )}
      </div>
      {/* Botón de descarga */}
      {pdfFile && (
        <div className="space-x-4 flex justify-center my-2">
          <button onClick={handleDownload} className="bg-blue-700 text-white px-4 py-2 rounded">
            Descargar PDF
          </button>
        </div>
      )}
      {error && <div className="text-center text-red-600">{error}</div>}
    </Layout>
  );
};

export default FolioViewer;
