import React, { useEffect } from "react";
import { useState } from "react";
import Layout from "../../Components/Layout";
import { useAuth } from "../../Context/AuthContext";
import { useParams } from "react-router-dom";
import DocumentsVerification from "../../Components/Verification/DocumentsVerification";

const VerifyFacturas = () => {
  const { folio_id } = useParams();
  const { authState } = useAuth();
  const [facturas, setFacturas] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${apiUrl}/facturas/${folio_id}`,
          {
            headers: {
              Authorization: `Bearer ${authState.authToken}`,
            },
          }
        );
        if (!res.ok) {
          throw new Error("Error al obtener los documentos por factura");
        }
        const dataFacturas = await res.json();
        // get aretes for each factura using api/facturas/:folio_id
        const facturasWithDetails = await Promise.all(
          dataFacturas.facturas.map(async (factura) => {
            const aretesRes = await fetch(
              `${apiUrl}/aretes/factura/${factura.factura_id}`,
              {
                headers: {
                  Authorization: `Bearer ${authState.authToken}`,
                },
              }
            );
            if (!aretesRes.ok) {
              throw new Error("Error al obtener los aretes por factura");
            }
            const aretesData = await aretesRes.json();

            return {
              ...factura,
              aretes: aretesData.aretes,
            };
          })
        );
        setFacturas(facturasWithDetails);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [authState.authToken, folio_id]);

  return (
    <Layout>
      {facturas.length > 0 && (
        <DocumentsVerification
          facturas={facturas}
          authState={authState}
          folio_id={folio_id}
        />
      )}
    </Layout>
  );
};

export default VerifyFacturas;
