import React, { useState, useEffect } from "react";

const TableDataComponent = ({ data, apiUrl, authState }) => {
  const [editIndex, setEditIndex] = useState(null);
  const [newData, setNewData] = useState([]);
  const [newRecord, setNewRecord] = useState({});

  // Actualizar newData cuando data cambie
  useEffect(() => {
    setNewData(data);
    // Inicializar newRecord con las claves vacías basadas en los datos existentes
    if (data && data.length > 0) {
      const emptyRecord = {};
      Object.keys(data[0]).forEach((key) => {
        if (key !== "id") {
          emptyRecord[key] = "";
        }
      });
      setNewRecord(emptyRecord);
    }
  }, [data]);

  const handleEditClick = (index) => {
    setEditIndex(index);
  };

  const handleInputChange = (index, key, value) => {
    const updatedData = [...newData];
    updatedData[index][key] = value.toUpperCase(); // Convertir a mayúsculas
    setNewData(updatedData);
  };

  const handleSaveClick = async (index) => {
    const itemToUpdate = newData[index];
    try {
      await fetch(`${apiUrl}/${itemToUpdate.id}`, {
        method: "PUT",
        headers: {
          Authorization: `${authState.authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(itemToUpdate),
      });
      setEditIndex(null);
    } catch (error) {
      console.error("Error al actualizar el elemento:", error);
    }
  };

  const handleAddNewOption = async () => {
    const upperCaseRecord = {};
    Object.keys(newRecord).forEach((key) => {
      upperCaseRecord[key] = newRecord[key].toUpperCase(); // Convertir a mayúsculas
    });

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Authorization: `${authState.authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(upperCaseRecord),
      });
      const result = await response.json();
      setNewData([...newData, result]);
      setNewRecord({});
    } catch (error) {
      console.error("Error al agregar el nuevo elemento:", error);
    }
  };

  const handleDeleteClick = async (index) => {
    const itemToDelete = newData[index];
    try {
      await fetch(`${apiUrl}/${itemToDelete.id}`, {
        method: "DELETE",
        headers: {
          Authorization: `${authState.authToken}`,
          "Content-Type": "application/json",
        },
      });
      setNewData(newData.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Error al eliminar el elemento:", error);
    }
  };

  const handleNewRecordChange = (key, value) => {
    setNewRecord({ ...newRecord, [key]: value.toUpperCase() }); // Convertir a mayúsculas
  };

  if (!data || data.length === 0) {
    return (
      <div className="w-full py-4 overflow-x-auto">
        <div className="min-w-full inline-block shadow rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead>
              <tr className="bg-blue-100">
                <th className="text-left py-2 px-4">No se encontraron datos...</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    );
  }

  const headers = Object.keys(data[0]);

  return (
    <div className="w-full py-4 overflow-x-auto">
      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2">Añadir un nuevo registro</h2>
        <div className="grid grid-cols-3 gap-4 mb-4">
          {headers.filter((header) => header !== "id").map((header) => (
            <input
              key={header}
              type="text"
              placeholder={header}
              value={newRecord[header]}
              onChange={(e) => handleNewRecordChange(header, e.target.value)}
              className="border border-gray-300 rounded py-1 px-2 w-full"
            />
          ))}
        </div>
        <button
          onClick={handleAddNewOption}
          className="bg-green-500 text-white py-2 px-4 rounded"
        >
          Dar de alta
        </button>
      </div>
      <div className="min-w-full inline-block shadow rounded-lg overflow-hidden">
        <table className="min-w-full leading-normal">
          <thead>
            <tr className="bg-blue-100">
              {headers.map((header) => (
                <th key={header} className="text-left py-2 px-4">
                  {header}
                </th>
              ))}
              <th className="text-left py-2 px-4">acciones</th>
            </tr>
          </thead>
          <tbody>
            {newData.map((row, index) => (
              <tr key={index} className="border-b bg-white">
                {headers.map((header) => (
                  <td key={header} className="text-left py-2 px-4 text-xs">
                    {header === "id" ? (
                      <span className="truncate">{row[header]}</span>
                    ) : editIndex === index ? (
                      <input
                        type="text"
                        value={row[header]}
                        onChange={(e) =>
                          handleInputChange(index, header, e.target.value)
                        }
                        className="border border-gray-300 rounded py-1 px-2 w-full"
                      />
                    ) : (
                      <span className="truncate">{row[header]}</span>
                    )}
                  </td>
                ))}
                <td className="text-left py-2 px-4 text-xs">
                  {editIndex === index ? (
                    <button
                      onClick={() => handleSaveClick(index)}
                      className="bg-blue-500 text-white py-1 px-2 rounded"
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      onClick={() => handleEditClick(index)}
                      className="bg-yellow-500 text-white py-1 px-2 rounded"
                    >
                      Editar
                    </button>
                  )}
                  <button
                    onClick={() => handleDeleteClick(index)}
                    className="bg-red-500 text-white py-1 px-2 rounded ml-2"
                  >
                    Borrar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableDataComponent;
