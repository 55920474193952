import React, { useState, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import { jwtDecode } from "jwt-decode";
import Layout from "../../Components/Layout";
import logo_vector_solo from "../../Assets/img/logo_vector_solo.png";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthState, authState } = useContext(AuthContext);
  const navigate = useNavigate();
  const [recordar, setRecordar] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleLogin = async (e) => {
    e.preventDefault();
    // Send the username and password to the server
    const response = await fetch(`${apiUrl}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password, recordar }),
    });

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem("token", data.token); // Store the token in the browser
      if (data.token) {
        const decoded = jwtDecode(data.token);
        setAuthState({ authToken: data.token, user: decoded });
      }

      navigate("/"); // Navigate to the home page
    } else {
      alert("Datos incorrectos");
    }
  };

  return (
    <Layout>
      {authState.authToken && authState.user ? (
        <Navigate to="/" />
      ) : (
        <div className="mt-4 w-full font-titles">
          <img
            src={logo_vector_solo}
            alt=""
            className="max-w-md mx-auto mb-4"
          />
          
          <h1 className="text-2xl font-titles text-left mb-5 max-w-md mx-auto">
            Iniciar sesión
          </h1>

          <form className="max-w-md mx-auto" onSubmit={handleLogin}>
            <div className="mb-5">
              <label
                htmlFor="input-login"
                className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
              >
                Usuario
              </label>
              <input
                type="text"
                id="input-login"
                className="rounded-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block min-w-64 w-full text-sm p-2.5"
                placeholder="Nombre de Usuario"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="input-password"
                className="block mb-2 text-xl font-medium text-gray-900 dark:text-white"
              >
                Contraseña
              </label>
              <input
                type="password"
                id="input-password"
                className="rounded-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block min-w-64 w-full text-sm p-2.5"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="flex items-start mb-5">
              <div className="flex items-center h-5">
                <input
                  type="checkbox"
                  id="remember"
                  value={recordar}
                  onChange={(e) => setRecordar(e.target.checked)}
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                />
              </div>
              <label
                htmlFor="remember"
                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Recordar (por 30 días)
              </label>
            </div>
            <button
              type="submit"
              id="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Iniciar Sesión
            </button>
          </form>
        </div>
      )}
    </Layout>
  );
};

export default LoginPage;
