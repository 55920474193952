import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../Components/Layout";
import { useAuth } from "../../Context/AuthContext";
import { toast } from "react-toastify";
import DropZone from "../../Components/FolioCreator/DropZone";

const SignedDocUpload = () => {
  const { id } = useParams();
  const { authState } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState(null);
  //fileUploaded, setFileUploaded, fileName, setFileName
  const [fileName, setFileName] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      alert("Por favor, selecciona un archivo para subir.");
      return;
    }

    try {
      // Preparar datos del formulario para subir el archivo
      const formData = new FormData();
      formData.append("file", selectedFile);

      // Subir Archivo
      const fileResponse = await fetch(
        `${apiUrl}/uploadAproved/${id}`, // Usar el 'id' del folio
        {
          method: "POST",
          headers: {
            Authorization: `${authState.authToken}`,
          },
          body: formData,
        }
      );

      if (fileResponse.ok) {
        // Manejo adicional en caso de éxito
        toast.success("Permiso Firmado Súbido correctamente");
        navigate("/"); // Redirigir a la página principal o a una página de confirmación
      } else {
        console.error("Error al subir el archivo");
      }
    } catch (error) {
      console.error("Error de red al intentar subir el archivo", error);
    }
  };

  return (
    <Layout>
      <div className="text-2xl mb-4 font-titles flex flex-col items-center mt-4">
        <h1>Ingresa la documentación correspondiente</h1>
        <h1>Para el folio N° {id}</h1>
      </div>
      <div className="mb-5">
        <form className="w-full mx-auto" onSubmit={handleSubmit}>
          <DropZone
            onDrop={handleFileChange}
            fileUploaded={fileUploaded}
            setFileUploaded={setFileUploaded}
            fileName={fileName}
            setFileName={setFileName}
            label={"permiso de movilización firmado"}
          />
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Subir
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default SignedDocUpload;
