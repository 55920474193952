import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../Components/Layout";
import { useAuth } from "../../Context/AuthContext";
import Spinner from "../../Components/Custom/Spinner";
import { toast } from "react-toastify";

const PaymentAsign = () => {
  const { folio_id } = useParams();
  const { authState } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [existingPayments, setExistingPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchExistingPayments = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`${apiUrl}/pagos/existing`, {
          method: "GET",
          headers: {
            Authorization: `${authState.authToken}`,
          },
        });
        const data = await response.json();
        console.log(data);
        setExistingPayments(data);
      } catch (error) {
        console.error("Error fetching existing payments:", error);
        setError("Error fetching existing payments");
      } finally {
        setIsLoading(false);
      }
    };

    fetchExistingPayments();
  }, [apiUrl, authState.authToken]);

  const handleSelectedPaymentChange = (paymentId) => {
    setSelectedPayment(paymentId);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    if (!selectedPayment) {
      alert("Por favor, selecciona un pago existente.");
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/pagos/${selectedPayment}/asignar`,
        {
          method: "PUT",
          headers: {
            Authorization: `${authState.authToken}`,
            "Content-Type": "application/json", // Asegúrate de que el tipo de contenido esté definido
          },
          body: JSON.stringify({ folio_id }), // Asegúrate de que folio_id esté siendo enviado correctamente
        }
      );
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message);
        navigate("/");
      } else {
        console.error("Error al asignar el pago");
        setError(data.message || "Error al asignar el pago");
      }
    } catch (error) {
      console.error("Error de red al intentar asignar el pago", error);
      setError("Error de red al intentar asignar el pago");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <div className="max-w-4xl mx-auto mt-8">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="text-center mb-6">
            <h1 className="text-3xl font-bold mb-2">Asignar Pago a Folio</h1>
            <h2 className="text-xl">Para el folio N° {folio_id}</h2>
          </div>
          {error && <div className="text-red-500 mb-4">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Seleccionar un pago existente:
              </label>
              <div className="min-w-full inline-block shadow rounded-lg overflow-hidden">
                <table className="table leading-normal">
                  <thead>
                    <tr className="bg-blue-100">
                      <th className="px-4 py-2">Seleccionar</th>
                      <th className="px-4 py-2">Número de Pago</th>
                      <th className="px-4 py-2">Folios Pagados</th>
                      <th className="px-4 py-2">Pagos Restantes</th>
                      <th className="px-4 py-2">Tipo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {existingPayments.map((payment) => (
                      <tr key={payment.id} className="border-b">
                        <td className="text-center">
                          <input
                            type="radio"
                            name="selectedPayment"
                            checked={selectedPayment === payment.id}
                            onChange={() =>
                              handleSelectedPaymentChange(payment.id)
                            }
                          />
                        </td>
                        <td className="text-center">
                          {payment.numero_folio_talon}
                        </td>
                        <td className="text-center">
                          {payment.numero_folios_pagados}
                        </td>
                        <td className="text-center">
                          {payment.numero_folios_pagados -
                            payment.countPagosFolios}
                        </td>
                        <td className="text-center">{payment.tipo}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <button
              type="submit"
              className="w-full bg-blue-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : "Asignar"}
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default PaymentAsign;
