import React, { useEffect, useState } from "react";
import TableDataComponent from "../TableDataComponent";

const Especies = ({ authState }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/crud/especies`, {
          headers: {
            Authorization: `${authState.authToken}`,
          },
        });
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [apiUrl, authState]);

  return (
    <div>
      <TableDataComponent data={data} apiUrl={`${apiUrl}/crud/especies`} authState={authState} />
    </div>
  );
};

export default Especies;
