import React from "react";

const MotivosRechazo = ({ motivo }) => {
  if (!motivo) return <p>No hay motivos de rechazo disponibles.</p>;
  return (
    <div>
      <h1 className="text-center text-2xl font-bold text-gray-800 mb-4">
        Motivo de rechazo
      </h1>
      <p className="text-center text-gray-800">
        {motivo}
      </p>
    </div>
  );
};

export default MotivosRechazo;
