import React from "react";

const NumberInput = ({
  id,
  value,
  onChange,
  placeholder,
  className = '',
  maxLength = 10,
  label, // Adding label prop
  ...props
}) => {
  const handleInputChange = (e) => {
    const { value } = e.target;
    // Solo permite números y verifica la longitud
    if (/^\d*$/.test(value) && value.length <= maxLength) {
      onChange(e);
    }
  };

  return (
    <div className="mb-4">
      {label && <label htmlFor={id} className="block text-sm font-medium text-gray-900 dark:text-white">{label}</label>}
      <input
        id={id}
        type="text"
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={`number-input no-spinner border rounded-lg bg-gray-50 border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block w-full text-sm p-2.5 h-10 ${className}`}
        {...props}
      />
    </div>
  );
};

export default NumberInput;
