import "./styles.css";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col items-center mt-24 min-h-[calc(100vh-96px)] layout-container">
      <div className="layout-content">
        {children}
      </div>
    </div>
  );
};

export default Layout;