import React from "react";

/**
 * Componente que muestra un menú en la parte inferior de la pantalla.
 * Permite seleccionar un filtro de estado para alguna lista o contenido.
 *
 * @param {object} props - Propiedades del componente.
 * @param {boolean} props.isMenuOpen - Determina si el menú está abierto o cerrado.
 * @param {function} props.setFiltroEstado - Función para establecer el estado del filtro seleccionado.
 * @param {(string|object)} props.filtroEstado - Estado actual del filtro seleccionado.
 * @param {Array.<{valor: string|object, etiqueta: string}>} props.opcionesFiltro - Opciones disponibles para el filtro.
 */

const BottomDrawerMenu = ({
  isMenuOpen,
  setFiltroEstado,
  filtroEstado,
  opcionesExtraFiltro = [],
}) => {
  const opcionesFiltroPredeterminadas = [
    { valor: "", etiqueta: "Subidos" },
    { valor: "En Progreso", etiqueta: "En progreso" },
    { valor: "Rechazado", etiqueta: "Rechazados" },
    { valor: "Aprobado", etiqueta: "Aprobado" },
    //{ valor: { estado: "En Progreso", step: "2" }, etiqueta: "Falta de Pago" },

  ];

  const opcionesFiltro = [
    ...opcionesFiltroPredeterminadas,
    ...opcionesExtraFiltro
  ];

  const baseStateStyle = "inline-block p-4 cursor-pointer";
  const inactiveStateStyle =
    "border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300";
  const activeStateStyle =
    "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500";

  const isActive = (opcion) => {
    // Si ambos son objetos (caso especial "Falta de Pago")
    if (typeof opcion.valor === "object" && typeof filtroEstado === "object") {
      return (
        opcion.valor.estado === filtroEstado.estado &&
        opcion.valor.step === filtroEstado.step
      );
    }
    // Comparar como cadenas para los otros casos
    return opcion.valor === filtroEstado;
  };

  return (
    <div
      className={`fixed bottom-0 left-0 w-full py-5 px-8 transform transition-transform ${
        isMenuOpen ? "translate-y-0" : "translate-y-full"
      } md:translate-y-0 md:static md:flex md:flex-wrap md:gap-3`}
    >
      {opcionesFiltro.map((opcion) => (
        <span
          key={JSON.stringify(opcion.valor)} // Key como cadena para manejar objetos y cadenas
          onClick={() => {
            setFiltroEstado(opcion.valor);
          }}
          className={`${baseStateStyle} ${
            isActive(opcion) ? activeStateStyle : inactiveStateStyle
          }`}
        >
          {opcion.etiqueta}
        </span>
      ))}
    </div>
  );
};

export default BottomDrawerMenu;
