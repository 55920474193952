import React from "react";
import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faFileInvoiceDollar,
  faFileInvoice,
  faFileSignature,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import BottomDrawerMenu from "../Components/BottomDrawerMenu";
import ActionBar from "../Components/ActionBar";
import TableData from "../Components/TableData";

function Secretaria() {
  const [data, setData] = useState([]);
  const [filtroEstado, setFiltroEstado] = useState("");
  const [terminoBusqueda, setTerminoBusqueda] = useState("");
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { authState, closeDocumentViewer } = useContext(AuthContext);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleFileDownload = async (type, id) => {
    try {
      const response = await fetch(`${apiUrl}/files/${type}/${id}`);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${type}-${id}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = new URL(`${apiUrl}/folios`);

        let params = { busqueda: terminoBusqueda };
        if (typeof filtroEstado === "object" && filtroEstado !== null) {
          params = { ...params, ...filtroEstado };
        } else if (filtroEstado) {
          params.estado = filtroEstado;
        }

        Object.keys(params).forEach((key) =>
          url.searchParams.append(key, params[key])
        );

        const res = await fetch(url, {
          headers: {
            Authorization: `${authState.authToken}`,
          },
        });

        if (!res.ok) {
          throw new Error("Response not ok from API");
        }

        const json = await res.json();
        setData(json);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [authState.authToken, filtroEstado, terminoBusqueda]);

  const handleEditClick = (folio_id) => {
    closeDocumentViewer();
    navigate(`/verify-facturas/${folio_id}`);
  };
  const indexes = {
    folio_id: "Folio",
    motivo_de_movilizacion: "Motivo",
    nombre_origen: "Rancho",
    poblacion_origen: "Población",
    nombre_destino: "Destino",
    estado: "Estado",
  };

  const actionsMap = {
    Rechazado: {
      1: (folio_id) => (
        <div className="flex space-x-2 font-sec text-sm text-center">
          <div
            className="px-4 cursor-pointer"
            onClick={() => handleEditClick(folio_id)}
          >
            <FontAwesomeIcon icon={faPenToSquare} className="px-2" />
            Editar
          </div>
        </div>
      ),
    },
    "En Progreso": {
      0: (folio_id) => (
        <div className="flex space-x-2 font-sec text-sm text-center">
          <p>En revisión por veterinaria</p>
        </div>
      ),
      1: (folio_id) => (
        <div className="flex space-x-2 font-sec text-sm text-center">
          <div
            className="px-4 cursor-pointer"
            onClick={() => handleEditClick(folio_id)}
          >
            <FontAwesomeIcon icon={faPenToSquare} className="px-2" />
            Editar
          </div>
          <div
            className="px-4 cursor-pointer"
            onClick={() => handleFileDownload("dictamen", folio_id)}
          >
            <FontAwesomeIcon icon={faDownload} className="px-2" />
            <span>Descargar Dictamen</span>
          </div>
        </div>
      ),
      2: (folio_id) => (
        <div className="flex space-x-2 font-sec text-sm text-center">
          En espera de Pago
        </div>
      ),
      3: (folio_id) => (
        <div className="flex space-x-2 font-sec text-sm text-center">
          <p>En revisión de pago</p>
        </div>
      ),
      4: (folio_id) => (
        <div className="flex space-x-2 font-sec text-sm text-center">
          <div
            className="px-4 cursor-pointer"
            onClick={() => navigate(`/permiso/${folio_id}`)}
          >
            <FontAwesomeIcon icon={faFileInvoice} className="px-2" />
            Visualizar Permiso
          </div>
        </div>
      ),
      5: (folio_id) => (
        <div className="flex space-x-2 font-sec text-sm text-center">
          <div
            className="px-4 cursor-pointer"
            onClick={() => navigate(`/upload-signed-doc/${folio_id}`)}
          >
            <FontAwesomeIcon icon={faFileSignature} className="px-2" />
            Subir Permiso Firmado
          </div>
        </div>
      ),
    },
    Aprobado: {
      6: (folio_id) => (
        <div className="flex space-x-2 font-sec text-sm text-center">-</div>
      ),
    },
    Cancelado: {},
  };

  const actions = (item) => {
    if (
      !actionsMap[item.estado] ||
      !actionsMap[item.estado][parseInt(item.step)]
    ) {
      return <div>-</div>;
    }
    const action = actionsMap[item.estado][parseInt(item.step)];
    return action ? action(item.folio_id) : <div>-</div>;
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setTerminoBusqueda(e.target.value);
  };

  return (
    <div className="w-full md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto">
      <h1 className="text-2xl leading-tight font-titles">Secretario/a {authState.user.nombre} {authState.user.apellido}</h1>
      <div className="text-2xl mb-4 lg:flex lg:justify-between px-2 items-center">
        <div className="w-full text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <ul className="lg:flex lg:flex-wrap -mb-px lg:items-center justify-between">
            <li>
              <ActionBar
                itemsPerPage={itemsPerPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                searchTerm={terminoBusqueda}
                handleSearchChange={handleSearchChange}
              />
            </li>
            <li className="">
              <BottomDrawerMenu
                isMenuOpen={isFilterMenuOpen}
                setFiltroEstado={setFiltroEstado}
                filtroEstado={filtroEstado}
                opcionesExtraFiltro={[
                  {
                    valor: { estado: "En Progreso", step: "2" },
                    etiqueta: "Falta de Pago",
                  },
                ]}
              />
            </li>
          </ul>
        </div>
      </div>
      <TableData
        data={data}
        indexes={indexes}
        actions={actions}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </div>
  );
}

export default Secretaria;
