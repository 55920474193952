import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../Components/Layout";
import DropZone from "../../Components/FolioCreator/DropZone";
import TextInput from "../../Components/Custom/Forms/TextInput";
import Spinner from "../../Components/Custom/Spinner";
import { useAuth } from "../../Context/AuthContext";
import { toast } from "react-toastify";
import TableData from "../../Components/HomePageUser/Components/TableData";
import Modal from "../../Components/Modal";
import MotivosRechazoPago from "../../Components/HomePageUser/Acopiador/Components/MotivosRechazoPago";
import { set } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faEdit,
  faFileCirclePlus,
  faFileInvoiceDollar,
  faMoneyCheckDollar,
  faMagnifyingGlass,
  faReceipt,
} from "@fortawesome/free-solid-svg-icons";

const PaymentUpload = () => {
  const { authState } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [payments, setPayments] = useState([]);

  const [fileUploaded, setFileUploaded] = useState(null);
  const [fileName, setFileName] = useState("");
  const [lineaDeCaptura, setLineaDeCaptura] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [motivosRechazo, setMotivosRechazo] = useState({});
  const [uploading, setUploading] = useState(false);

  const handlePagoChange = (e) => {
    setFileUploaded(e.target.files[0]);
  };

  const handleLineaDeCapturaChange = (e) => {
    setLineaDeCaptura(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    if (!fileUploaded) {
      alert("Por favor, selecciona un archivo para subir.");
      setIsLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("pago_permiso_movilizacion", fileUploaded);
      formData.append("numero_folio_talon", lineaDeCaptura);

      const response = await fetch(`${apiUrl}/pagos/movilizacion/upload`, {
        method: "POST",
        headers: {
          Authorization: `${authState.authToken}`,
        },
        body: formData,
      });

      if (response.ok) {
        toast.success("Tu formato de pago se ha subido correctamente");
        navigate("/");
      } else {
        const data = await response.json();
        toast.error(data.message || "Error al subir el pago");
        setError(data.message || "Error al subir el pago");
      }
    } catch (error) {
      console.error("Error de red al intentar subir el pago", error);
      setError("Error de red al intentar subir el pago");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/pagos/usuario/${authState.user.usuario_id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${authState.authToken}`, // Pasar el token de autorización
            },
          }
        );

        if (!response.ok) {
          throw new Error("Error fetching payments");
        }

        const data = await response.json();
        setPayments(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchPayments();
  }, [apiUrl, authState]);

  const normalizeData = (data) => {
    return data.map((payment) => ({
      id: payment.id,
      numero_folio_talon: payment.numero_folio_talon,
      numero_folios_pagados: payment.numero_folios_pagados || "N/A",
      numero_folios_restantes:
        payment.numero_folios_pagados - payment.numero_folios_usados,
      tipo: payment.tipo,
      estado: payment.estado,
      motivo_rechazo: payment.motivo_rechazo,
    }));
  };

  const indexes = {
    id: "ID",
    numero_folio_talon: "Número de Folio Talón",
    numero_folios_pagados: "Folios Pagados",
    numero_folios_restantes: "Folios Restantes",
    tipo: "Tipo",
    estado: "Estado",
  };
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleOpenModal = async (motivo) => {
    try {
      setModalVisible(true);
      setMotivosRechazo(motivo);
    } catch (error) {
      console.log(error);
    }
  };

  //abrir un modal que muestre pagos_permisos_movilizacion.motivo_rechazo

  const actions = (item) => {
    return (
      <FontAwesomeIcon
      icon={faExclamationCircle}
      className="mr-2 h-5 cursor-pointer text-red-600 hover:text-red-700"
      disabled={item.estado !== "Rechazado"}
      hidden={item.estado !== "Rechazado"}
      onClick={() => handleOpenModal(item.motivo_rechazo)}
      title="Motivo de Rechazo"
      />
    );
  };

  return (
    <Layout>
      <div className="w-full mt-8 flex justify-between">
        <div className="w-3/5 px-12">
          <TableData data={normalizeData(payments)} indexes={indexes} actions={actions}/>
        </div>
        <div className="flex justify-center w-2/5 px-12">
          <div className="bg-white p-6 rounded-lg shadow-md w-full">
            <div className="text-center mb-6">
              <h1 className="text-2xl font-titles font-bold mb-2">
                Subir Pago de Permiso de Movilización
              </h1>
            </div>
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <DropZone
                  onDrop={handlePagoChange}
                  label={"documento de pago"}
                  setFileUploaded={setFileUploaded}
                  fileUploaded={fileUploaded}
                  fileName={fileName}
                  setFileName={setFileName}
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Orden de pago
                </label>
                <TextInput
                  id="lineaDeCaptura-input"
                  value={lineaDeCaptura}
                  onChange={handleLineaDeCapturaChange}
                  placeholder="Ingrese el número del pago"
                  maxLength={20}
                  className="mb-4"
                  validationPattern="^[a-zA-Z0-9]*$"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                disabled={isLoading}
              >
                {isLoading ? <Spinner /> : "Subir"}
              </button>
            </form>
          </div>
        </div>

        {modalVisible && (
        <Modal show={modalVisible} onClose={handleCloseModal}>
          <MotivosRechazoPago motivo={motivosRechazo} />
        </Modal>
      )}
      </div>
    </Layout>
  );
};

export default PaymentUpload;
