import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../Context/AuthContext";
import Select from "../../../../Components/Custom/Forms/Select";
import Modal from "../Modal";
import MotivosRechazo from "../MotivosRechazo";
import { toast } from "react-toastify";
import { set } from "date-fns";

const SolveFolio = () => {
  const { folio_id } = useParams();
  const { authState } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [folioData, setFolioData] = useState(null);
  const [destinos, setDestinos] = useState([]);
  const [motivosRechazo, setMotivosRechazo] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [removedFacturas, setRemovedFacturas] = useState([]);
  const navigate = useNavigate();

  const optionsMotivo = [
    { value: "", label: "Selecciona un motivo" },
    { value: "ENGORDA", label: "Engorda" },
    { value: "SACRIFICIO", label: "Sacrificio" },
    { value: "CAMBIO DE ANGOSTADER", label: "Cambio de Angostadero" },
    { value: "REPRODUCCION", label: "Reproducción" },
    { value: "INDUSTRIA", label: "Industria" },
    { value: "CONSUMO", label: "Consumo" },
    { value: "COMPETENCIA", label: "Competencia" },
    { value: "OTRO", label: "Otro" },
  ];

  const optionsEspecie = [
    { value: "", label: "Selecciona una especie" },
    { value: "BOVINA", label: "Bovina" },
    { value: "PORCINA", label: "Porcina" },
    { value: "CAPRINA", label: "Caprina" },
    { value: "OVINA", label: "Ovina" },
    { value: "EQUINA", label: "Equina" },
    { value: "AVICOLA", label: "Avícola" },
    { value: "APICOLA", label: "Apícola" },
  ];

  useEffect(() => {
    const fetchFolioData = async () => {
      try {
        const response = await fetch(`${apiUrl}/folios/${folio_id}/edit`, {
          method: "get",
          headers: {
            Authorization: `${authState.authToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setFolioData(data.folioData);
        } else {
          alert("Error al obtener datos del folio");
        }
      } catch (error) {
        console.error("Error al obtener datos del folio", error);
      }
    };

    const fetchDestinos = async () => {
      try {
        const response = await fetch(`${apiUrl}/destinos/get`, {
          method: "get",
          headers: {
            Authorization: `${authState.authToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setDestinos(data);
        } else {
          alert("Error al obtener destinos");
        }
      } catch (error) {
        console.error("Error al obtener destinos", error);
      }
    };

    fetchFolioData();
    fetchDestinos();
  }, [folio_id, authState.authToken, apiUrl]);

  const handleDestinoChange = (e) => {
    setFolioData((prevData) => ({
      ...prevData,
      destino: {
        ...prevData.destino,
        id_destino: e.target.value,
      },
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFolioData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFacturaInputChange = (index, e) => {
    const { name, value } = e.target;
    const newFacturas = [...folioData.facturas];
    newFacturas[index][name] = value;
    setFolioData((prevData) => ({
      ...prevData,
      facturas: newFacturas,
    }));
  };
  const handleReemoInputChange = (index, e) => {
    const { name, value } = e.target;
    const newFacturas = [...folioData.facturas];
    newFacturas[index].reemo[name] = value;
    setFolioData((prevData) => ({
      ...prevData,
      facturas: newFacturas,
    }));
  };

  const handleAreteChange = (facturaIndex, areteIndex, e) => {
    const { name, value } = e.target;
    const newFacturas = [...folioData.facturas];
    newFacturas[facturaIndex].aretes[areteIndex][name] = value;
    setFolioData((prevData) => ({
      ...prevData,
      facturas: newFacturas,
    }));
  };

  const handleFileDownload = async (type, id) => {
    try {
      const response = await fetch(`${apiUrl}/files/${type}/${id}`, {
        headers: {
          Authorization: `${authState.authToken}`,
        },
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${type}-${id}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleFileChange = (e, type, index) => {
    const { files } = e.target;
    if (type === "dictamen") {
      setFolioData((prevData) => ({
        ...prevData,
        dictamenFile: files[0],
      }));
    } else if (type === "factura") {
      const newFacturas = [...folioData.facturas];
      newFacturas[index].facturaFile = files[0];
      setFolioData((prevData) => ({
        ...prevData,
        facturas: newFacturas,
      }));
    } else if (type === "reemo") {
      const newFacturas = [...folioData.facturas];
      newFacturas[index].reemoFile = files[0];
      setFolioData((prevData) => ({
        ...prevData,
        facturas: newFacturas,
      }));
    }
  };

  const handleRemoveFactura = (index) => {
    setRemovedFacturas((prevData) => [...prevData, folioData.facturas[index]]);
    const newFacturas = folioData.facturas.filter((_, idx) => idx !== index);
    setFolioData((prevData) => ({
      ...prevData,
      facturas: newFacturas,
    }));
  };

  const handleRemoveArete = (facturaIndex, areteIndex) => {
    const newFacturas = [...folioData.facturas];
    newFacturas[facturaIndex].aretes = newFacturas[facturaIndex].aretes.filter(
      (_, idx) => idx !== areteIndex
    );
    setFolioData((prevData) => ({
      ...prevData,
      facturas: newFacturas,
    }));
  };

  const handleAddArete = (facturaIndex) => {
    const newFacturas = [...folioData.facturas];
    newFacturas[facturaIndex].aretes.push({ numero_arete: "", sexo: "M" });
    setFolioData((prevData) => ({
      ...prevData,
      facturas: newFacturas,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("usuario_id", authState.user.usuario_id);
      formData.append(
        "motivo_de_movilizacion",
        folioData.motivo_de_movilizacion
      );
      formData.append("id_destino", folioData.destino.id_destino);
      formData.append("especie", folioData.especie);
      if (folioData.dictamenFile) {
        formData.append("dictamen", folioData.dictamenFile);
      }

      let v = true;

      await folioData.facturas.forEach(async (factura, index) => {
        formData.append(`facturas[${index}][factura_id]`, factura.factura_id);

        //verificar si factura tiene id, si no, verificar si tiene archivo
        if (!factura.factura_id && !factura.facturaFile) {
          toast.error("Archivo de factura es requerido para factura nueva");
          v = false;
        }

        //verificar si numero de factura es valido
        if (!factura.numero_de_factura) {
          toast.error("Número de factura es requerido");
          v =  false;
        }
        formData.append(
          `facturas[${index}][numero_de_factura]`,
          factura.numero_de_factura
        );

        if (factura.facturaFile) {
          formData.append(`facturas[${index}][archivo]`, factura.facturaFile);
        }

        //verificar si reemo tiene id, si no, verificar si tiene archivo
        if (!factura.reemo.reemo_id && !factura.reemoFile) {
          toast.error("Archivo de REEMO es requerido para REEMO nuevo");
          v = false;
        }

        formData.append(
          `facturas[${index}][reemo][reemo_id]`,
          factura.reemo.reemo_id
        );

        //verificar si numero de reemo es valido
        if (!factura.reemo.numero_de_reemo) {
          toast.error("Número de REEMO es requerido");
          v = false;
        }

        formData.append(
          `facturas[${index}][reemo][numero_de_reemo]`,
          factura.reemo.numero_de_reemo
        );

        if (factura.reemoFile) {
          formData.append(
            `facturas[${index}][reemo][archivo]`,
            factura.reemoFile
          );
        }

        //verificar si hay minimo un arete
        if (factura.aretes.length === 0) {
          toast.error(`El grupo #${index + 1} no tiene aretes.`);
          v = false;
        }

        console.log(factura.aretes.length);

        factura.aretes.forEach((arete, idx) => {
          //verificar si numero de arete es valido
          if (!arete.numero_arete) {
            toast.error("Número de arete es requerido");
            v = false;
          }
          formData.append(
            `facturas[${index}][aretes][${idx}][numero_arete]`,
            arete.numero_arete
          );
          formData.append(
            `facturas[${index}][aretes][${idx}][sexo]`,
            arete.sexo
          );
        });
      });

      if (v === false) {
        toast.error("Error al actualizar el folio");
        return;
      }
      //revisar si hay aretes repetidos
      const aretes = [];

      let aretesRepetidos = false;

      folioData.facturas.forEach((factura) => {
        factura.aretes.forEach((arete) => {
          if (aretes.includes(arete.numero_arete)) {
            toast.error(
              "No se pueden repetir aretes, arete repetido: " +
                arete.numero_arete
            );
            aretesRepetidos = true;
            return;
          }
          aretes.push(arete.numero_arete);
        });
      });

      if (aretesRepetidos) {
        return;
      }

      const response = await fetch(`${apiUrl}/folios/${folio_id}/edit`, {
        method: "PUT",
        headers: {
          Authorization: `${authState.authToken}`,
        },
        body: formData,
      });

      //remover facturas eliminadas de la base de datos
      if (removedFacturas.length > 0) {
        removedFacturas.forEach(async (factura_removida) => {
          if (factura_removida.factura_id) {
            await fetch(`${apiUrl}/facturas/${factura_removida.factura_id}`, {
              method: "DELETE",
              headers: {
                Authorization: `${authState.authToken}`,
              },
            });
          }
        });
      }

      if (response.ok) {
        toast.success("Folio actualizado correctamente");
        navigate("/inicio");
      } else {
        toast.error("Error al actualizar el folio");
      }
    } catch (error) {
      console.error("Error al actualizar el folio:", error);
    }
  };

  const handleAddGroup = () => {
    const newFacturas = [...folioData.facturas];
    newFacturas.push({
      factura_id: "",
      numero_de_factura: "",
      reemo: { reemo_id: "", numero_de_reemo: "" },
      aretes: [{ numero_arete: "", sexo: "M" }],
    });
    setFolioData((prevData) => ({
      ...prevData,
      facturas: newFacturas,
    }));
  };
/*
  const fetchMotivosRechazo = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/folios/${folio_id}/rejection-reasons`,
        {
          method: "get",
          headers: {
            Authorization: `${authState.authToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setMotivosRechazo(data.rejectionReasons);
      } else {
        alert("Error al obtener motivos de rechazo");
      }
    } catch (error) {
      console.error("Error al obtener motivos de rechazo", error);
    }
  };
/*
  const handleOpenModal = async () => {
    await fetchMotivosRechazo();
    setModalVisible(true);
  };*/

  const handleOpenModal = async () => {
    try {
      const res = await fetch(
        `${apiUrl}/folios/${folio_id}/rejection-reasons`,
        {
          headers: {
            Authorization: `${authState.authToken}`,
          },
        }
      );

      if (!res.ok) {
        throw new Error("Response not ok from API");
      }

      // Obtener las facturas con el folio id
      const facturasRes = await fetch(
        `${apiUrl}/facturas/aretes/rejected/${folio_id}`,
        {
          headers: {
            Authorization: `${authState.authToken}`,
          },
        }
      );

      const resJson = await res.json();
      const aretesRech = await facturasRes.json();

      const motivosRechazo = resJson.rejectionReasons;

      // Filtrar los aretes rechazados y agregar el comentario de aretesRech si existe
      const filteredAretes = aretesRech.map((arete) => {
        const areteRechazado = motivosRechazo.aretesRechazados.find((areteRechazado) => areteRechazado.numero_arete === arete.numero_arete);
        if (areteRechazado) {
          const motivo_rechazo = arete.in_dictamen===false ? "El arete no se encuentra el dictamen."  : "El número o datos del arete no coincide con la factura.";
          const comentario = arete.comentario

          return {
            ...areteRechazado,
            motivo_rechazo: motivo_rechazo,
            comentario: comentario,
            numero_de_factura: arete.factura.numero_de_factura,
          };
        }
        return null;
      });
        
      console.log("aretesRech",aretesRech);

      motivosRechazo.aretesRechazados = filteredAretes;

      setMotivosRechazo(motivosRechazo);
      
      console.log(motivosRechazo);

      setModalVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  if (!folioData) {
    return <div>Loading...</div>;
  }
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Actualizar Folio</h1>
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded-lg shadow-md"
      >
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Dictamen:
          </label>
          <button
            type="button"
            onClick={() => handleFileDownload("dictamen", folioData.folio_id)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
          >
            Descargar Dictamen
          </button>
          <input
            type="file"
            name="dictamenFile"
            onChange={(e) => handleFileChange(e, "dictamen")}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Motivo de Movilización:
          </label>
          <Select
            name="motivo_de_movilizacion"
            value={folioData.motivo_de_movilizacion}
            options={optionsMotivo}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Destino:
          </label>
          <select
            value={folioData.destino.id_destino}
            onChange={handleDestinoChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            {destinos.map((destino) => (
              <option key={destino.id} value={destino.id}>
                {destino.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Especie:
          </label>
          <Select
            name="especie"
            value={folioData.especie}
            options={optionsEspecie}
            onChange={handleInputChange}
          />
        </div>
        {folioData.facturas.map((factura, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-md mb-4">
            <label className="block text-sm font-medium text-gray-700 ">
              Factura:
            </label>
            <button
              type="button"
              onClick={() => handleFileDownload("factura", factura.factura_id)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
              hidden={!factura.factura_id}
            >
              Descargar Factura
            </button>
            <input
              type="file"
              name="facturaFile"
              onChange={(e) => handleFileChange(e, "factura", index)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <input
              type="text"
              name="numero_de_factura"
              value={factura.numero_de_factura}
              onChange={(e) => handleFacturaInputChange(index, e)}
              onKeyDown={(e) => {
                if (
                  !/[0-9]/.test(e.key) &&
                  e.key !== "Backspace" &&
                  e.key !== "Delete" &&
                  e.key !== "ArrowLeft" &&
                  e.key !== "ArrowRight"
                ) {
                  e.preventDefault();
                }
              }}
              maxLength={9} // Cambia este valor según el número máximo de caracteres que desees permitir.
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            {factura.reemo && (
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">
                  REEMO:
                </label>
                <button
                  type="button"
                  onClick={() =>
                    handleFileDownload("reemo", factura.reemo.reemo_id)
                  }
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                  hidden={!factura.reemo.reemo_id}
                >
                  Descargar REEMO
                </button>
                <input
                  type="file"
                  name="reemoFile"
                  onChange={(e) => handleFileChange(e, "reemo", index)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <input
                  type="text"
                  name="numero_de_reemo"
                  value={factura.reemo.numero_de_reemo}
                  onChange={(e) => handleReemoInputChange(index, e)}
                  onKeyDown={(e) => {
                    if (
                      !/[0-9]/.test(e.key) &&
                      e.key !== "Backspace" &&
                      e.key !== "Delete" &&
                      e.key !== "ArrowLeft" &&
                      e.key !== "ArrowRight"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  maxLength={9}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            )}
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">
                Aretes:
              </label>
              {factura.aretes.map((arete, idx) => (
                <div key={idx} className="flex items-center space-x-2 mt-2">
                  <input
                    type="text"
                    name="numero_arete"
                    value={arete.numero_arete}
                    onChange={(e) => handleAreteChange(index, idx, e)}
                    onKeyDown={(e) => {
                      if (
                        !/[0-9]/.test(e.key) &&
                        e.key !== "Backspace" &&
                        e.key !== "Delete" &&
                        e.key !== "ArrowLeft" &&
                        e.key !== "ArrowRight"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    maxLength={10}
                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <select
                    name="sexo"
                    value={arete.sexo}
                    onChange={(e) => handleAreteChange(index, idx, e)}
                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="M">Macho</option>
                    <option value="F">Hembra</option>
                  </select>
                  <button
                    type="button"
                    onClick={() => handleRemoveArete(index, idx)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-1 rounded"
                  >
                    Eliminar
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => handleAddArete(index)}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 mb-2"
              >
                Agregar Arete
              </button>
            </div>
            <button
              type="button"
              onClick={() => handleRemoveFactura(index)}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mb-2"
            >
              Eliminar Grupo
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddGroup}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          Agregar otro grupo
        </button>
        <br />
        <button
          type="submit"
          className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Guardar Cambios
        </button>
      </form>
      <button
        onClick={handleOpenModal}
        className="bg-red-600 text-white font-bold py-2 px-4 rounded mt-4 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
      >
        Ver Motivos de Rechazo
      </button>
      <Modal show={modalVisible} onClose={handleCloseModal}>
        <MotivosRechazo motivos={motivosRechazo} />
      </Modal>
    </div>
  );
};

export default SolveFolio;
